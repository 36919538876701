import {
  PEOPLE_LOAD,
  PEOPLE_SELF_ACTIVATE,
  // PEOPLE_LOAD_ERROR,
  // PEOPLE_LOAD_SUCCESS,
  PEOPLE_SELF_LOAD,
  PEOPLE_SELF_LOAD_ERROR,
  PEOPLE_SELF_LOAD_SUCCESS,
  PEOPLE_UPDATE,
  PEOPLE_UPDATE_ERROR,
  PEOPLE_UPDATE_SUCCESS,
  PEOPLE_SELF_UPDATE_BALANCE,
  PEOPLE_UPDATE_ASSET_BALANCE,
  PEOPLE_UPDATE_XP_ASSET_BALANCE,
  PEOPLE_UPDATE_VALIDATION_ERROR,
  PEOPLE_UPDATE_INVALID_FIELDS,
  PEOPLE_SELF_XP_UPDATE_BALANCE,
  PEOPLE_ACTIVATE,
  PEOPLE_GET_FEATURED,
  PEOPLE_GET_FEATURED_ERROR,
  PEOPLE_GET_FEATURED_SUCCESS,
  PEOPLE_ADD_IMAGE,
  PEOPLE_SEARCH,
} from "./constants";
import { SelfType, RefreshType } from "../../types";
import { PEOPLE_ENDPOINT, SELF_ENDPOINT, FEATURED_ENDPOINT } from "../../constants/endpoints";

export const addSelfImage = (file: string) => (dispatch: any) => {
  dispatch({
    type: PEOPLE_ADD_IMAGE,
    payload: file
  })
}

export const peopleSearch = (querystring: string) => (dispatch: any) => {
  dispatch({
    type: PEOPLE_SEARCH,
    payload: querystring
  })
}

export const getFeatured = () => (dispatch: any) => {
  dispatch({
    type: PEOPLE_GET_FEATURED,
    payload: {},
    meta: {
      offline: {
        effect: {
          url: FEATURED_ENDPOINT,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
        commit: { type: PEOPLE_GET_FEATURED_SUCCESS },
        rollback: { type: PEOPLE_GET_FEATURED_ERROR },
      },
    },
  });
}

export const loadPerson = (refreshObject: Array<RefreshType>) => (dispatch: any) => {
  if(refreshObject.length > 0){
    dispatch({
      type: PEOPLE_LOAD,
      payload: { refreshObject },
      // meta: {
      //   offline: {
      //     effect: {
      //       url: PEOPLE_ENDPOINT,
      //       method: "POST",
      //       body: JSON.stringify(refreshObject),              
      //       headers: {
      //         Accept: "application/json",
      //         "Content-Type": "application/json",
      //       },
      //     },
      //     commit: { type: PEOPLE_LOAD_SUCCESS, meta: { refreshObject } },
      //     rollback: { type: PEOPLE_LOAD_ERROR, meta: { refreshObject } },
      //   },
      // },
    });
  }
 
return { success: true };
};

export const loadSelf = (token: string) => (dispatch: any) => {
  dispatch({
    type: PEOPLE_SELF_LOAD,
    payload: {},
    meta: {
      offline: {
        effect: {
          url: SELF_ENDPOINT,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
        },
        commit: { type: PEOPLE_SELF_LOAD_SUCCESS, meta: { token } },
        rollback: { type: PEOPLE_SELF_LOAD_ERROR, meta: { token } },
      },
    },
  });
};


export const personUpdate =
  (token: string, person: SelfType) => (dispatch: any) => {
    dispatch({
      type: PEOPLE_UPDATE,
      payload: {},
      meta: {
        offline: {
          effect: {
            url: PEOPLE_ENDPOINT + '/' + person.id, 
            method: 'PUT', 
            body: JSON.stringify(person), 
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          },
          commit: { type: PEOPLE_UPDATE_SUCCESS, meta: { person }},
          rollback: { type: PEOPLE_UPDATE_ERROR, meta: { person }}
          }
      }
    })
  };

export const selfActivate = (self: SelfType) => (dispatch: any) => {
  dispatch({ type: PEOPLE_SELF_ACTIVATE, payload: self });
  return self;
};

export const setSelfDoubloonBalance = (balance: number) => (dispatch: any) => {
  dispatch({ type: PEOPLE_SELF_UPDATE_BALANCE, payload: balance });
}

export const setSelfXpBalance = (balance: number) => (dispatch:any) => {
  dispatch({ type: PEOPLE_SELF_XP_UPDATE_BALANCE, payload: balance });

}

export const setAssetBalance = (balance: number) => (dispatch: any) => {
  dispatch({ type: PEOPLE_UPDATE_ASSET_BALANCE, payload: balance })
}

export const setXpAssetBalance = (balance: number) => (dispatch: any) => {
  dispatch({ type: PEOPLE_UPDATE_XP_ASSET_BALANCE, payload: balance })
}

export const setValidationError = (error: string) => (dispatch: any) => {
  dispatch({ type: PEOPLE_UPDATE_VALIDATION_ERROR, payload: error })
}

export const setInvalidFields = (error: Array<string>) => (dispatch: any) => {
  dispatch({ type: PEOPLE_UPDATE_INVALID_FIELDS, payload: error })
}

export const setActivePerson = (id: string) => (dispatch: any) => {
  dispatch({ type: PEOPLE_ACTIVATE, payload: id});
}