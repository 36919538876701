import React from 'react';
import { ThemeContext } from './ThemeContext';
import { LightBulb } from "../common/Icons"

const Toggle = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);

    return (
        <div className="transition duration-500 ease-in-out rounded-full">
            {theme === 'dark' ? (
                <button
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className="text-gray-100 dark:text-gray-100 cursor-pointer"
                ><LightBulb />
                </button>
            ) : (
                <button
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className="text-gray-100 dark:text-gray-100 cursor-pointer"
                ><LightBulb />
                </button>
            )}
        </div>
    );
};

export default Toggle;