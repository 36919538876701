/* This example requires Tailwind CSS v2.0+ */
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";

type PaginationPropsType = {
  page: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  updatePagination: Function;
};

const Pagination = (props: PaginationPropsType) => {
  const { page, startPage, endPage, updatePagination } = props;
  return (
    <nav className="px-4 flex items-center justify-between sm:px-0">
      {page !== startPage && (
        <div className="w-0 flex-1 flex">
          <button
            onClick={() => {
              updatePagination(page + 1)
            }}
            className="border-transparent pt-4 pr-1 inline-flex items-center text-xs font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
            <ArrowNarrowLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
        </div>
      )}
      {(page + 2) > endPage && (
        <div className="w-0 flex-1 flex justify-end">
          <button
            onClick={() => updatePagination(page - 1)}
            className="border-transparent pt-4 pl-1 inline-flex items-center text-xs font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
            Next
            <ArrowNarrowRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      )}
    </nav>
  );
};

export default Pagination;
