export const REFRESH_FILE = 'REFRESH_FILE';
export const REFRESH_FILE_SUCCESS = 'REFRESH_FILE_SUCCESS';
export const REFRESH_FILE_ERROR = 'REFRESH_FILE_ERROR';
export const OPTIMISTIC_FILE_SUCCESS = 'OPTIMISTIC_FILE_SUCCESS';

export const REFRESH_PERSON = 'REFRESH_PERSON';
export const REFRESH_PERSON_SUCCESS = 'REFRESH_PERSON_SUCCESS';
export const REFRESH_PERSON_ERROR = 'REFRESH_PERSON_ERROR';

export const POST_FILE_ERROR = 'POST_FILE_ERROR';
export const POST_FILE_SUCCESS = 'POST_FILE_SUCCESS';

export const REFRESH_ASSETS = 'REFRESH_ASSETS';
export const REFRESH_ASSETS_SUCCESS = 'REFRESH_ASSETS_SUCCESS';
export const REFRESH_ASSETS_ERROR = 'REFRESH_ASSETS_ERROR';

export const DESTROY_FILE = 'DESTROY_FILE';
export const DESTROY_FILE_SUCCESS = 'DESTROY_FILE_SUCCESS';
export const DESTROY_FILE_ERROR = 'DESTROY_FILE_ERROR';