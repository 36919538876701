/**
 * TypeScript Types
 */
 export interface AuthReducerTypes {
    id: string,
    isAuthLoading: boolean,
    token: string | null,
    isLoggedIn: boolean,
    email: string | null,
    tokenError?: boolean,
    stripe_account_link: string,
    emergency_logout: boolean,
    isFirstTime: boolean,
  }

  export interface errorsObjectType {
    email: string,
    password: string,
  }

  
  export const AUTH_LOGIN_BEGIN = 'AUTH_LOGIN_BEGIN';
  export const AUTH_API_LOGIN_SUCCESS = 'AUTH_API_LOGIN_SUCCESS';
  export const AUTH_API_LOGIN_ERROR = 'AUTH_API_LOGIN_ERROR';

  export const AUTH_FIREBASE_LOGIN_BEGIN = 'AUTH_FIREBASE_LOGIN_BEGIN';
  export const AUTH_FIREBASE_LOGIN_SUCCESS = 'AUTH_FIREBASE_LOGIN_SUCCESS';
  export const AUTH_FIREBASE_LOGIN_ERROR = 'AUTH_FIREBASE_LOGIN_ERROR'
  
  export const AUTH_LOGOUT_BEGIN = 'AUTH_LOGOUT_BEGIN';
  export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
  export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR';

  export const AUTH_VALIDATION_ERROR = 'AUTH_VALIDATION_ERROR';

  export const AUTH_TOKEN_NOT_FOUND_ERROR = 'AUTH_TOKEN_NOT_FOUND_ERROR';