import { Router } from "./components/Router";
import { Provider } from "react-redux";
import configStore from './configureStore';

const App = () => {
  return (
    <Provider store={configStore}>
      <Router />
    </Provider>
  );
}

export default App;
