import React, {  } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  loggedOutBuy,
} from "../../store/transactions/actions";
import store from "../../store";
// import { capitalizeFirstLetter } from "../../services/utils";
import {
  STRIPE_DB100_KEY,
  STRIPE_DB500_KEY,
  STRIPE_DB1625_KEY,
  // STRIPE_API_KEY
} from "../../constants/config";
import {
  BuyType,
  AssetType,
} from "../../types";
import {
  setSlideout,
  // setLoading,
  setActiveLanding,
} from "../../store/layout/actions";
import { BASE_WEB_URL } from "../../constants/endpoints";


export type RootState = ReturnType<typeof store>;

const PurchaseButtons = () => {

  const allAssets: { [id: string]: AssetType } = useSelector(
    (state: RootStateOrAny) => state.orm.Asset.itemsById
  );

  const self = useSelector((state: RootState) => state.people.self );

  const doubloonBalance = allAssets["DBLN"]?.qty;

  const stripeDb100 = STRIPE_DB100_KEY ? STRIPE_DB100_KEY : "";
  const stripeDb500 = STRIPE_DB500_KEY ? STRIPE_DB500_KEY : "";
  const stripeDb1625 = STRIPE_DB1625_KEY ? STRIPE_DB1625_KEY : "";

  const dispatch = useDispatch();

  const handlePayment = async (paymentId: string, amount: number) => {
    const idem = "p" + require("crypto").randomBytes(8).toString("hex");

    let successUrl = BASE_WEB_URL;
    let cancelUrl = BASE_WEB_URL;

    successUrl = successUrl + "/stripe_success"


    const buyObject: BuyType = {
      id: idem,
      provisioned_at: Date.now() / 1000,
      status: "PROVISIONED",
      stripePaymentKey: paymentId,
      dblnQty: amount,
      successUrl: successUrl,
      cancelUrl: cancelUrl
    };
    dispatch(setSlideout(false));
    dispatch(loggedOutBuy(buyObject));
    // dispatch(setLoading(true));
    dispatch(setActiveLanding("CONFIRMATION"));
  };

  type PButtonPropsType = {
    doubloonBalance: number;
    amount: number;
    asset: string;
    price: string;
    stripe: string;
  };

  const PButton = (props: PButtonPropsType) => {
    const { amount, price, stripe } = props;
    return (
      <div className="shadow-xl flex rounded-xl dark:bg-gray-900">
          <button
            onClick={() => handlePayment && handlePayment(stripe, amount)}
            className="w-24 h-24 sm:w-44 sm:h-44 flex place-content-center bg-primary-700 hover:bg-primary-900 rounded-xl shadow-xl p-4 items-center text-white grid grid-rows-2"
          >
            <div className="sm:text-3xl text-2xl pb-2 font-bold">{price}</div>
            <div className="text-xs sm:text-2xl pt-2">{amount}</div>
            <div className="text-xs sm:text-lg ">Doubloons</div>
          </button>
      </div>
    );
  };

  return (
    <div className="bg-white dark:bg-gray-900 pb-6">
      <>
        <div className="sm:px-10 px-8">
          <div className="flex ">

          </div>
            <>
              <div className="flex gap-1 space-around mx-auto justify-center pt-4">
                <PButton
                  doubloonBalance={doubloonBalance}
                  asset={self.asset_name ? self.asset_name : ""}
                  price={"$1.60"}
                  amount={100}
                  stripe={stripeDb100}
                />

                <PButton
                  doubloonBalance={doubloonBalance}
                  asset={self.asset_name ? self.asset_name : ""}
                  price={"$8"}
                  amount={500}
                  stripe={stripeDb500}
                />

                <PButton
                  doubloonBalance={doubloonBalance}
                  asset={self.asset_name ? self.asset_name : ""}
                  price={"$25"}
                  amount={1625}
                  stripe={stripeDb1625}
                />
              </div>
            </>
        </div>
      </>
    </div>
  );
};

export default PurchaseButtons;
