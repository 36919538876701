
/**
 * TypeScript Types
 */
import { SelfType, PersonType } from '../../types';

export interface PeopleReducerTypes {
  // byId: { [id: string]: PersonType };
  isPeopleLoading: boolean,
  activePerson: string,
  self: SelfType,
  stripe_account_link?: string | null,
  recent: Array<string>,
  featured: Array<string>,
  searchResults: Array<PersonType>,
}

/**
 * Action Types
 */

export const PEOPLE_SELF_ACTIVATE = 'PEOPLE_SELF_ACTIVATE';
export const PEOPLE_ACTIVATE = 'PEOPLE_ACTIVATE';

export const PEOPLE_SELF_LOAD = 'PEOPLE_SELF_LOAD';
export const PEOPLE_SELF_LOAD_SUCCESS = 'PEOPLE_SELF_LOAD_SUCCESS';
export const PEOPLE_SELF_LOAD_ERROR = 'PEOPLE_SELF_LOAD_ERROR';

export const PEOPLE_LOAD = 'PEOPLE_LOAD'
export const PEOPLE_LOAD_SUCCESS = 'PEOPLE_LOAD_SUCCESS';
export const PEOPLE_LOAD_ERROR = 'PEOPLE_LOAD_ERROR';

export const PEOPLE_UPDATE = 'PEOPLE_UPDATE';
export const PEOPLE_UPDATE_SUCCESS = 'PEOPLE_UPDATE_SUCCESS';
export const PEOPLE_UPDATE_ERROR = 'PEOPLE_UPDATE_ERROR';

export const PEOPLE_SELF_UPDATE_BALANCE = 'PEOPLE_SELF_UPDATE_BALANCE';
export const PEOPLE_SELF_XP_UPDATE_BALANCE = 'PEOPLE_SELF_XP_UPDATE_BALANCE';
export const PEOPLE_UPDATE_ASSET_BALANCE = 'PEOPLE_UPDATE_ASSET_BALANCE';
export const PEOPLE_UPDATE_XP_ASSET_BALANCE = 'PEOPLE_UPDATE_XP_ASSET_BALANCE';
export const PEOPLE_UPDATE_VALIDATION_ERROR = 'PEOPLE_UPDATE_VALIDATION_ERROR';
export const PEOPLE_UPDATE_INVALID_FIELDS = 'PEOPLE_UPDATE_INVALID_FIELDS';

export const PEOPLE_GET_FEATURED = 'PEOPLE_GET_FEATURED';
export const PEOPLE_GET_FEATURED_SUCCESS = 'PEOPLE_GET_FEATURED_SUCCESS';
export const PEOPLE_GET_FEATURED_ERROR = 'PEOPLE_GET_FEATURED_ERROR';

export const PEOPLE_ADD_IMAGE = 'PEOPLE_ADD_IMAGE';
export const PEOPLE_SEARCH = 'PEOPLE_SEARCH';
export const PEOPLE_SEARCH_SUCCESS = 'PEOPLE_SEARCH_SUCCESS';