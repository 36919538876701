import {
  FILE_ACTIVE_REPS,
  FILE_ADD_ACTIVE_FILE,
  FILE_CLEAR_ACTIVE_FILES,
  FILE_ADD_IMAGE_PASS,
  FILE_ADD_IMAGE_PASS_ERROR,
  FILE_ADD_IMAGE_PASS_SUCCESS,
  FILE_SELECT_FILE,
  FILE_UPLOAD_FILE_REQUEST,
  FILE_UPDATE_PENDING,
  FILE_REQUEST_UPLOADED_FILES,
  FILE_UPDATE_PROGRESS
} from "./constants";
import {
  IMAGE_PASS_ENDPOINT,
} from "../../constants/endpoints";
import {
  FileType
} from "../../types"

type uploadFileType = {
  parent_id: string,
  child_id: string,
  field: string,
  blob: Blob
}

export const addPendingFile = (file: FileType) => (dispatch: any) => {
  dispatch({
    type: FILE_UPDATE_PENDING,
    payload: file,
});
}

export const updateProgress = (pct: number) => (dispatch: any) => {
  dispatch({
    type: FILE_UPDATE_PROGRESS,
    payload: pct
  })
}

export const updateUrl = (url: any) => (dispatch: any) => {
  dispatch({
    type: FILE_REQUEST_UPLOADED_FILES,
    payload: url,
  });
}

export const uploadFile = (file: uploadFileType) => (dispatch: any) => {
  dispatch({
      type: FILE_UPLOAD_FILE_REQUEST,
      payload: file,
  });
};

export const selectFile = (id: string) => (dispatch: any) => {
  dispatch({ type: FILE_SELECT_FILE, payload: {id}});
}

export const setActiveRep = (idxAry: Array<number>) => (dispatch: any) => {
  dispatch({ type: FILE_ACTIVE_REPS, payload: { idxAry } });
};

export const setActiveFiles = (id: string) => (dispatch: any) => {
  dispatch({ type: FILE_ADD_ACTIVE_FILE, payload: id });
};

export const clearActiveFiles = () => (dispatch: any) => {
  dispatch({ type: FILE_CLEAR_ACTIVE_FILES });
};

export const addImagePass =
  (file: string, token: string) => (dispatch: any) => {
    dispatch({
      type: FILE_ADD_IMAGE_PASS,
      payload: { file },
      meta: {
        offline: {
          effect: {
            url: IMAGE_PASS_ENDPOINT,
            method: "POST",
            body: JSON.stringify(file),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          commit: { type: FILE_ADD_IMAGE_PASS_SUCCESS, meta: { file } },
          rollback: { type: FILE_ADD_IMAGE_PASS_ERROR, meta: { file } },
        },
      },
    });
  };

  // export const clearUnlockCandidate = () => (dispatch: any) => {
  //   dispatch({type: FILE_CLEAR_UNLOCK_CANDIDATE})
  // }
