import React from "react";
import {
  NeutralSm,
  FriendlySm,
  HonoredSm,
  ReveredSm,
  ExaltedSm,
} from "../../components/common/Icons";

type PostReputationIndicatorPropsType = {
  target_post_reputation: string;
};

const PostReputationIndicator = (props: PostReputationIndicatorPropsType) => {
  const { target_post_reputation } = props;

  return (
    <>
    <div className="relative">
      <div className="absolute top-0 right-0 grid grid-cols-5 flex justify-end items-end justify-self-end place-content-end w-28">
        {target_post_reputation === "NEUTRAL" ? (
          <img
            src={NeutralSm}
            alt=""
            loading="lazy"
            className="self-end flex mr-1 w-4 h-4 ring-2 bg-gray-400 ring-gray-400 rounded-full"
          />
        ) : (
          <div> </div>
        )}
        <></>
        {target_post_reputation === "NEUTRAL" ||
        target_post_reputation === "FRIENDLY" ? (
          <img
            src={FriendlySm}
            alt=""
            loading="lazy"
            className="mr-1 w-4 h-4 ring-2 bg-green-400 ring-green-400 rounded-full"
          />
        ) : (
          <div> </div>
        )}{" "}
        <></>
        {target_post_reputation === "NEUTRAL" ||
        target_post_reputation === "FRIENDLY" ||
        target_post_reputation === "HONORED" ? (
          <img
            src={HonoredSm}
            alt=""
            loading="lazy"
            className="mr-1 w-4 h-4 ring-2 bg-blue-400 ring-blue-400 rounded-full"
          />
        ) : (
          <div> </div>
        )}{" "}
        <></>
        {target_post_reputation === "NEUTRAL" ||
        target_post_reputation === "FRIENDLY" ||
        target_post_reputation === "HONORED" ||
        target_post_reputation === "REVERED" ? (
          <img
            src={ReveredSm}
            alt=""
            loading="lazy"
            className="mr-1 w-4 h-4 ring-2 bg-purple-400 ring-purple-400 rounded-full"
          />
        ) : (
          <div> </div>
        )}
        <></>
        {(target_post_reputation === "NEUTRAL" ||
          target_post_reputation === "FRIENDLY" ||
          target_post_reputation === "HONORED" ||
          target_post_reputation === "REVERED" ||
          target_post_reputation === "EXALTED") && (
          <img
            src={ExaltedSm}
            alt=""
            loading="lazy"
            className="mr-1 w-4 h-4 ring-2 bg-red-400 ring-red-400 rounded-full"
          />
        )}
      </div>
      </div>
    </>
  );
};

export default PostReputationIndicator;
