import { ORM } from 'redux-orm';
import { File, Person, Asset } from './models';

const orm: any = new ORM({
    stateSelector: state => orm,
})

orm.register(File);
orm.register(Person);
orm.register(Asset);

export default orm;