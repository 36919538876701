import React, { useState, useEffect } from "react";
import store from "../../store";
import { Confirm, PostBottomPanel, PostPhoto, PostTopPanel, RadioGroup } from ".";
import { useSelector, useDispatch } from "react-redux";
import { clearActiveFiles } from "../../store/files/actions";
import { setModal } from "../../store/layout/actions";
import { postFile, refreshFile } from "../../store/orm/actions";
import { setActiveRep } from "../../store/files/actions";
import rock from "../../screens/Feed/rock.webp";
import { destroyFile } from "../../store/orm/actions";
import { FileType, RefreshType } from "../../types";

export type RootState = ReturnType<typeof store>;

const PostEdit = () => {
  const dispatch = useDispatch();

  const activeFiles = useSelector(
    (state: RootState) => state.files.activeFiles
  );
  const allFiles: Array<any> = useSelector(
    (state: any) => state.orm.File.itemsById
  );
  const selfId = useSelector((state: RootState) => state.people.self.id);
  const [activeLoadingMessage, setActiveLoadingMessage] = useState("");
  const [bidValue, setBidValue] = useState(1);
  const token = useSelector((state: RootState) => state.auth.token);
  const selectedArray = useSelector(
    (state: RootState) => state.files.activeReps
  );
  const activeId: any = activeFiles[0];
  const id = allFiles[activeId]?.id;

  const handlePostButton = () => {
    const lowestRep = Math.min(...selectedArray);
    lowestRep === 0 && (reputation = "NEUTRAL");
    lowestRep === 1 && (reputation = "FRIENDLY");
    lowestRep === 2 && (reputation = "HONORED");
    lowestRep === 3 && (reputation = "REVERED");
    lowestRep === 4 && (reputation = "EXALTED");
    const fileObject = {
      id: id,
      reputation: reputation,
      bid: bidValue,
      person_id: selfId,
      status: "POSTED",
      last_refresh_time: Date.now() / 1000,
    };
    token && dispatch(postFile([fileObject], token));
    dispatch(clearActiveFiles());
    setActiveLoadingMessage("Upload Image");
    dispatch(setModal(false));
  };

  const handleBidChange = (e: any) => {
    e.target.value ? setBidValue(parseInt(e.target.value)) : setBidValue(0);
  };

  const activeFile: any = activeFiles[0];
  const realActiveFile: FileType = allFiles[activeFile];

  const [url, setUrl] = useState(realActiveFile?.ls_lq_url);

  let reputation = allFiles[activeFile]?.reputation;

  const [color, setColor] = useState("red");
  const [rep, setRep] = useState("EXALTED");
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const handleDestroy = () => {
    token && id && dispatch(destroyFile(id, token));
    dispatch(setModal(false));
  };

  useEffect(() => {
    realActiveFile && setBidValue(realActiveFile.bid ? realActiveFile.bid : 999999);
    realActiveFile && setUrl(realActiveFile.ls_lq_url);
  }, [realActiveFile]);

  useEffect(() => {
    const realActiveFile: FileType = allFiles[activeFile];
    realActiveFile && setBidValue(realActiveFile.bid ? realActiveFile.bid : 999999);
    realActiveFile && setUrl(realActiveFile.ls_lq_url);
  }, [activeFiles, activeFile, allFiles]);

  useEffect(() => {
    reputation === "NEUTRAL" && dispatch(setActiveRep([0, 1, 2, 3, 4]));
    reputation === "FRIENDLY" && dispatch(setActiveRep([1, 2, 3, 4]));
    reputation === "HONORED" && dispatch(setActiveRep([2, 3, 4]));
    reputation === "REVERED" && dispatch(setActiveRep([3, 4]));
    reputation === "EXALTED" && dispatch(setActiveRep([4]));
  }, [reputation, dispatch]);

  useEffect(() => {
    const lowestRep = Math.min(...selectedArray);
    if (lowestRep === 0) {
      setColor("gray");
      setRep("NEUTRAL");
      setBidValue(0);
    }

    if (lowestRep === 1) {
      setColor("green");
      setRep("FRIENDLY");
    }

    if (lowestRep === 2) {
      setColor("blue");
      setRep("HONORED");
    }

    if (lowestRep === 3) {
      setColor("purple");
      setRep("REVERED");
    }

    if (lowestRep === 4) {
      setColor("red");
      setRep("EXALTED");
    }
  }, [selectedArray]);

  useEffect(() => {
    const refreshObject: RefreshType = {
      id: realActiveFile?.id,
      last_refresh_time: realActiveFile?.last_refresh_time,
    };
    token && realActiveFile && dispatch(refreshFile([refreshObject], token));
  }, [token, realActiveFile, dispatch]);

  // useEffect(() => {
  //   console.log(url)
  // },[url])

  const handleRepChange = () => {
    const lowestRep = Math.min(...selectedArray);
    lowestRep === 0 && (reputation = "NEUTRAL");
    lowestRep === 1 && (reputation = "FRIENDLY");
    lowestRep === 2 && (reputation = "HONORED");
    lowestRep === 3 && (reputation = "REVERED");
    lowestRep === 4 && (reputation = "EXALTED");
    const fileObject = {
      id: id,
      reputation: reputation,
      bid: bidValue,
      person_id: selfId,
      status: "POSTED",
      last_refresh_time: Date.now() / 1000,
    };
    token && dispatch(postFile([fileObject], token));
  }

  return (
    <div className="py-2 sm:py-4 px-3 sm:px-6 ">
      <PostTopPanel
        color={color}
        rep={rep}
        setIsConfirmationVisible={setIsConfirmationVisible}
      />

      <div className="flex justify-end w-full ">
        <RadioGroup 
          id={id}
          setReputation={handleRepChange}
        />
      </div>

      {/* Main Photo */}
      {isConfirmationVisible ? (
        <div className="flex">
          <Confirm 
            handleDestroy={handleDestroy}
            setIsConfirmationVisible={setIsConfirmationVisible}
            color={color}
            url={url}
            rock={rock}
          />
        </div>
      ) : (
        <div className="flex">
          <PostPhoto
            setActiveLoadingMessage={setActiveLoadingMessage}
            activeLoadingMessage={activeLoadingMessage}
            repColor={color}
            thumbnailUrl={url ? url : rock}
            isEdit={activeFile ? true : false}
          />
        </div>
      )}

      <div className="">
        <PostBottomPanel
          handleBidChange={handleBidChange}
          bgColor={color}
          amount={bidValue}
          reputation={rep}
          handlePostButton={handlePostButton}
        />
      </div>
    </div>
  );
};

export default PostEdit;
