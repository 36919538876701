import React, { } from "react";
import { useSelector } from "react-redux";
// import { Image } from "../common/Image";
import { XpMeter } from "../XpMeter";
import { getReputation } from "../../services/xpService";
// import { Button } from "../common/Button";
// import {
//   FacebookSvg,
//   InstagramSvg,
//   TiktokSvg,
//   SoundcloudSvg,
//   ArrowLeftIcon,
// } from "../common/Icons";
import { AssetType, PersonType } from "../../types";
import store from "../../store";
import { capitalizeFirstLetter } from "../../services/utils";
// import { getReputationColor } from '../../services/xpService';
// import { setActiveLanding } from "../../store/layout/actions";

export type RootState = ReturnType<typeof store>;

type PersonPanelPropsType = {
  personId: string;
};

const PersonPanel = (props: PersonPanelPropsType) => {
  const { personId } = props;
  // const isSlideoutVisible: boolean = useSelector((state: RootState) => state.layout.isSlideoutVisible);
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );
  const allAssets: { [id: string]: AssetType } = useSelector(
    (state: any) => state.orm.Asset.itemsById
  );
  const asset_id: string = allPeople && allPeople[personId]?.asset_id;
  const xp_asset_id: string = allPeople && allPeople[personId]?.xp_asset_id;
  const asset: AssetType = allAssets[asset_id];
  const xp_asset: AssetType = allAssets[xp_asset_id];
  // const photo_url: string = allPeople && allPeople[personId]?.photo_url;
  const name: string = allPeople && allPeople[personId]?.name;
  // const facebook_link: string | undefined =
    // allPeople && allPeople[personId]?.facebook_link;
  // const instagram_link: string | undefined =
    // allPeople && allPeople[personId]?.instagram_link;
  // const tiktok_link: string | undefined =
    // allPeople && allPeople[personId]?.tiktok_link;
  // const soundcloud_link: string | undefined =
    // allPeople && allPeople[personId]?.soundcloud_link;
  const asset_name: string | undefined =
    allPeople && allPeople[personId]?.asset_name;
  const assetBalance: number = asset?.qty;
  const xpBalance: number = xp_asset?.qty;
  // const [activeSocialLink, setActiveSocialLink] = useState("NONE");
  // const activeLanding: string = useSelector(
  //   (state: RootState) => state.layout.activeLanding
  // );

  const numFormatter = (num: number) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 999999 && num < 999999999) {
      return (num / 1000000).toFixed(2) + "M"; // convert to M for number from > 1 million
    } else if (num > 999999999) {
      return (num / 1000000000).toFixed(2) + "B"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  };

  // useEffect(() => {
  //   activeSocialLink !== "NONE" &&
  //     window.location.assign("https://" + activeSocialLink);
  // }, [activeSocialLink]);

  const reputation = getReputation(xpBalance);
  
  // const dispatch = useDispatch();
  // const handleBackClick = () => {
  //   dispatch(setActiveLanding("FEED"));
  //   // window.location.href = '/';
  // };


  return (
      <div
        className={
          "pt-2 sm:pt-4 items-center px-2 sm:px-6 w-full flex rounded-xl" +
          " bg-" +
          reputation.color + "-700"
        }
      >

        <div className="w-full pb-3 pr-1 pl-1 ">
          <div className="grid grid-rows flex justify-start text-white text-md sm:text-xl font-bold">
            {name}
          </div>

          <div className="w-full">
            

            <div className="grid grid-cols-3 flex w-full">
              <div
                className="grid grid-rows text-white text-left sm:text-xs w-full"
                style={{ fontSize: "0.5em" }}
              >
                {/* <div>{reputation.reputation}</div> */}
                <div className="">
                  {xpBalance ? numFormatter(xpBalance) : 0}{" "}{xpBalance < 4651 && "/ " + numFormatter(reputation.levelCeiling)}{" XP"}
                </div>
              </div>
              <div className="flex"></div>
              <div
                className="grid grid-rows text-white text-right justify-end -mt-5"
                // style={{ fontSize: "0.6em" }}
              >
                <div style={{ fontSize: "0.6em" }}>{asset_name && capitalizeFirstLetter(asset_name)}</div>
                <div className="text-2xl font-bold -mt-1">{assetBalance ? numFormatter(assetBalance) : 0}</div>
              </div>
            </div>
            <XpMeter amount={xpBalance} />
          </div>
        </div>
      </div>
  );
};

export default PersonPanel;
