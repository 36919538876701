import React, { useEffect } from "react";
import { Thumbnail } from ".";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
// import { getReputation } from "../../services/xpService";
import { FileType, RefreshType } from "../../types";
import { refreshFile } from "../../store/orm/actions";
// import { Pagination } from "../Pagination";
// import { paginate } from "../../services/utils";
// import SwipeableViews from "react-swipeable-views";
// import { ChevronLeft, ChevronRight } from "../../components/common/Icons";
import {
  CarouselProvider,
  Slider,
  Slide
} from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';

export type RootState = ReturnType<typeof store>;
type AssetRowPropsType = {
  variant: string;
  isSelf: boolean;
  files?: Array<string>;
};

const AssetRow = (props: AssetRowPropsType) => {
  const { variant, files } = props;
  // const allPeople: { [id: string]: PersonType } = useSelector(
  //   (state: any) => state.orm.Person.itemsById
  // );
  const allFiles: { [id: string]: FileType } = useSelector(
    (state: any) => state.orm.File.itemsById
  );
  const token: string = useSelector((state: any) => state.auth.token);
  // const activePersonId: string = useSelector(
  //   (state: RootState) => state.people.activePerson
  // );
  // const selfId: string = useSelector(
  //   (state: RootState) => state.people.self.id
  // );
  const isLoggedIn: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  // const activePerson: PersonType = allPeople && allPeople[activePersonId];
  // const filesArray: Array<string> = useSelector(
  //   (state: any) => state.orm.File.items
  // );

  // const [page, setPage] = useState(1);

  // let selfFilesArray: Array<string> = [];
  // filesArray.map((file: string) => {
  //   if (allFiles[file]?.person_id === selfId) {
  //     selfFilesArray.push(allFiles[file].id);
  //   }
  //   return true;
  // });

  // const sortByDate = (a: any, b: any) => {
  //   const fileA = allFiles[a]?.created_at;
  //   const fileB = allFiles[b]?.created_at;
  //   if (fileA && fileB) {
  //     if (fileA < fileB) {
  //       return 1;
  //     }
  //     if (fileA > fileB) {
  //       return -1;
  //     }
  //   }
  //   return 0;
  // };

  // selfFilesArray.sort(sortByDate);
  // let activeFilesArray = activePerson?.images;
  // activeFilesArray?.sort(sortByDate);
  let images = files;

  const dispatch = useDispatch();

  useEffect(() => {
    let refreshArray: Array<RefreshType> = [];
    images?.forEach((image) => {
      const refreshObject = {
        id: image,
        last_refresh_time: allFiles[image]?.last_refresh_time
          ? allFiles[image]?.last_refresh_time
          : 1,
      };
      !refreshArray.includes(refreshObject) && refreshArray.push(refreshObject);
    });
    token
      ? dispatch(refreshFile(refreshArray, token))
      : dispatch(refreshFile(refreshArray));
  }, [images]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className="md:my-2 md:pb-2 flex w-full bg-white dark:bg-gray-800">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={images?.length ? images.length : 0}
          className="w-full px-4 col-span-12 md:col-span-10 dark:bg-gray-800"
          visibleSlides={3}
          dragStep={3}
        >

          <Slider className="dark:bg-gray-800" >
            {images?.map((image:string, idx: number) => {
              const hidden = false;
                return(
                <Slide index={idx} key={idx} className="dark:bg-gray-800">
                    <Thumbnail
                      key={idx}
                      id={allFiles[image]?.id}
                      variant={variant}
                      sq_lq_url={allFiles[image]?.sq_lq_url}
                      lg_lq_url={allFiles[image]?.lg_lq_url}
                      sq_bl_url={allFiles[image]?.sq_bl_url}
                      lg_bl_url={allFiles[image]?.lg_bl_url}
                      rep={allFiles[image]?.reputation}
                      isBlurred={!!allFiles[image]?.is_blurred}
                      isLoggedIn={isLoggedIn}
                      price={allFiles[image]?.bid}
                      hidden={hidden}
                    />
                  </Slide>)
            })}

          </Slider>
        </CarouselProvider>
    </div>
  );
};

export default AssetRow;
