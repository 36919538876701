import React, { } from "react";
import { PhotographIcon, TrashCanIcon } from "../common/Icons";
import {
  NeutralSm,
  FriendlySm,
  HonoredSm,
  ReveredSm,
  ExaltedSm,
} from "../common/Icons";
import {
  setActiveBlurPhoto,
  setActiveModal,
  setActivePhoto,
} from "../../store/layout/actions";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { FileType } from "../../types";

export type RootState = ReturnType<typeof store>;

type PostTopPanelPropsType = {
  rep: string;
  color: string;
  setIsConfirmationVisible: Function;
};

const PostTopPanel = (props: PostTopPanelPropsType) => {
  const { rep, color, setIsConfirmationVisible } = props;
  const dispatch = useDispatch();

  const activeFiles = useSelector(
    (state: RootState) => state.files.activeFiles
  );
  const allFiles: Array<any> = useSelector(
    (state: any) => state.orm.File.itemsById
  );


  const activeFile: any = activeFiles[0];
  const realActiveFile: FileType = allFiles[activeFile];

  const handleFullScreen = () => {
    const lg_bl_url = realActiveFile?.lg_bl_url
      ? realActiveFile?.lg_bl_url
      : "";
    const lg_hq_url = realActiveFile?.lg_hq_url
      ? realActiveFile?.lg_hq_url
      : "";
    dispatch(setActiveModal("PHOTO"));
    dispatch(setActiveBlurPhoto(lg_bl_url));
    dispatch(setActivePhoto(lg_hq_url));
  };

  return (
    <div className="flex grid grid-cols-12 -mt-2">
      <div
        className={`col-span-2 sm:col-span-1 justify-start flex grid text-${color}-700 pt-3 h-10 w-10`}
      >
        {rep === "NEUTRAL" && (
          <img src={NeutralSm} alt="" className="h-10 w-10" />
        )}
        {rep === "FRIENDLY" && (
          <img src={FriendlySm} alt="" className="h-10 w-10" />
        )}
        {rep === "HONORED" && (
          <img src={HonoredSm} alt="" className="h-10 w-10" />
        )}
        {rep === "REVERED" && (
          <img src={ReveredSm} alt="" className="h-10 w-10" />
        )}
        {rep === "EXALTED" && (
          <img src={ExaltedSm} alt="" className="h-10 w-10" />
        )}
      </div>
      <div className="mt-1 sm:pl-2 text-gray-700 col-span-10 sm:col-span-11">
        <div
          className={`flex justify-start pl-4 text-xl font-bold text-${color}-700`}
        >
          Asset
        </div>
        <div className="flex justify-start pl-4 text-sm">
          <button
            className="cursor-pointer z-10"
            style={{ cursor: "pointer" }}
            onClick={() => handleFullScreen()}
          >
            <PhotographIcon />
          </button>
          <button
            className="cursor-pointer z-10"
            onClick={() => setIsConfirmationVisible(true)}
          >
            <TrashCanIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostTopPanel;
