import React, { useEffect } from "react";
import { FirebaseLogin } from "../../components/FirebaseLogin";
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../services/utils";
import { buy, clearBuy, loggedOutBuy } from '../../store/transactions/actions';
// import { setLoading } from "../../store/layout/actions";

export type RootState = ReturnType<typeof store>;

type LoginConfirmationPropsType = {};

const LoginConfirmation = (props: LoginConfirmationPropsType) => {
  const tipTransaction = useSelector(
    (state: RootState) => state.transactions.buyTransaction.tipTransaction
  );

  const buyObject = useSelector(
    (state: RootState) => state.transactions.buyTransaction
  );
  const token = useSelector((state: RootState) => state.auth.token);
  const redirect = useSelector((state: RootState) => state.transactions.redirect);
  const isLoading = useSelector((state: RootState) => state.layout.isLoading);


  const dispatch = useDispatch();

  // useEffect(() => {
  //   const loginInterval = setInterval(() => {
  //     setIsLoading(false)
  //     email && handleLinkClick();
  //   }, 500);
  //   return () => {
  //     clearInterval(loginInterval)
  //     setIsLoading(false)
  //   };
  // }, [email, dispatch]);


  useEffect(() => {
    dispatch(loggedOutBuy(buyObject));
    token && dispatch(buy(buyObject, token))
  },[buyObject, dispatch, token])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(redirect !== ''){
        dispatch(clearBuy());
        window.location.assign(redirect);
      }
    },500)
    return () => clearInterval(intervalId);
  },[redirect, dispatch]);

  // useEffect(() => {
  //   dispatch(setLoading(false))
  // },[dispatch])

  return (
    <>
      {isLoading ? (
        <div className="p-8 flex justify-center text-3xl">Loading</div>
      ) : (
        <div className="px-8">
          <div className="text-gray-700 text-3xl font-bold mx-auto pb-4 flex justify-center uppercase">
            <div>
              <div className="mx-auto text-center">Claim your</div>
            </div>
          </div>
          <div
            className={
              "text-8xl font-bold mx-auto flex justify-center text-primary-700"
            }
          >
            {tipTransaction?.asset_qty}
          </div>
          <div className="text-gray-700 font-bold mx-auto pt-6 flex justify-center uppercase">
            <div>
              <div className="mx-auto text-center text-3xl">
                {capitalizeFirstLetter(
                  tipTransaction?.asset_name
                    ? tipTransaction?.asset_name
                    : "Currency"
                )}{" "}
                and XP
              </div>
            </div>
          </div>
          <div className="text-gray-700 mx-auto flex justify-center pt-4 text-lg ">
            <div className="flex justify-center grid-rows grid mx-auto flex items-start">
              {/* <div className="mx-auto">Click to Accept the Contract</div> */}
              <div className="mx-auto " style={{ fontSize: "0.5rem" }}>
                By clicking you agree to all this legalese
              </div>
              <div className="mx-auto">
                <FirebaseLogin />
              </div>
              {/* <button
                className="mx-auto font-bold text-sm pt-4 text-primary-700 hover:text-primary-900"
                onClick={() => handleLinkClick()}
              >
                No thanks, continue anonymously
              </button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginConfirmation;
