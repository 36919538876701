import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { ParrotIconSvg, BadgeCheckIcon, ClockIcon } from "../common/Icons";
import {
  setActiveModal,
  setModal,
} from "../../store/layout/actions";
import { transactionActivate } from '../../store/transactions/actions';
import { PaginationType } from "../../types";


import { Pagination } from "../Pagination";
import { paginate } from "../../services/utils";

export type RootState = ReturnType<typeof store>;

const TxLog = () => {

  const activeTransactions = useSelector(
    (state: RootState) => state.transactions.activeTransactions
  );
  const stagedTransactions = useSelector(
    (state: RootState) => state.transactions.stagedTransactions
  );
  const confirmedTransactions = useSelector(
    (state: RootState) => state.transactions.confirmedTransactions
  );

  const dispatch = useDispatch();

  const [mergedTransactions, setMergedTransactions] = useState(confirmedTransactions);
  const [page, setPage] = useState(1);

  const paginationObjectFooToo = mergedTransactions
    ? paginate(mergedTransactions.length)
    : {
        totalItems: 0,
        currentPage: 0,
        pageSize: 0,
        totalPages: 0,
        startPage: 0,
        endPage: 0,
        startIndex: 0,
        endIndex: 0,
        pages: [0],
      };
  const [paginationObject, setPaginationObject] = useState<PaginationType>(
    paginationObjectFooToo
  );
  const updatePagination = (currentPage: number) => {
    setPage(currentPage);
    mergedTransactions && paginate(mergedTransactions.length);
    setPaginationObject(paginationObject);
  };


  useEffect(() => {
      setMergedTransactions([
        ...activeTransactions,
        ...stagedTransactions,
        ...confirmedTransactions,
      ]);
  }, [confirmedTransactions, activeTransactions, stagedTransactions]);

  return (
    <>
      <ul className="px-2 pt-2">

        {mergedTransactions?.map((transaction, idx) => {
          const adjustedIndex = idx + (page - 1) * 9;

          let transactionColor = "text-green-700 dark:text-green-300";
          let accentColor = "bg-green-100 dark:bg-green-900";
          if (transaction.asset_qty < 0) {
            transactionColor = "text-red-700 dark:text-red-300";
            accentColor = "bg-red-100 dark:bg-red-900";
          }
          const utcSeconds = transaction.provisioned_at;
          let transactionTime = new Date(0);
          transactionTime.setUTCSeconds(utcSeconds);
          return (
            <div key={idx} className="">
              {adjustedIndex >= 0 && adjustedIndex < 9 && transaction.asset_name != null && transaction.asset_qty != 0 &&( // eslint-disable-line 
                <>
                  <li
                    key={transaction.id}
                    className={
                      "rounded-md border-1 mb-2 shadow-md cursor-pointer dark:bg-gray-800 "
                    }
                    onClick={() => {
                      if (transaction) {
                        dispatch(transactionActivate(transaction));
                        dispatch(setActiveModal("TX_DETAIL"));
                        dispatch(setModal(true));
                      }
                    }}
                  >
                    <div
                      className={
                        "h-2 relative rounded-t-md overflow-hidden w-full " +
                        accentColor
                      }
                    ></div>
                    <div className="flex space-x-3 px-4 py-2">
                      <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between">
                          <div className="flex-shrink pr-2">
                            {transaction.target_name === "Libertalia" ? (
                              <div className="bg-white rounded-full border-white border"><ParrotIconSvg /></div>
                            ) : (
                              <img
                                className="h-6 w-6 rounded-full"
                                src={transaction.target_photo_url}
                                alt=""
                              />
                            )}
                          </div>
                          {(transaction.status === "CHAIN_CONFIRMED" || transaction.status === "STRIPE_CONFIRMED") && (
                            <div className="text-yellow-500 pr-2">
                              <BadgeCheckIcon />
                            </div>
                          )}
                          {transaction.status === "PROVISIONED" && (
                            <div className="text-gray-300 pr-2">
                              <ClockIcon />
                            </div>
                          )}
                          <div className="flex-grow">
                            <h3 className="text-sm font-medium dark:text-gray-300">
                              {transaction.target_name}
                            </h3>
                            <p className="text-xs text-gray-500">
                              {transactionTime.toLocaleString()}
                            </p>
                          </div>

                          <div className="">
                            <div
                              className={
                                "flex justify-end text-sm " + transactionColor
                              }
                            >
                              {transaction.asset_qty}
                            </div>
                            <div className="flex justify-end text-xs text-gray-400">
                              {transaction.asset_name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              ) }
            </div>
          );
        })}
      </ul>
      <div className="flex w-full grid p-0 m-0">
        {mergedTransactions && mergedTransactions?.length > 9 && (
          <Pagination
            page={page}
            totalPages={paginationObject.totalPages}
            startPage={paginationObject.startPage}
            endPage={paginationObject.endPage}
            updatePagination={updatePagination}
          />
        )}
      </div>
    </>
  );
};

export default TxLog;
