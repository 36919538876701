import { debounce, takeEvery } from 'redux-saga/effects';
// import * as actionTypes from './files';
import { uploadFileSaga } from './fileuploadsaga';
import { optimisticUploadSaga } from './orm/optimisticUploadSaga';
import { peopleSearchSaga } from './people/peopleSearchSaga';
import { FILE_UPLOAD_FILE_REQUEST } from './files/constants';
import { OPTIMISTIC_FILE_SUCCESS } from './orm/constants';
import { PEOPLE_SEARCH } from './people/constants';

export default function* rootSaga() {
    const watcher = yield takeEvery(FILE_UPLOAD_FILE_REQUEST, uploadFileSaga);
    yield takeEvery(OPTIMISTIC_FILE_SUCCESS, optimisticUploadSaga);
    yield debounce(500, PEOPLE_SEARCH, peopleSearchSaga);
    return watcher;
}
