import React, { useEffect } from "react";
import store from "../../store";
import { RecentPeople } from ".";
import LoginPanel from "./LoginPanel";
import { useSelector, useDispatch } from "react-redux";
import { RefreshType, PersonType, FileType } from "../../types";
import { refreshPerson } from "../../store/orm/actions";
import { getFeatured } from "../../store/people/actions";
import { AssetRow, ProducerRibbon } from "../../components/AssetGrid";
import { Avatar } from "../../components/Avatar";
import Search from "../../components/Search/Search";

export type RootState = ReturnType<typeof store>;

const Feed = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  // const token = useSelector((state: RootState) => state.auth.token);
  const subscriptions = useSelector(
    (state: RootState) => state.people.self.subscriptions
  );
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );
  const allFiles: { [id: string]: FileType } = useSelector(
    (state: any) => state.orm.File.itemsById
  );
  const self = useSelector((state: RootState) => state.people.self);
  const featured = useSelector((state: RootState) => state.people.featured);
  const recent = useSelector((state: RootState) => state.people.recent);
  const publishedImages: Array<string> | undefined = useSelector(
    (state: RootState) => state.people.self.publishedImages
  );

  // Refresh Featured People and their Files
  useEffect(() => {
    const refreshArray: Array<RefreshType> = [];
    const refreshFilesArray: Array<RefreshType> = [];
    featured?.map((sub: any) => {
      const refreshObject = {
        id: sub,
        last_refresh_time: 1,
      };
      const person = allPeople[sub];
      const images = person?.images;
      images?.map((image) => {
        const fileRefreshObject = {
          id: image,
          last_refresh_time: allFiles[image].last_refresh_time,
        };
        refreshFilesArray.push(fileRefreshObject);
        return true;
      });

      refreshArray.push(refreshObject);
      return true;
    });
    refreshArray.length > 0 && dispatch(refreshPerson(refreshArray));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featured]);

  // Get Featured People
  useEffect(() => {
    dispatch(getFeatured());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh People in Subscriptions
  useEffect(() => {
    const refreshArray: Array<RefreshType> = [];
    subscriptions?.map((sub: any) => {
      const refreshObject = {
        id: sub.producer_id,
        last_refresh_time: 1,
      };
      refreshArray.push(refreshObject);
      return true;
    });
    refreshArray.length > 0 && dispatch(refreshPerson(refreshArray));
  }, [subscriptions, dispatch]);

  return (
    <>
      {/* <div className=""> */}
      <div className="max-h-full justify-center mx-auto">
        <div className="w-full ">
          {!isLoggedIn && (
            <div className="justify-center py-8">
              <LoginPanel />
            </div>
          )}
          <div className="justify-center p-4">
            <Search />
          </div>

          {recent.length > 0 && (
            <div className="py-2 flex grid">
              <div className="font-bold pl-4 pb-2 dark:text-gray-400 text-gray-600 flex">
                Recent
              </div>
              <div className="flex min-w-full w-full grid ">
                <RecentPeople />
              </div>
            </div>
          )}

          <div>
            {!isLoggedIn && (
              <>
                <div className="py-2 w-full max-w-full grid flex ">
                  <div className="font-bold pl-4 dark:text-gray-400 text-gray-600">Featured</div>
                  {featured?.map((sub: string, idx: number) => {
                    if (sub !== self.id) {
                      return (
                        <div key={idx} className="space-y-1 mx-4">
                          <ProducerRibbon producer_id={allPeople[sub]?.id} />
                        </div>
                      );
                    }
                    return true;
                  })}
                </div>
              </>
            )}

            {isLoggedIn && subscriptions && subscriptions.length > 0 && (
              <>
                <div className="py-2 w-full max-w-full grid flex ">
                  <div className="font-bold pl-4 dark:text-gray-400 text-gray-600">Subscriptions</div>
                  {subscriptions?.map((sub: any, idx: number) => {
                    if (sub.producer_id !== self.id) {
                      return (
                        <div key={idx} className="space-y-1 mx-4">
                          <ProducerRibbon
                            producer_id={allPeople[sub.producer_id]?.id}
                          />
                        </div>
                      );
                    }
                    return true;
                  })}
                </div>
              </>
            )}
          </div>
          {isLoggedIn && (
            <>
              {self?.publishedImages && self?.publishedImages?.length > 0 ? (
                <div className="mx-4 justify-center border-2 dark:border-gray-700 dark:bg-gray-800 shadow-xl rounded-xl space-y-4 pb-4">
                  <div className="font-bold pl-4 text-lg dark:text-gray-400 text-gray-600 pt-4 flex grid grid-cols-12 rounded-xl flex">
                    <div className="flex col-span-1 w-16 px-1">
                      {self.photo_url && (
                        <Avatar
                          image_url={self.photo_url}
                          blUrl={self.photo_url}
                        />
                      )}
                    </div>
                    <div className="flex pl-12 col-span-9 sm:col-span-10 grid grid-rows-2">
                      <div className="font-bold text-lg">My Posts</div>
                      <div className="text-xs font-normal">
                        {self.publishedImages.length} Active
                      </div>
                    </div>
                    <div className="text-primary-700 text-right pr-4 col-span-2 sm:pr-1 sm:col-span-1 "></div>
                  </div>
                  <AssetRow
                    variant="SELF"
                    isSelf={true}
                    files={publishedImages}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </>
          )}
        </div>

        {/* {recent.length > 0 && (
          <div className="invisible md:visible pt-2">
            <div className="font-bold pl-4 text-gray-600">Recent </div>
            <RecentPeoplePanels />
          </div>
        )} */}
      </div>
    </>
  );
};

export default Feed;
