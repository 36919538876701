import React from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { PersonType, AssetType } from "../../types";
import { getReputation } from "../../services/xpService";
import { loadPerson } from "../../store/people/actions";
import { setActiveLanding } from "../../store/layout/actions";
import { Image } from "../common/Image";
import { numFormatter, capitalizeFirstLetter } from "../../services/utils";
import { XpMeter } from "../XpMeter";
import { AssetRow } from ".";

export type RootState = ReturnType<typeof store>;

type ProducerRibbonPropsType = {
  producer_id: string;
};

const ProducerRibbon = (props: ProducerRibbonPropsType) => {
  const dispatch = useDispatch();

  const allAssets: { [id: string]: AssetType } = useSelector(
    (state: any) => state.orm.Asset.itemsById
  );

  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );

  const { producer_id } = props;
  const person = allPeople[producer_id];
  const images = person?.images ? person?.images : [""];
  const photo_url: string = person?.photo_url;
  const xp_asset_id: string = allPeople && allPeople[person?.id]?.xp_asset_id;

  const xp_asset: AssetType = allAssets[xp_asset_id];
  const xpBalance: number = xp_asset?.qty;
  const reputation = getReputation(xpBalance);
  const asset_name: string | undefined =
    allPeople && allPeople[producer_id]?.asset_name;
  const asset_id: string = allPeople && allPeople[person?.id]?.asset_id;
  const asset: AssetType = allAssets[asset_id];

  const assetBalance: number = asset?.qty;

  const isLoggedIn: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const handleProfileClick = (target_id: string) => {
    const refreshObject = {
      id: target_id,
      last_refresh_time: 1,
    };
    dispatch(loadPerson([refreshObject]));
    dispatch(setActiveLanding("TARGET_PROFILE"));
  };

  if (images.length > 0) {
    return (
      <div className="flex w-full justify-center ">
        <div className="border-2 dark:border-gray-700 w-full rounded-xl shadow-xl my-2 grid grid-rows-12 dark:bg-gray-800">
          <div className="flex px-4 h-14 min-w-full w-full grid-start-1">
            <button
              onClick={() => handleProfileClick(person.id)}
              className="block grid-cols-12 flex focus:outline-none pt-2 w-full"
              data-testid="button-test"
            >
              {photo_url && (
                <div className="flex col-span-2 p-1">
                  <Image
                    url={photo_url}
                    blUrl={photo_url}
                    className="ring ring-white rounded-full w-16"
                    onLoad={() => {
                      return true;
                    }}
                  />
                </div>
              )}

              <div className="flex w-full pb-3 pr-1 grid grid-rows-2 col-span-8 pl-4">
                <div className="dark:text-gray-200 text-gray-800 grid min-w-full flex justify-start text-xl font-bold">
                  {person?.name}
                </div>

                <div className="w-full">
                  <div className="grid grid-cols-3 flex w-full">
                    <div className="grid grid-rows dark:text-gray-300 text-gray-600 text-left text-xs w-full">
                      <div className="">@{person?.username}</div>
                      {isLoggedIn && (
                        <div className="" style={{ fontSize: "0.8em" }}>
                          {xpBalance ? numFormatter(xpBalance) : 0}{" "}
                          {xpBalance < 4651 &&
                            "/ " + numFormatter(reputation.levelCeiling)}
                          {" XP"}
                        </div>
                      )}
                    </div>
                    <div className="flex"></div>
                  </div>
                </div>
              </div>

              <div className="grid col-span-2 grid-rows dark:text-gray-300 text-gray-600 text-right justify-end ">
                <div style={{ fontSize: "0.8em" }}>
                  {asset_name && capitalizeFirstLetter(asset_name)}
                </div>
                {isLoggedIn && (
                  <div className="text-2xl font-bold -mt-2">
                    {assetBalance ? numFormatter(assetBalance) : 0}
                  </div>
                )}
              </div>
            </button>
          </div>

          {isLoggedIn && (
            <div className="px-5 sm:pt-3 md:pt-2 lg:pt-4 pt-2 sm:mb-3 w-full h-5 row-start-2">
              <XpMeter amount={xpBalance} />
            </div>
          )}

          <div className="flex min-w-full w-full row-start-3 py-2">
            <AssetRow
              variant="SMALL"
              isSelf={false}
              files={allPeople[producer_id]?.images}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ProducerRibbon;
