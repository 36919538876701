import React, { useEffect, useState } from "react";
import { Thumbnail } from ".";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { PersonType, FileType, RefreshType, PaginationType } from "../../types";
import { refreshFile } from "../../store/orm/actions";
import { Pagination } from "../Pagination";
import { paginate } from "../../services/utils";
import { format, addDays, getISODay } from "date-fns";

export type RootState = ReturnType<typeof store>;
type AssetGridPropsType = {
  variant: string;
  isSelf: boolean;
};

const AssetGrid = (props: AssetGridPropsType) => {
  const { variant } = props;
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );
  const allFiles: { [id: string]: FileType } = useSelector(
    (state: any) => state.orm.File.itemsById
  );
  const token: string = useSelector((state: any) => state.auth.token);
  const activePersonId: string = useSelector(
    (state: RootState) => state.people.activePerson
  );
  const selfId: string = useSelector(
    (state: RootState) => state.people.self.id
  );
  const isLoggedIn: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const activePerson: PersonType = allPeople && allPeople[activePersonId];
  const selfFiles: Array<string> | undefined = useSelector(
    (state: RootState) => state.people.self.images
  );

  const [page, setPage] = useState(1);

  const sortByDate = (a: any, b: any) => {
    const fileA = allFiles[a]?.created_at;
    const fileB = allFiles[b]?.created_at;
    if (fileA && fileB) {
      if (fileA < fileB) {
        return -1;
      }
      if (fileA > fileB) {
        return 1;
      }
    }
    return 0;
  };

  let selfFilesArray: Array<string> = [];
  selfFiles &&
    selfFiles?.map((file: string) => {
      if (allFiles[file]?.person_id === selfId) {
        selfFilesArray.push(allFiles[file]?.id);
      }
      return true;
    });

  const [sortedArray, setSortedArray] = useState(selfFilesArray);

  selfFilesArray.sort(sortByDate);
  let activeFilesArray = activePerson?.images;
  activeFilesArray?.sort(sortByDate);

  const [images, setImages] = useState(activePerson?.images);

  useEffect(() => {
    setImages(activePerson?.images);
  }, [sortedArray]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let selfFilesArray: Array<string> = [];
    selfFiles?.map((file: string) => {
      selfFilesArray.push(allFiles[file]?.id);

      return true;
    });
    selfFilesArray.sort(sortByDate);
    setSortedArray(selfFilesArray);
  }, [selfFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  const paginationObjectFooToo = images
    ? paginate(images.length)
    : {
        totalItems: 0,
        currentPage: 0,
        pageSize: 0,
        totalPages: 0,
        startPage: 0,
        endPage: 0,
        startIndex: 0,
        endIndex: 0,
        pages: [0],
      };
  const [paginationObject, setPaginationObject] = useState<PaginationType>(
    paginationObjectFooToo
  );

  const updatePagination = (currentPage: number) => {
    setPage(currentPage);
    images && paginate(images.length);
    setPaginationObject(paginationObject);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    let refreshArray: Array<RefreshType> = [];
    images?.forEach((image) => {
      const refreshObject = {
        id: image,
        last_refresh_time: allFiles[image]?.last_refresh_time
          ? allFiles[image]?.last_refresh_time
          : 1,
      };
      !refreshArray.includes(refreshObject) && refreshObject.id != null && refreshArray.push(refreshObject);
    });
    token
      ? dispatch(refreshFile(refreshArray, token))
      : dispatch(refreshFile(refreshArray));
  }, [images]); // eslint-disable-line react-hooks/exhaustive-deps

  const getClosestDayOfLastWeek = (fromDate = new Date()) => {
    const offsetDays = -7 - (getISODay(fromDate) - 5);
    const lastFriday = addDays(fromDate, offsetDays);
    return lastFriday;
  };

  let lastFriday = getClosestDayOfLastWeek();

  return (
    <div className="grid grid-rows-4 flex bg-white dark:bg-gray-900 pt-2 relative justify-start items-start px-2 ">
      <div className="grid grid-cols-3 flex">
        {images?.map((image: string, idx: number) => {
          const adjustedIndex = idx + (page - 1) * 9;
          // const nextFriday = format(addDays(lastFriday, 7 * (idx + 1)), "yyyy-MM-dd");
          return (
            <div key={idx}>
              {adjustedIndex >= 0 && adjustedIndex < 3 && (
                <>
                <Thumbnail
                  id={allFiles[image]?.id}
                  variant={variant}
                  sq_lq_url={allFiles[image]?.sq_lq_url}
                  lg_lq_url={allFiles[image]?.lg_lq_url}
                  sq_bl_url={allFiles[image]?.sq_bl_url}
                  lg_bl_url={allFiles[image]?.lg_bl_url}
                  rep={allFiles[image]?.reputation}
                  isBlurred={!!allFiles[image]?.is_blurred}
                  isLoggedIn={isLoggedIn}
                  price={allFiles[image]?.bid}
                  
                />
                </>
              )}
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-3 flex">
        {images?.map((image: string, idx: number) => {
          const adjustedIndex = idx + (page - 1) * 9;
          const nextFriday = format(addDays(lastFriday, 7 * (idx + 4)), "yyyy-MM-dd");
          return (
            <div key={idx}>
              {adjustedIndex >= 3 && adjustedIndex < 6 && (
                <Thumbnail
                  id={allFiles[image]?.id}
                  variant={variant}
                  sq_lq_url={allFiles[image]?.sq_lq_url}
                  lg_lq_url={allFiles[image]?.lg_lq_url}
                  sq_bl_url={allFiles[image]?.sq_bl_url}
                  lg_bl_url={allFiles[image]?.lg_bl_url}
                  rep={allFiles[image]?.reputation}
                  isBlurred={!!allFiles[image]?.is_blurred}
                  isLoggedIn={isLoggedIn}
                  price={allFiles[image]?.bid}
                  postdate={nextFriday}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-3 flex">
        {images?.map((image: string, idx: number) => {
          const adjustedIndex = idx + (page - 1) * 9;
          const nextFriday = format(addDays(lastFriday, 7 * (idx + 7)), "yyyy-MM-dd");
          return (
            <div key={idx}>
              {adjustedIndex >= 6 && adjustedIndex < 9 && (
                <Thumbnail
                  id={allFiles[image]?.id}
                  variant={variant}
                  sq_lq_url={allFiles[image]?.sq_lq_url}
                  lg_lq_url={allFiles[image]?.lg_lq_url}
                  sq_bl_url={allFiles[image]?.sq_bl_url}
                  lg_bl_url={allFiles[image]?.lg_bl_url}
                  rep={allFiles[image]?.reputation}
                  isBlurred={!!allFiles[image]?.is_blurred}
                  isLoggedIn={isLoggedIn}
                  price={allFiles[image]?.bid}
                  postdate={nextFriday}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="flex w-full grid p-0 m-0">
        {images && images?.length > 9 && (
          <Pagination
            page={page}
            totalPages={paginationObject.totalPages}
            startPage={paginationObject.startPage}
            endPage={paginationObject.endPage}
            updatePagination={updatePagination}
          />
        )}
      </div>
    </div>
  );
};

export default AssetGrid;
