import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Image } from "../../components/common/Image";
import { XpMeter } from "../../components/XpMeter";
import { getReputation } from "../../services/xpService";
import { Button } from "../../components/common/Button";
import {
  FacebookSvg,
  InstagramSvg,
  TiktokSvg,
  SoundcloudSvg,
  ArrowLeftIcon,
} from "../../components/common/Icons";
import { AssetType, PersonType } from "../../types";
import store from "../../store";
import { numFormatter, capitalizeFirstLetter } from "../../services/utils";
import { setActiveLanding } from "../../store/layout/actions";

export type RootState = ReturnType<typeof store>;

type PersonPanelPropsType = {
  personId: string;
  coverUrl: string;
};

const PersonPanel = (props: PersonPanelPropsType) => {
  const { personId } = props;
  // const isSlideoutVisible: boolean = useSelector((state: RootState) => state.layout.isSlideoutVisible);
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );
  const allAssets: { [id: string]: AssetType } = useSelector(
    (state: any) => state.orm.Asset.itemsById
  );
  const asset_id: string = allPeople && allPeople[personId]?.asset_id;
  const xp_asset_id: string = allPeople && allPeople[personId]?.xp_asset_id;
  const asset: AssetType = allAssets[asset_id];
  const xp_asset: AssetType = allAssets[xp_asset_id];
  const photo_url: string = allPeople && allPeople[personId]?.photo_url;
  const name: string = allPeople && allPeople[personId]?.name;
  const facebook_link: string | undefined =
    allPeople && allPeople[personId]?.facebook_link;
  const instagram_link: string | undefined =
    allPeople && allPeople[personId]?.instagram_link;
  const tiktok_link: string | undefined =
    allPeople && allPeople[personId]?.tiktok_link;
  const soundcloud_link: string | undefined =
    allPeople && allPeople[personId]?.soundcloud_link;
  const asset_name: string | undefined =
    allPeople && allPeople[personId]?.asset_name;
  const assetBalance: number = asset?.qty;
  const xpBalance: number = xp_asset?.qty;
  const [activeSocialLink, setActiveSocialLink] = useState("NONE");
  const activeLanding: string = useSelector(
    (state: RootState) => state.layout.activeLanding
  );
  const reputation = getReputation(xpBalance);
  const reputationBgColor = "bg-" + reputation.color + "-700";
  console.log(reputationBgColor);

  useEffect(() => {
    activeSocialLink !== "NONE" &&
      window.location.assign("https://" + activeSocialLink);
  }, [activeSocialLink]);

  const dispatch = useDispatch();
  const handleBackClick = () => {
    dispatch(setActiveLanding("FEED"));
  };

  return (
    <div
      className={reputationBgColor +
        " md:mt-2 grid grid-rows-2 sm:rounded-xl shadow-lg sm:mx-6 px-6 md:mx-14"
      }
    >
      <div className="h-8 block flex row-start-2 -mt-2">
        {activeLanding === "TARGET_PROFILE" ? (
          <Button
            variant="flat-2"
            Icon={ArrowLeftIcon}
            onClick={() => {
              handleBackClick();
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div
        className={
          "row-start-3 grid grid-cols-12 "
        }
      >
        <div className="flex pl-2 pr-4 pb-6 h-20 w-20 sm:h-32 sm:w-32 md:w-42 md:h-42 justify-center col-span-3 ">
          {photo_url && (
            <Image
              url={photo_url}
              blUrl={photo_url}
              className="ring ring-white h-auto w-auto rounded-full"
              onLoad={() => {
                return true;
              }}
            />
          )}
        </div>

        <div className="w-full col-span-9 pb-3 pr-1 pl-1 ">
          <div className="grid grid-rows flex justify-start text-white text-xl font-bold">
            {name}
          </div>

          {/* Social Links */}
          <div className="w-full">
            <div className="grid grid-cols-4 mb-2 mt-1 text-white w-32">
              {facebook_link ? (
                <div className="">
                  <Button
                    variant="flat-2"
                    Icon={FacebookSvg}
                    onClick={() => setActiveSocialLink(facebook_link || "NONE")}
                  />
                </div>
              ) : (
                <div></div>
              )}
              {instagram_link ? (
                <div className="">
                  <Button
                    variant="flat-2"
                    Icon={InstagramSvg}
                    onClick={() =>
                      setActiveSocialLink(instagram_link || "NONE")
                    }
                  />
                </div>
              ) : (
                <div></div>
              )}
              {tiktok_link ? (
                <div className="">
                  <Button
                    variant="flat-2"
                    Icon={TiktokSvg}
                    onClick={() => setActiveSocialLink(tiktok_link || "NONE")}
                  />
                </div>
              ) : (
                <div></div>
              )}
              {soundcloud_link ? (
                <div className="">
                  <Button
                    variant="flat-2"
                    Icon={SoundcloudSvg}
                    onClick={() =>
                      setActiveSocialLink(soundcloud_link || "NONE")
                    }
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>

            <div className="grid grid-cols-3 flex mt-2 w-full">
              <div
                className="grid grid-rows text-white text-left sm:text-xs w-full"
                style={{ fontSize: "0.6em" }}
              >
                <div>{reputation.reputation}</div>
                <div className="">
                  {xpBalance ? numFormatter(xpBalance) : 0}{" "}
                  {xpBalance < 4651 &&
                    "/ " + numFormatter(reputation.levelCeiling)}
                </div>
              </div>
              <div className="flex"></div>
              <div
                className="grid grid-rows text-white sm:text-xs text-right justify-end"
                style={{ fontSize: "0.6em" }}
              >
                <div>{asset_name && capitalizeFirstLetter(asset_name)}</div>
                <div>{assetBalance ? numFormatter(assetBalance) : 0}</div>
              </div>
            </div>
            <XpMeter amount={xpBalance} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonPanel;
