import React from "react";

type PostBottomPanelType = {
  targetPersonTextColor: string;
  amount: number;
  target_post_reputation: string;
};

const PostBottomPanel = (props: PostBottomPanelType) => {
  const { targetPersonTextColor, amount, target_post_reputation } = props;

  const getRepPrice = (rep: string) => {
    if (rep === "NEUTRAL") {
      return (amount / Math.exp(0)).toFixed(2);
    }
    if (rep === "FRIENDLY") {
      return (amount / Math.exp(1)).toFixed(2);
    }
    if (rep === "HONORED") {
      return (amount / Math.exp(2)).toFixed(2);
    }
    if (rep === "REVERED") {
      return (amount / Math.exp(3)).toFixed(2);
    }
    if (rep === "EXALTED") {
      return (amount / Math.exp(5)).toFixed(2);
    }
  };

  return (
    <div className="pt-3 pr-4 grid grid-cols-5 w-full flex space-x-4">
      {(target_post_reputation === "FRIENDLY" ||
        target_post_reputation === "HONORED" ||
        target_post_reputation === "REVERED" ||
        target_post_reputation === "EXALTED") && (
        <div className="grid grid-rows-2 flex space-around ">
          <p
            className="flex self-start justify-self-start text-gray-500 text-xs "
            style={{ fontSize: "0.6rem" }}
          >
            NEUTRAL
          </p>
          <p
            className={
              "sm:-mt-2 text-xs sm:text-sm md:text-lg flex self-start justify-self-start font-bold items-start " +
              targetPersonTextColor
            }
          >
            {getRepPrice("NEUTRAL")}
          </p>
        </div>
      )}
      {(target_post_reputation === "HONORED" ||
        target_post_reputation === "REVERED" ||
        target_post_reputation === "EXALTED") && (
        <div className="grid grid-rows-2 flex space-around">
          <p
            className="text-xs flex self-start justify-self-start text-green-500 text-xs "
            style={{ fontSize: "0.6rem" }}
          >
            FRIENDLY
          </p>
          <p
            className={
              "sm:-mt-2 text-xs sm:text-sm md:text-lg flex self-start justify-self-start font-bold items-start " +
              targetPersonTextColor
            }
          >
            {getRepPrice("FRIENDLY")}
          </p>
        </div>
      )}
      {(target_post_reputation === "REVERED" ||
        target_post_reputation === "EXALTED") && (
        <div className="grid grid-rows-2 flex space-around">
          <p
            className="text-xs flex self-start justify-self-start text-blue-500 text-xs "
            style={{ fontSize: "0.6rem" }}
          >
            HONORED
          </p>
          <p
            className={
              "sm:-mt-2 text-xs sm:text-sm md:text-lg flex self-start justify-self-start font-bold items-start " +
              targetPersonTextColor
            }
          >
            {getRepPrice("HONORED")}
          </p>
        </div>
      )}
      {target_post_reputation === "EXALTED" && (
        <div className="grid grid-rows-2 flex space-around">
          <p
            className="text-xs flex self-start justify-self-start text-purple-500 text-xs "
            style={{ fontSize: "0.6rem" }}
          >
            REVERED
          </p>
          <p
            className={
              "sm:-mt-2 text-xs sm:text-sm md:text-lg flex self-start justify-self-start font-bold items-start " +
              targetPersonTextColor
            }
          >
            {getRepPrice("REVERED")}
          </p>
        </div>
      )}
      {target_post_reputation === "NEUTRAL" && (
                <div className="grid grid-rows-2 flex space-around">
                <p
                  className="text-xs flex self-start justify-self-start text-gray-500 text-xs "
                  style={{ fontSize: "0.6rem" }}
                >
                  PUBLIC
                </p>
                <p
                  className={
                    "sm:-mt-2 text-xs sm:text-sm md:text-lg flex self-start justify-self-start font-bold items-start " +
                    targetPersonTextColor
                  }
                >
                  FREE
                </p>
              </div>
      )}
    </div>
  );
};

export default PostBottomPanel;
