

type SettingsInputPropsType = {
    label: string;
    fieldName: string;
    error: boolean;
    value?: string;
    onChange: Function;
    Icon: any;
  };
  
  const SettingsInput = (props: SettingsInputPropsType) => {
    const { label, fieldName, error, value, onChange, Icon } = props;
  
    const errorClasses =
      "flex-1 w-full border-red-300 border-2 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-500 sm:text-sm rounded-none rounded-r-md";
    const normalClasses =
      "flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 dark:text-white dark:border-gray-700 dark:bg-gray-800";
    
    return (
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="w-40 inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-gray-500 text-sm">
          <div className="pr-2">{Icon}</div>
          {label}
        </span>
        <input
          type="text"
          name={fieldName}
          id={fieldName}
          data-testid={fieldName}
          className={error ? errorClasses : normalClasses}
          onChange={(evt: any) => onChange(evt)}
          value={value || ""}
        />
      </div>
    );
  };

  export default SettingsInput;