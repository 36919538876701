export const webClient = process.env.REACT_APP_FIREBASE_WEB_CLIENT;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
export const STRIPE_DB100_KEY = process.env.REACT_APP_STRIPE_DB100_KEY;
export const STRIPE_DB500_KEY = process.env.REACT_APP_STRIPE_DB500_KEY;
export const STRIPE_DB1625_KEY = process.env.REACT_APP_STRIPE_DB1625_KEY;
export const STRIPE_DB4650_KEY = process.env.REACT_APP_STRIPE_DB4650_KEY;