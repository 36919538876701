import React from "react";
// import ReactDOM from 'react-dom';
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";
import { ThemeProvider, Background } from "./components/Background";

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <ThemeProvider initialTheme="light">
        <Background>
          <div className="bg-white dark:bg-gray-900">
            <App />
          </div>
        </Background>
      </ThemeProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <ThemeProvider initialTheme="light">
        <Background>
          <div className="bg-white dark:bg-gray-900">
            <App />
          </div>{" "}
        </Background>
      </ThemeProvider>
    </React.StrictMode>,
    rootElement
  );
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

serviceWorkerRegistration.register();
reportWebVitals();
