import React, { } from "react";
import store from "../../store";
import RepPrices from "../Post/RepPrices";
export type RootState = ReturnType<typeof store>;

type PostBottomPanelType = {
  amount: number;
  bgColor: string;
  reputation: string;
  handleBidChange: Function;
  handlePostButton: Function;
};

const PostBottomPanel = (props: PostBottomPanelType) => {
  const { handleBidChange, bgColor, amount, reputation, handlePostButton } =
    props;

  return (
    <div className="">
      <div className="flex grid grid-rows">
        <div className="">
          <RepPrices
            targetPersonTextColor={bgColor}
            amount={amount}
            target_post_reputation={reputation}
          />
        </div>
       
          <div className="flex grid grid-cols-5 pt-4">
            <div className="flex pr-2 col-span-4 w-full">
              <input
                type="text"
                name="bid"
                id="bid"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-xl"
                placeholder="1"
                aria-describedby="bid-optional"
                onChange={(e: any) => {
                  handleBidChange(e);
                }}
                value={amount}
                disabled={reputation === "NEUTRAL"}
              />
            </div>
            <div className={"flex justify-end "}>
              <button
                onClick={() => handlePostButton()}
                className={
                  "inline-flex h-10 mx-auto px-4 py-2 border border-transparent shadow-lg font-medium rounded-xl text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-xs sm:text-sm " +
                  `bg-${bgColor}-700`
                }
              >
                Save
              </button>
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default PostBottomPanel;
