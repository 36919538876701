import { put, call, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import "firebase/storage";
import { FILE_REQUEST_UPLOADED_FILES, FILE_UPDATE_PROGRESS } from './files/constants';
// import { useDispatch } from 'react-redux';
import firebase from "firebase/app";
// import { updateUrl, updateProgress } from './files/actions';


import ReduxSagaFirebase from 'redux-saga-firebase'

const rsf = new ReduxSagaFirebase(firebase)

export function* uploadFileSaga(action) {
  const file = action.payload
  const path = "/files/" + file.parent_id + "/" + file.field + "/" + file.child_id
  const task = rsf.storage.uploadFile(path, file.blob)
  const channel = eventChannel(emit => task.on('state_changed', emit));
  yield takeEvery(channel, doSomething);

  // Wait for upload to complete
  yield task

  yield call(syncFileUrl)

  function* syncFileUrl() {
    try {
      const result = yield call(rsf.storage.getDownloadURL, path)
      const url = file.parent_id + ',' + file.field + ',' + result
      console.log(url);
      yield put({
        type: FILE_REQUEST_UPLOADED_FILES,
        payload: url,
      })
      // yield put(updateUrl(url))
    } catch (error) {
      console.error(error)
    }
  }
}

function* doSomething(uploadTask) {
  const snapshot = uploadTask;
  const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  console.log(progress);
  yield put({
    type: FILE_UPDATE_PROGRESS,
    payload: progress
  })
  // yield put(updateProgress(progress));
}
