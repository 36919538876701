import { Reducer } from 'redux';
import {PERSIST_REHYDRATE} from '@redux-offline/redux-offline/lib/constants';
import {
  LAYOUT_SET_ACTIVE_SLIDEOUT,
  LAYOUT_SET_SLIDEOUT,
  LAYOUT_SET_ACTIVE_TOAST,
  LAYOUT_SET_TOAST,
  LAYOUT_SET_MODAL,
  LAYOUT_SET_ACTIVE_MODAL,
  LAYOUT_SET_LOADING,
  LayoutReducerTypes,
  LAYOUT_SET_LOADING_STATE,
  LAYOUT_SET_PARAMETER,
  LAYOUT_HAS_PARAMETER,
  LAYOUT_SET_ACTIVE_LANDING,
  LAYOUT_SET_ACTIVE_PHOTO,
  LAYOUT_SET_ACTIVE_CONFIRMATION,
  LAYOUT_SET_ACTIVE_BLUR_PHOTO,
} from './constants';

const defaultState = {
  isSlideoutVisible: false,
  activeSlideout: 'PROFILE',
  isModalVisible: false,
  activeModal: 'LOGIN',
  isToastVisible: false,
  activeToast: 'LOGIN',
  isLoading: true,
  loadingState: 'NO_TOKEN',
  rehydrated: false,
  hasParameter: false,
  parameter: '',
  activeLanding: 'FEED',
  activePhoto: '',
  activeBlurPhoto: '',
  activeConfirmation: 'LOGIN',
};

const authReducer: Reducer<LayoutReducerTypes> = (
  state = defaultState,
  action: { type: String; payload?: any }
) => {
  switch (action.type) {
    case PERSIST_REHYDRATE: {
      return {
        ...state,
        rehydrated: true,
        isLoading: false,
      };
    }
    case LAYOUT_SET_SLIDEOUT: {
      return {
        ...state,
        isSlideoutVisible: action.payload,
      };
    }
    case LAYOUT_SET_ACTIVE_SLIDEOUT: {
      return {
        ...state,
        activeSlideout: action.payload,
      };
    }
    case LAYOUT_SET_TOAST: {
      return {
        ...state,
        isToastVisible: action.payload,
      };
    }
    case LAYOUT_SET_ACTIVE_TOAST: {
      return {
        ...state,
        activeToast: action.payload,
      };
    }
    case LAYOUT_SET_MODAL: {
      return {
        ...state,
        isModalVisible: action.payload,
      }
    }
    case LAYOUT_SET_ACTIVE_MODAL: {
      return {
        ...state,
        activeModal: action.payload,
      };
    }
    case LAYOUT_SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case LAYOUT_SET_LOADING_STATE: {
      return {
        ...state,
        loadingState: action.payload,
      }
    }
    case LAYOUT_SET_PARAMETER: {
      return {
        ...state,
        parameter: action.payload
      }
    }
    case LAYOUT_HAS_PARAMETER: {
      return {
        ...state,
        hasParameter: action.payload
      }
    }
    case LAYOUT_SET_ACTIVE_LANDING: {
      return {
        ...state,
        activeLanding: action.payload
      }
    }
    case LAYOUT_SET_ACTIVE_PHOTO: {
      return {
        ...state,
        activePhoto: action.payload
      }
    }
    case LAYOUT_SET_ACTIVE_BLUR_PHOTO: {
      return {
        ...state,
        activeBlurPhoto: action.payload
      }
    }
    case LAYOUT_SET_ACTIVE_CONFIRMATION: {
      return {
        ...state,
        activeConfirmation: action.payload
      }
    }
    default:
      return state;
  }
};

export default authReducer;