import React, { useState } from "react";
import { Button } from "../../components/common/Button";
import { ArrowLeftIcon } from "../../components/common/Icons";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  addConfirmedTransaction,
  exchangeActivate,
  tip,
  loadConfirmedTransactions,
  loggedOutBuy,
} from "../../store/transactions/actions";
import store from "../../store";
import { capitalizeFirstLetter } from "../../services/utils";
import {
  STRIPE_DB100_KEY,
  STRIPE_DB500_KEY,
  STRIPE_DB1625_KEY,
  // STRIPE_API_KEY
} from "../../constants/config";
import {
  BuyType,
  PersonType,
  TipTransactionType,
  AssetType,
} from "../../types";
import {
  // loadPerson,
  setAssetBalance,
  setSelfDoubloonBalance,
  setXpAssetBalance,
} from "../../store/people/actions";
import {
  setSlideout,
  setModal,
  setActiveModal,
  setLoading,
  setToast,
  setActiveToast,
  setActiveLanding,
} from "../../store/layout/actions";
import { BASE_WEB_URL } from "../../constants/endpoints";


export type RootState = ReturnType<typeof store>;

const PurchaseButtons = () => {
  const [tipAmount, setTipAmount] = useState(100);
  const [isCustomTipOpen, setIsCustomTipOpen] = useState(false);
  const activePersonId: string = useSelector(
    (state: RootState) => state.people.activePerson
  );
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: RootStateOrAny) => state.orm.Person.itemsById
  );
  const allAssets: { [id: string]: AssetType } = useSelector(
    (state: RootStateOrAny) => state.orm.Asset.itemsById
  );
  const token = useSelector((state: RootState) => state.auth.token);

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  // const buyObject = useSelector((state: RootState) => state.transactions.buyTransaction);

  const activePerson: PersonType = allPeople[activePersonId];
  const activePersonAsset: AssetType = allAssets[activePerson?.asset_id];
  const activePersonXpAsset: AssetType = allAssets[activePerson?.xp_asset_id];

  const doubloonBalance = allAssets["DBLN"]?.qty;

  const assetBalance = activePersonAsset?.qty;
  const xpBalance = activePersonXpAsset?.qty;
  const target = activePerson?.username;

  const stripeDb100 = STRIPE_DB100_KEY ? STRIPE_DB100_KEY : "";
  const stripeDb500 = STRIPE_DB500_KEY ? STRIPE_DB500_KEY : "";
  const stripeDb1625 = STRIPE_DB1625_KEY ? STRIPE_DB1625_KEY : "";

  const dispatch = useDispatch();

  const handleTip = (amount: number, target_id: string) => {
    if (isLoggedIn) {
      const tipObject: TipTransactionType = {
        id: "p" + require("crypto").randomBytes(8).toString("hex"),
        target_id: target_id,
        asset_qty: amount,
        asset_name: "Doubloons",
        asset_id: "DBLN",
        target_name: activePerson.name,
        target_photo_url: activePerson.photo_url,
        target_username: activePerson.username,
        provisioned_at: Date.now() / 1000,
        status: "PROVISIONED",
      };
      let userAssetBalance = 0;
      assetBalance && (userAssetBalance = assetBalance);
      dispatch(setXpAssetBalance(xpBalance + amount));
      dispatch(setSelfDoubloonBalance(doubloonBalance - amount));
      token && dispatch(tip(tipObject, token));
      dispatch(addConfirmedTransaction(tipObject));
      dispatch(exchangeActivate((doubloonBalance - amount).toString()));
      dispatch(setAssetBalance(userAssetBalance + amount));
      token && dispatch(loadConfirmedTransactions(token));
      // dispatch(setLoading(false));
      dispatch(setActiveToast("TIP"));
      dispatch(setToast());
    } else {
      dispatch(setSlideout(false));
      dispatch(setActiveModal("LOGIN"));
      dispatch(setModal(true));
    }
  };

  const handlePayment = async (paymentId: string, amount: number) => {
    const idem = "p" + require("crypto").randomBytes(8).toString("hex");
    const idem2 = "p" + require("crypto").randomBytes(8).toString("hex");

    let successUrl = BASE_WEB_URL;
    let cancelUrl = BASE_WEB_URL;

    successUrl = successUrl + "/stripe_success/" + target;
    cancelUrl = cancelUrl + "/" + target;

    const buyObject: BuyType = {
      id: idem,
      provisioned_at: Date.now() / 1000,
      status: "PROVISIONED",
      stripePaymentKey: paymentId,
      dblnQty: amount,
      successUrl: successUrl,
      cancelUrl: cancelUrl,
      tipTransaction: {
        id: idem2,
        target_id: activePersonId,
        asset_qty: amount,
        asset_name: activePerson.asset_name,
        asset_id: activePerson.asset_id,
        target_name: activePerson.name,
        target_photo_url: activePerson.photo_url,
        target_username: activePerson.username,
        provisioned_at: Date.now() / 1000,
        status: "PROVISIONED",
      },
    };
    dispatch(setSlideout(false));
    dispatch(loggedOutBuy(buyObject));
    dispatch(setLoading(true));
    dispatch(setActiveLanding("CONFIRMATION"));
  };

  type PButtonPropsType = {
    doubloonBalance: number;
    amount: number;
    asset: string;
    price: string;
    stripe: string;
  };

  const PButton = (props: PButtonPropsType) => {
    const { doubloonBalance, amount, price, stripe } = props;
    return (
      <div className="shadow-xl rounded-xl flex dark:bg-gray-900">
        {doubloonBalance >= amount ? (
          <button
            onClick={() => handleTip && handleTip(amount, activePersonId)}
            className="w-24 h-24 sm:w-40 sm:h-40 justify-center bg-primary-700 place-content-center flex grid grid-rows-3 hover:bg-primary-900 rounded-xl p-4 items-center text-white"
          >
            <div className="text-sm">Trade</div>
            <div className="text-xl sm:text-3xl md:text-5xl font-bold">{amount}</div>
            <div className="text-sm">Doubloons</div>
          </button>
        ) : (
          <button
            onClick={() => handlePayment && handlePayment(stripe, amount)}
            className="w-24 h-24 sm:w-40 sm:h-40 flex place-content-center bg-primary-700 hover:bg-primary-900 rounded-xl p-4 items-center text-white grid grid-rows-2"
          >
            <div className="text-xl sm:text-3xl md:text-5xl font-bold">{price}</div>
            <div className="text-lg ">{amount}</div>
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white pb-6 dark:bg-gray-900">
      <>
        <div className="sm:px-10 pt-5 px-8">
          <div className="flex ">
            {isCustomTipOpen && (
              <div>
                <button
                  onClick={() => setIsCustomTipOpen(false)}
                  className="text-gray-700"
                >
                  <ArrowLeftIcon />
                </button>
                <div className="w-full gap-4 grid grid-cols-3 justify-center flex">
                  <div className="min-w-0 m-1 py-2 col-span-2">
                    <input
                      type="number"
                      name="tipAmount"
                      id="tipAmount"
                      className="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      onChange={(evt: any) =>
                        setTipAmount(parseInt(evt.target.value))
                      }
                      value={tipAmount}
                    />
                  </div>
                  <div className="min-w-0 m-1 py-2">
                    <Button
                      onClick={() => handleTip(tipAmount, activePerson.id)}
                      variant={"primary"}
                      fullWidth
                      label={"Tip"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {!isCustomTipOpen && activePerson?.asset_name && (
            <>
              <div className="flex mx-auto justify-start items-center pb-4 font-bold text-xl px-2 md:px-4 text-gray-700 dark:text-gray-300 ">
                <div>Buy {capitalizeFirstLetter(activePerson?.asset_name)}</div>
                {/* {isLoggedIn && (
                  <button
                    onClick={() => setIsCustomTipOpen(true)}
                    className="text-sm font-bold text-primary-400 flex justify-end hover:text-primary-900"
                  >
                    Custom Amount
                  </button>
                )} */}
              </div>
              <div className="flex gap-6 space-around mx-auto justify-center">
                <PButton
                  doubloonBalance={doubloonBalance}
                  asset={activePerson.asset_name}
                  price={"$1.60"}
                  amount={100}
                  stripe={stripeDb100}
                />

                <PButton
                  doubloonBalance={doubloonBalance}
                  asset={activePerson.asset_name}
                  price={"$8"}
                  amount={500}
                  stripe={stripeDb500}
                />

                <PButton
                  doubloonBalance={doubloonBalance}
                  asset={activePerson.asset_name}
                  price={"$25"}
                  amount={1625}
                  stripe={stripeDb1625}
                />
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default PurchaseButtons;
