import React, { useEffect, useState } from 'react';
import Toast from '../Toast/Toast';
import { useSelector } from 'react-redux'
import store from "../../../store";
export type RootState = ReturnType<typeof store>;

const ToastsList = () => {
    const activeToast = useSelector((state: RootState) => state.layout.activeToast);
    const [ message, setMessage ] = useState('login');
    const [ color, setColor ] = useState('color');

    useEffect(() => {
        if (activeToast === 'LOGIN') {
            setMessage('Login Successful');
            setColor('green');
        }
        if (activeToast === 'BUY') {
            setMessage('Purchase Successful');
            setColor('green');
        }
        if (activeToast === 'SELL') {
            setMessage('Deposit Successful');
            setColor('green');
        }
        if (activeToast === 'LINK') {
            setMessage('Link Copied');
            setColor('green');
        }
        if (activeToast === 'LOGOUT') {
            setMessage('Logout Successful');
            setColor('green');
        }
        if (activeToast === 'TIP') {
            setMessage('Tip Successful');
            setColor('green');
        }
        if (activeToast === 'SETTINGS') {
            setMessage('Settings Updated');
            setColor('green');
        }
        if (activeToast === 'INSUFFICIENT_FUNDS') {
            setMessage('Insufficient Funds');
            setColor('red');
        }
        if (activeToast === 'INVALID_WALLET_ADDRESS') {
            setMessage('Invalid Wallet Address');
            setColor('red');
        }        
        if (activeToast === 'USER_EXISTS') {
            setMessage('Username already exists');
            setColor('red');
        }        
    },[activeToast])

    return (
        <>
        <div data-testid="toast-list">
            <Toast message={message} alertColor={color} />  
        </div>
        </>
    )
}

export default ToastsList;