import React, { useState, useEffect } from "react";
import waves from "./rock.webp";
import store from "../../store";
import { useDispatch } from "react-redux";
import {
  setActiveModal,
  setModal,
} from "../../store/layout/actions";
// import { FirebaseLogin } from "../../components/FirebaseLogin";

export type RootState = ReturnType<typeof store>;

const LoginPanel = () => {
  const dispatch = useDispatch();

  const loginMessages = ["GET PAID", "EARN XP", "MAKE ART"];

  const [activeLoginMessage, setActiveLoginMessage] = useState(0);

  const handleClick = () => {
    dispatch(setActiveModal("LOGIN"))
      dispatch(setModal(true));
  }

  useEffect(() => {
    let x = 0;
    const intervalId = setInterval(() => {
      if (x === loginMessages.length) {
        x = 0;
      }
      setActiveLoginMessage(x);
      x++;    }, 5000);

    return () => clearInterval(intervalId);
  }, [loginMessages.length]);

  return (
    <div
      className="bg-cover mx-2 shadow-xl rounded-xl -mt-8"
      style={{ backgroundImage: `url(${waves})` }}
    >
      <button
        className="flex w-full mt-3 justify-center items-center "
        onClick={() => handleClick()}
      >
      <div
        className="
        shadow-xl rounded-xl bg-opacity-50
        flex justify-center grid items-center bg-gray-900 sm:px-2 
        w-full h-full
        "
      >
        <div className="flex text-5xl text-white font-bold py-8 text-center animate-pulse">
          {loginMessages[activeLoginMessage]}
        </div>
        {/* <div className="flex justify-center">
          <FirebaseLogin />
        </div> */}
      </div>
      </button>
    
    </div>
  );
};

export default LoginPanel;
