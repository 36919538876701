import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import store from "../../store";
import { FileType } from "../../types";
import { SelfGrid } from "../../components/AssetGrid";
import { Spinner } from "../../components/Spinner";
import { Image } from "../../components/common/Image";
import { FileUpload } from "../../components/FileUpload";

export type RootState = ReturnType<typeof store>;

const Profile = () => {
  const images: Array<string> | undefined = useSelector((state: RootState) => state.people.self.publishedImages);
  const upcomingImages: Array<string> | undefined = useSelector((state: RootState) => state.people.self.upcomingImages);
  const publishedImages: Array<string> | undefined = useSelector((state: RootState) => state.people.self.publishedImages);

  const allFiles: { [id: string]: FileType } = useSelector(
    (state: RootStateOrAny) => state.orm.File.itemsById
  );
  const isLoading: boolean = useSelector(
    (state: RootState) => state.layout.isLoading
  );
  const imageNumber = images ? images.length : 0;


  const sortByDate = (a: any, b: any) => {
    const fileA = allFiles[a]?.created_at;
    const fileB = allFiles[b]?.created_at;
    if (fileA && fileB) {
      if (fileA < fileB) {
        return 1;
      }
      if (fileA > fileB) {
        return -1;
      }
    }
    return 0;
  };

  const sortedImages = images?.sort(sortByDate)
  const [activeLoadingMessage, setActiveLoadingMessage] = useState("");
  const [activeLoadingPct, setActiveLoadingPct] = useState(0);
  const [mainImage, setMainImage] = useState(sortedImages && allFiles[sortedImages[0]])

  useEffect(() => {
    const image = publishedImages && allFiles[publishedImages[publishedImages.length - 1]];
    publishedImages && setMainImage(image)
  },[publishedImages, allFiles])

  const getClassWidth = (pctFull: number) => {
    let classWidth = "w-0";
    if (pctFull > 0.08333333 && pctFull <= 0.16666667) {
      classWidth = "w-1/12";
    }

    if (pctFull > 0.16666667 && pctFull <= 0.25) {
      classWidth = "w-2/12";
    }

    if (pctFull > 0.25 && pctFull <= 0.333333) {
      classWidth = "w-3/12";
    }

    if (pctFull > 0.333333 && pctFull <= 0.41666667) {
      classWidth = "w-4/12";
    }

    if (pctFull > 0.41666667 && pctFull <= 0.5) {
      classWidth = "w-5/12";
    }

    if (pctFull > 0.5 && pctFull <= 0.583333) {
      classWidth = "w-6/12";
    }

    if (pctFull > 0.583333 && pctFull <= 0.66666667) {
      classWidth = "w-7/12";
    }

    if (pctFull > 0.66666667 && pctFull <= 0.75) {
      classWidth = "w-8/12";
    }

    if (pctFull > 0.75 && pctFull <= 0.833333) {
      classWidth = "w-9/12";
    }

    if (pctFull > 0.833333 && pctFull <= 0.91666667) {
      classWidth = "w-10/12";
    }

    if (pctFull > 0.91666667 && pctFull < 1) {
      classWidth = "w-11/12";
    }

    if (pctFull >= 1) {
      classWidth = "w-full";
    }

    return classWidth;
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div className="overflow-x-hidden dark:bg-gray-900">
        <div className="flex grid mx-auto justify-center max-w-3xl h-full">
          {imageNumber > 0 && (
            <>
              <div className="px-4 font-bold text-xl dark:text-gray-300 text-gray-700">
                Live 
              </div>
              <div className="flex justify-center rounded-xl p-1 max-w-full max-h-full bg-red-400 m-2 border-2 dark:border-gray-800">
                <Image
                  url={mainImage?.ls_lq_url ? mainImage.ls_lq_url : ""}
                  className={"rounded-xl w-screen "}
                  blUrl={mainImage?.ls_bl_url ? mainImage.ls_bl_url : ""}
                />
              </div>
            </>
          )}
          <div className="flex mx-auto text-gray-700 grid grid-rows-auto w-full px-4 sm:w-6/12">
            <div className="flex mx-auto animate-pulse">{activeLoadingMessage && activeLoadingMessage}</div>
            <div hidden={(activeLoadingPct <= 0 || activeLoadingPct >= 100) && activeLoadingMessage !== ""} className="my-1 h-2 min-w-max relative rounded-full overflow-hidden">
                <>
                  <div className="min-w-max h-full bg-gray-200 absolute"></div>
                  <div
                    className={
                      "h-full rounded-full absolute bg-primary-400 " +   
                      getClassWidth(activeLoadingPct / 100)
                    }
                  >{ }</div>
                </>
              </div>
            <div hidden={!!activeLoadingMessage}>
              <FileUpload 
              setActiveLoadingMessage={setActiveLoadingMessage} 
              setActiveLoadingPct={setActiveLoadingPct}
              />
            </div>
          </div>
          {upcomingImages && upcomingImages?.length > 0 && (
            <>
            <div className="px-4 font-bold text-xl dark:text-gray-300 text-gray-700">
              Upcoming
            </div>
            <SelfGrid variant="SELF" isSelf={true} />
          </>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
