import React, { useState } from "react";
import store from "../../store";
import { FileType, PersonType, AssetType } from "../../types";
import { RepPrices, PostPhoto, PostProfile } from ".";
import { getReputationColor } from "../../services/xpService";
import { useSelector, useDispatch } from "react-redux";
import { addImagePass } from "../../store/files/actions";
// import { optimisticPostFile } from "../../store/orm/actions";
// import { ClockIcon } from "../common/Icons";
// import moment from "moment";
// import Unlock from "./Unlock";
import { Confirm } from ".";
import { getReputation } from "../../services/xpService";


export type RootState = ReturnType<typeof store>;

const Post = () => {
  const dispatch = useDispatch();

  const allFiles: { [id: string]: FileType } = useSelector(
    (state: any) => state.orm.File.itemsById
  );
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );
  const allAssets: { [id: string]: AssetType } = useSelector(
    (state: any) => state.orm.Asset.itemsById
  );
  const id = useSelector((state: RootState) => state.files.selectedFile);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const token = useSelector((state: RootState) => state.auth.token);
  // const unlockedTarget = useSelector(
  //   (state: RootState) => state.files.unLockTarget
  // );
  const file = allFiles[id];
  const target_id = file?.person_id;
  const person: PersonType = allPeople && allPeople[file?.person_id];
  const person_photo_url = person?.photo_url;
  const amount = file?.bid;
  const lgLqUrl = file?.lg_lq_url;
  const mdLqUrl = file?.md_lq_url;
  const mdBlUrl = file?.md_bl_url;
  const lsLqUrl = file?.ls_lq_url;
  const lsBlUrl = file?.ls_bl_url;
  const isBlurred = !!file?.is_blurred;
  const target_post_reputation = file?.reputation;
  const personRep = getReputation(person?.xp_asset_balance);
  const targetPersonBgColor = "bg-" + personRep.color + "-700";
  const targetPersonRingColor = "ring-" + personRep.color + "-400";
  const targetPersonTextColor = "text-" + personRep.color + "-700";
  const asset = allAssets[person?.asset_id];
  const xp_asset = allAssets[person?.xp_asset_id];
  const doubloon_balance = allAssets['DBLN']?.qty ? allAssets['DBLN']?.qty : 0;
  const targetPersonXp = xp_asset?.qty ? xp_asset?.qty : 0;
  const targetPersonAssetBalance = asset?.qty ? asset?.qty : 0;
  const targetPersonAssetName = person?.asset_name;
  const imagepass = file?.imagepass;
  // const imagepassduration = file?.imagepassduration;
  const status = file?.is_blurred ? "LOCKED" : "UNLOCKED"
  const unlockStatus = imagepass ? "PASS" : "LEVEL";
  const name = person?.name;
  const lastRefreshTime = file?.last_refresh_time;

  // const [unlockedTime, setUnlockedTime] = useState("a day");
  

  const unlock = (id: string) => {
    token && dispatch(addImagePass(id, token));
  };

  const [unlockConfirm, setUnlockConfirm] = useState(false);

  // useEffect(() => {
  //   dispatch(optimisticPostFile(unlockedTarget));
  //   const startUnlockTime = moment(imagepass).unix();
  //   const duration = imagepassduration ? imagepassduration : 0;
  //   const endUnlockTime = startUnlockTime + duration;
  //   const currentTime = moment().unix();
  //   const remainingTime = endUnlockTime - currentTime;
  //   if (remainingTime > 0) {
  //     // const displayTime = moment({}).seconds(remainingTime).fromNow(true);
  //     const displayTime = moment({}).seconds(remainingTime).fromNow(true);
  //     setUnlockedTime(displayTime);
  //   }
  // }, [unlockedTarget, dispatch, imagepass, imagepassduration, setUnlockedTime]);

  return (
    <div className="grid grid-rows-12 ">
      <div className="row-start-1 max-h-20 ">
        <PostProfile
          target_id={target_id}
          targetPersonBgColor={targetPersonBgColor}
          targetPersonTextColor={targetPersonTextColor}
          targetPersonRingColor={targetPersonRingColor}
          name={name}
          person_photo_url={person_photo_url}
          target_post_reputation={target_post_reputation}
          targetPersonAssetBalance={targetPersonAssetBalance}
          targetPersonAssetName={targetPersonAssetName}
          lastRefreshTime={lastRefreshTime}
          targetPersonXp={targetPersonXp}
          isBlurred={isBlurred}
          file={file}
        />

      </div>


      {/* Main Photo */}
      <div className="row-start-2 ">
        {status === "LOCKED" && (
          <>
            {unlockConfirm ? (
              <>
              {mdBlUrl && (
                <Confirm
                handleUnlock={unlock}
                setIsConfirmationVisible={setUnlockConfirm}
                color={target_post_reputation ? getReputationColor(target_post_reputation) : 'gray'}
                url={mdBlUrl}
                amount={amount ? amount : 999999}
                currency={targetPersonAssetName}
                id={id}
              />
              )}
              </>
            ) : (
              <>
              {lsBlUrl && (
                <PostPhoto
                isBlurred={isBlurred}
                xpBalance={targetPersonXp}
                repColor={target_post_reputation ? getReputationColor(target_post_reputation) : 'gray'}
                thumbnailUrl={lsBlUrl}
                blUrl={lsBlUrl}
                amount={amount ? amount : 999999}
                isLoggedIn={isLoggedIn}
                target_person_reputation={personRep.reputation}
                target_post_reputation={target_post_reputation ? getReputationColor(target_post_reputation) : 'gray'}
                target_person_asset_balance={targetPersonAssetBalance}
                doubloon_balance={doubloon_balance}
                target_currency={targetPersonAssetName}
              />
              )}
              
              </>
            )}
          </>
        )}
        {status === "UNLOCKED" && (
          <>
          {mdLqUrl && mdBlUrl && lsLqUrl && (
            <PostPhoto
            fullScreenUrl={lgLqUrl}
            thumbnailUrl={lsLqUrl}
            blUrl={mdBlUrl}
            amount={amount ? amount : 999999}
            isLoggedIn={isLoggedIn}
            target_post_reputation={target_post_reputation ? getReputationColor(target_post_reputation) : 'gray'}
            target_person_asset_balance={targetPersonAssetBalance}
            doubloon_balance={doubloon_balance}
            target_person_reputation={personRep.reputation}
            target_currency={targetPersonAssetName}
          />
          )}
          
          </>
        )}
      </div>

      {/* Bottom Panel */}
            <div className="row-start-3 items-end flex">
      {status === "LOCKED" && (
        <>
          {isLoggedIn ? (
              <div className="flex w-full">
                <RepPrices
                  targetPersonTextColor={targetPersonTextColor}
                  amount={amount ? amount : 999999}
                  target_post_reputation={target_post_reputation ? getReputationColor(target_post_reputation) : 'gray'}
                  />
              </div>
          ) : (
            <div>
              <RepPrices
                targetPersonTextColor={targetPersonTextColor}
                amount={amount ? amount : 999999}
                target_post_reputation={target_post_reputation ? getReputationColor(target_post_reputation) : 'gray'}
              />
            </div>
          )}
        </>
      )}

      {status === "UNLOCKED" && (
        <>
          {/* {unlockStatus === "PERMANANT" && (
            <div className="text-xl font-bold py-2">Unlocked Permanantly</div>
          )} */}

          {unlockStatus === "LEVEL" && (
            <div className="flex w-full">
              <RepPrices
                targetPersonTextColor={targetPersonTextColor}
                amount={amount ? amount : 999999}
                target_post_reputation={target_post_reputation ? target_post_reputation : 'EXALTED'}
              />
            </div>
          )}

          {unlockStatus === "PASS" && (
            <div className="flex w-full">
              {/* <RepPrices
            targetPersonTextColor={targetPersonTextColor}
            amount={amount}
            target_post_reputation={target_post_reputation}
          /> */}

              <div className="text-sm py-2 text-gray-500 grid grid-cols-2 flex w-full">
                {/* <div className="text-left text-sm font-bold">
                  Unlocked for {unlockedTime}
                </div>
                <div className="justify-self-end">
                  <ClockIcon />
                </div> */}
              </div>
            </div>
          )}
        </>
      )}
      </div>
    </div>
  );
};

export default Post;
