import {
  AUTH_LOGIN_BEGIN,
  AUTH_API_LOGIN_ERROR,
  AUTH_API_LOGIN_SUCCESS,
  AUTH_LOGOUT_BEGIN,
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_FIREBASE_LOGIN_ERROR,
  AUTH_FIREBASE_LOGIN_SUCCESS,
} from "./constants";
import {
  logoutUser,
} from "../../services/authService";
import { USER_STATUS_ENDPOINT, FIREBASE_LOGIN_ENDPOINT } from '../../constants/endpoints';

// This tries to login to the api server
export const apiLogin = (token: string) => (dispatch: any) => {
  // const token = window.localStorage.getItem('token');
  dispatch({
    type: AUTH_LOGIN_BEGIN,
    payload: {},
    meta: {
      offline: {
        effect: {
          url: USER_STATUS_ENDPOINT, 
          method: 'GET', 
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        },
        commit: { type: AUTH_API_LOGIN_SUCCESS, meta: { token }},
        rollback: { type: AUTH_API_LOGIN_ERROR, meta: { token }}
        }
    }
  })
}

// This tries to login to Firebase
export const firebaseLogin = (token :string) => (dispatch: any) => {
  dispatch({
    type: AUTH_LOGIN_BEGIN,
    payload: {},
    meta: {
      offline: {
        effect: {
          url: FIREBASE_LOGIN_ENDPOINT, 
          method: 'POST', 
          body: JSON.stringify({
            fb_token: token,
          }), 
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        },
        commit: { type: AUTH_FIREBASE_LOGIN_SUCCESS, meta: { token }},
        rollback: { type: AUTH_FIREBASE_LOGIN_ERROR, meta: { token }}
        }
    }
  })
  return {success: true};
}

export const logout = () => (dispatch: any) => {
  dispatch({ type: AUTH_LOGOUT_BEGIN });
  logoutUser()
    .then(() => {
      dispatch({ type: AUTH_LOGOUT_SUCCESS });
      
      return true;
    })
    .catch((error) => {
      dispatch({ type: AUTH_LOGOUT_ERROR, payload: error });
      console.error(error);
    });
};

