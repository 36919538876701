/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { peopleSearch } from "../../store/people/actions";
import { useDispatch, useSelector } from "react-redux";
import store from "../../store";
import { setActiveLanding } from "../../store/layout/actions";
import { loadPerson } from "../../store/people/actions";
import { refreshPerson } from "../../store/orm/actions";
import { PersonType } from "../../types";
import { QrSvg } from "../common/Icons";
import { Button } from "../common/Button";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

// import { BASE_WEB_URL } from "../../constants/endpoints";

export type RootState = ReturnType<typeof store>;

export default function Example() {
  const isLoading = useSelector((state: RootState) => state.layout.isLoading);
  const [isScanner, setIsScanner] = useState(false);

  const [isopen, setOpen] = useState(false);
  const [queryString, setQueryString] = useState("");
  const dispatch = useDispatch();
  const searchResults = useSelector(
    (state: RootState) => state.people.searchResults
  );

  useEffect(() => {
    if (queryString === null) {
      setOpen(false);
    } else {
      dispatch(peopleSearch(queryString));
      setOpen(true);
    }
  }, [queryString, dispatch]);

  const handleBlur = () => {
    const timer = setTimeout(() => {
      setQueryString("");
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const [target_id, setTargetId] = useState("");

  const handleClick = (id: string) => {
    setIsScanner(false);
    setTargetId(id);
  };

  const handleScan = (redirect: string) => {
    setIsScanner(false);
    window.location.href = redirect;
  }

  useEffect(() => {
    if (target_id) {
      const refreshObject = {
        id: target_id,
        last_refresh_time: 1,
      };
      dispatch(loadPerson([refreshObject]));
      dispatch(refreshPerson([refreshObject]));
      dispatch(setActiveLanding("TARGET_PROFILE"));
    }
  }, [target_id, dispatch]);

  return (
    <div className="relative">
      <input
        id="search-field"
        className="dark:bg-gray-900 block w-full h-10 border-transparent text-gray-500 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-xl shadow-md"
        placeholder="Search Libertalia"
        type="search"
        name="search"
        onChange={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setQueryString(e.target.value);
        }}
        onBlur={() => handleBlur()}
        value={queryString ? queryString : ""}
        autoComplete="off"
      />
      <div className="flex justify-end pr-2 -mt-8 ">
        <Button
          variant="flat-3"
          Icon={QrSvg}
          spinning={isLoading}
          onClick={() => setIsScanner(!isScanner)}
        />
      </div>

      {isScanner && (
        <div className="mt-4 rounded-xl" hidden={!isScanner}>
          <BarcodeScannerComponent
            onUpdate={(err, result) => {
              result && handleScan(result.getText())
            }}
          />
        </div>
      )}

      <Transition
        show={isopen}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {isopen && (
          <div
            hidden={!queryString}
            className="absolute z-10 mt-3 w-full bg-white dark:bg-gray-900 shadow-lg max-h-56 rounded-xl py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          >
            {searchResults.map((person: PersonType, idx: number) => (
              <div key={idx}>
                {person.username && (
                  <button
                    key={person.id}
                    className="cursor-default select-none relative py-2 pl-3 pr-9 w-full"
                    onClick={() => handleClick(person.id)}
                  >
                    <>
                      <div className="flex items-center">
                        <img
                          src={person.photo_url}
                          alt=""
                          className="flex-shrink-0 h-6 w-6 rounded-full"
                        />
                        <span className="ml-3 block truncate font-normal dark:text-gray-200">
                          {person.name}
                        </span>
                      </div>
                    </>
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </Transition>
    </div>
  );
}
