import React from "react";
import { Image } from "../../components/common/Image";
import { FileUpload } from "../../components/FileUpload";

type PostPhotoPropsType = {
  thumbnailUrl: string;
  repColor?: string;
  setActiveLoadingMessage: Function;
  isEdit: boolean;
  activeLoadingMessage: string;
};

const PostPhoto = (props: PostPhotoPropsType) => {
  const {
    thumbnailUrl,
    repColor,
    setActiveLoadingMessage,
    isEdit,
    activeLoadingMessage,
  } = props;

  return (
    <>
      <div
        className="focus:outline-none relative pt-1"
        data-testid="button-test"
      >
        {!isEdit && (
          <div className="absolute w-full h-full flex justify-center">
            <div className="z-10 text-white font-bold flex items-center opacity-80">
              <div className="text-xl">{activeLoadingMessage ? activeLoadingMessage : ''}</div>
                <FileUpload
                  setActiveLoadingMessage={setActiveLoadingMessage}
                  setActiveLoadingPct={setActiveLoadingMessage}
                />
            </div>
          </div>
        )}
          <div className={`bg-${repColor}-400 rounded-xl w-full flex`}>
            <Image
              url={thumbnailUrl}
              blUrl={thumbnailUrl}
              className={"rounded-xl w-screen h-full opacity-50"}
            />
          </div>
      </div>
    </>
  );
};

export default PostPhoto;
