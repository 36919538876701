// The landing page handles auth flow, data loading and layout control
import React, { useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import {
  loadSelf,
  setSelfDoubloonBalance,
  setSelfXpBalance,
  setAssetBalance,
  setXpAssetBalance,
  loadPerson,
} from "../../store/people/actions";
import {
  setModal,
  setLoading,
  setLoadingState,
  setHasParameter,
  setParameter,
  setActiveLanding,
} from "../../store/layout/actions";
import { apiLogin } from "../../store/auth/actions";
// import {
//   // loadBalances,
//   tip,
//   loadConfirmedTransactions,
// } from "../../store/transactions/actions";
import { refreshPerson, refreshAssets } from "../../store/orm/actions";
// import waves from '../Feed/rock.webp'

// Components
import { Slideover } from "../../components/Slideover";
import { Modal } from "../../components/common/Modal";
import { Toast } from "../../components/common/Toast";
import { TopNav } from "../../components/TopNav";
import { TargetProfile } from "../TargetProfile";
import { Payout } from "../Payout";
import { Login } from "../Login";
import { Payment } from "../BuyDoubloons";
import { QR } from "../../components/QR";
import { Settings } from "../Settings";
// import { BigDoubloonButton } from "../../components/BigDoubloonButton";
// import { Portfolio } from "../../components/Portfolio";
// import { Tabs } from "../../components/common/Tabs";
import { Feed } from "../Feed";
import { PostEdit } from "../../components/PostEdit";
import { SelfProfile } from "../SelfProfile";
import { BottomNav } from "../../components/BottomNav";

// Utilities
import { useParams } from "react-router-dom";
import { Photo } from "../../components/Photo";
import { Spinner } from "../../components/Spinner";
import { TxDetail } from "../../components/TxDetail";
import DoubloonPurchaseConfirmation from "../../components/Notifications/Confirmation";
// import { refreshPerson } from "../../store/orm/actions";
import { PersonType } from "../../types";
import { validateUsername } from "../../services/authService";
import Confirmation from "../../components/Notifications/Confirmation";
// import { Helmet } from "react-helmet";
import { Post } from "../../components/Post";
import { Wallet } from "../Wallet";

export type RootState = ReturnType<typeof store>;

const Landing = () => {
  const dispatch = useDispatch();

  // Auth
  const token = useSelector((state: RootState) => state.auth.token);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const tokenError = useSelector((state: RootState) => state.auth.tokenError);
  // const isAuthLoading = useSelector(
  //   (state: RootState) => state.auth.isAuthLoading
  // );

  // Layout
  const isLoading = useSelector((state: RootState) => state.layout.isLoading);
  const activeSlideout = useSelector(
    (state: RootState) => state.layout.activeSlideout
  );
  const isSlideoutVisible = useSelector(
    (state: RootState) => state.layout.isSlideoutVisible
  );
  const isToastVisible = useSelector(
    (state: RootState) => state.layout.isToastVisible
  );
  const isModalVisible = useSelector(
    (state: RootState) => state.layout.isModalVisible
  );
  const activeModal = useSelector(
    (state: RootState) => state.layout.activeModal
  );
  const loadingState = useSelector(
    (state: RootState) => state.layout.loadingState
  );
  const activeLanding = useSelector(
    (state: RootState) => state.layout.activeLanding
  );

  // Profiles

  const activePersonId: string = useSelector(
    (state: RootState) => state.people.activePerson
  );
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );

  const activePerson: PersonType = allPeople && allPeople[activePersonId];
  const activePersonAssetId: string = activePerson?.asset_id;
  const activePersonXpAssetId: string = activePerson?.xp_asset_id;
  // const activePersonName: string = activePerson?.name;

  const selfId = useSelector((state: RootState) => state.people.self.id);

  // const isPeopleLoading = useSelector(
  //   (state: RootState) => state.people.isPeopleLoading
  // );

  // Transactions
  const balances: Array<any> = useSelector(
    (state: any) => state.orm.Asset.items
  );
  // const isTransactionLoading = useSelector(
  //   (state: RootState) => state.transactions.isTransactionLoading
  // );
  // const stagedTransactions = useSelector(
  //   (state: RootState) => state.transactions.stagedTransactions
  // );

  // Look for parameter, set it in store and load the target

  type IdType = {
    target_id?: string;
  };
  const { target_id } = useParams<IdType>();

  useEffect(() => {
    // setLoading(true);
    if (target_id) {
      // Load the Profile

      validateUsername(target_id).then((result) => {
        if (Object.keys(result).length !== 0) {
          const refreshObject = {
            id: result.id,
            last_refresh_time: 1,
          };
          dispatch(setHasParameter(true));
          dispatch(setParameter(target_id));
          dispatch(loadPerson([refreshObject]));
          dispatch(refreshPerson([refreshObject]));
          console.log(
            "Target Identified, Dispatched Person Loader: " + target_id
          );
        } else {
          dispatch(setHasParameter(false));
        }
      });
    }
    !target_id && dispatch(setHasParameter(false));
  }, [target_id, dispatch]);

  // This attempts to login to
  // api server with the local token. "NO_TOKEN" is the default
  // loading state. It will set tokenError if there's a problem with it

  useEffect(() => {
    loadingState === "NO_TOKEN" && token && dispatch(apiLogin(token));
    // dispatch(setLoading(true));
  }, [token, loadingState, dispatch]);

  // Set the loading state to INVALID_TOKEN if the above check triggers the error
  useEffect(() => {
    if (loadingState === "NO_TOKEN" && tokenError) {
      dispatch(setLoadingState("INVALID_TOKEN"));
      // dispatch(setLoading(false));
    }
  }, [tokenError, loadingState, dispatch]);

  // If the saved token is rejected by the server, present Login Modal.
  // Once Firebase logs in, loadSelf to populate people store's "self" object.

  useEffect(() => {
    // If I'm logged in, load me and set loading to just logged in
    if (isLoggedIn) {
      token && dispatch(loadSelf(token));
      dispatch(setModal(false));
      dispatch(setLoadingState("JUST_LOGGED_IN"));
    }
  }, [isLoggedIn, loadingState, token, dispatch]);

  // If the token is invalid and I'm not logged in and there's a target loaded, show it anyway
  useEffect(() => {
    if (
      loadingState === "INVALID_TOKEN" &&
      !isLoggedIn &&
      activePersonId &&
      target_id
    ) {
      console.log("Showing Target");
      dispatch(setModal(false));
      // dispatch(setSlideout(true));
    }
  }, [isLoggedIn, loadingState, activePersonId, target_id, dispatch]);

  // If a self profile exists, loop through balances array to get doubloon
  // and xp balance

  useEffect(() => {
    const getSelfBalances = () => {
      balances.map((asset) => {
        if (asset.id === "DBLN") {
          dispatch(setSelfDoubloonBalance(asset.qty));
        }
        if (asset.id === "XP") {
          dispatch(setSelfXpBalance(asset.qty));
        }
        return true;
      });
    };
    if (selfId !== "0") {
      // Set to 0 initially
      dispatch(setSelfDoubloonBalance(0));
      dispatch(setSelfXpBalance(0));
      console.log("Self Identified, Getting Doubloon and XP Balances");
      dispatch(setModal(false));
      getSelfBalances();
    }
  }, [balances, selfId, dispatch]);

  // If a parameter is specified, and the active person is loaded, loop through
  // balances and set asset balance for active person. (Amount of user currency I own)

  useEffect(() => {
    const getTargetBalances = (asset_id: string, xp_asset_id: string) => {
      return balances.map((asset) => {
        asset_id === asset.id && dispatch(setAssetBalance(asset.qty));
        xp_asset_id === asset.id && dispatch(setXpAssetBalance(asset.qty));
        return true;
      });
    };

    if (activePersonId && selfId !== activePersonId && target_id && balances) {
      dispatch(setAssetBalance(0));
      dispatch(setXpAssetBalance(0));
      console.log("Target Identified, Getting Asset and XP Balances");
      dispatch(setActiveLanding("TARGET_PROFILE"));
      // dispatch(setSlideout(true));
      getTargetBalances(activePersonAssetId, activePersonXpAssetId);
    }
  }, [
    dispatch,
    activePersonId,
    activePersonAssetId,
    activePersonXpAssetId,
    balances,
    selfId,
    target_id,
  ]);

  // If any of the store loading flags are active, set global loading

  // useEffect(() => {
  //   if (isAuthLoading || isTransactionLoading || isPeopleLoading) {
  //     dispatch(setLoading(true));
  //   } else {
  //     dispatch(setLoading(false));
  //   }
  // }, [
  //   isLoading,
  //   isAuthLoading,
  //   isTransactionLoading,
  //   isPeopleLoading,
  //   dispatch,
  // ]); // eslint-disable-line react-hooks/exhaustive-deps

  // Load Balances from Server
  useEffect(() => {
    if (token) {
      dispatch(refreshAssets(token));
      dispatch(setLoading(false));
    }
  }, [token, dispatch]);

  return (
    <main className="font-sans h-screen pb-10 dark:bg-gray-900">
        
      {isLoading && (
        <div className="flex justify-center text-primary-500 p-20 dark:bg-gray-900 dark:text-gray-500">
          <Spinner />
        </div>
      )}

      {/* Top Nav */}
      {!isLoading && activeLanding !== "TARGET_PROFILE" && (
        <div className="py-4 px-4 lg:px-10 h-24 hidden sm:block">
          <>
            <TopNav />
          </>
        </div>
      )}

      {/* Main */}
      {!isLoading && (
        <div className="sm:pt-4 dark:bg-gray-900">
          <div className="grid grid-cols-12 flex">
            <div className="invisible sm:visible lg:col-span-1"></div>
            <div className="col-span-12 lg:col-span-10">
            <div className="max-w-2xl mx-auto justify-center">

              {activeLanding === "TARGET_PROFILE" && <TargetProfile />}
              {activeLanding === "SELF_PROFILE" && <SelfProfile />}
              {activeLanding === "FEED" && (
                  <Feed />
              )}
              {activeLanding === "CONFIRMATION" && <Confirmation />}
              {activeLanding === "WALLET" && <Wallet />}
              </div>
            </div>
            <div className="invisible sm:visible md:col-span-1"></div>
          </div>
        </div>
      )}

      {/* Slideovers */}
      {!isLoading && (
        <Slideover>
          {/* User Settings */}
          {activeSlideout === "SELF_PROFILE" && <Settings />}
          {activeSlideout === "SPINNER" && <Spinner />}
        </Slideover>
      )}

      {!isSlideoutVisible && isToastVisible && <Toast />}

      {/* Modals */}
      {isModalVisible && (
        <Modal>
          {activeModal === "POST" && <PostEdit />}
          {activeModal === "LOGIN" && !isLoggedIn && !isLoading && <Login />}
          {activeModal === "BUY_DOUBLOONS" && <Payment />}
          {activeModal === "PAYOUT" && <Payout />}
          {activeModal === "QR" && <QR />}
          {activeModal === "TX_DETAIL" && <TxDetail />}
          {activeModal === "DOUBLOON_PURCHASE_CONFIRMATION" && (
            <DoubloonPurchaseConfirmation />
          )}
          {activeModal === "UNLOCK" && <Post />}
        </Modal>
      )}

      {activeModal === "PHOTO" && <Photo />}

      {isLoggedIn && !isLoading && <BottomNav />}
    </main>
  );
};

export default Landing;
