import React, { useState, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import store from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { setActiveModal, setModal } from '../../store/layout/actions';
import { getImage } from '../../services/utils';

export type RootState = ReturnType<typeof store>;




const Photo = () => {
  const activePhoto = useSelector(
    (state: RootState) => state.layout.activePhoto
  );
  const activeBlurPhoto = useSelector(
    (state: RootState) => state.layout.activeBlurPhoto
  );
  const [currentImage, setCurrentImage] = useState(0);
  const [cachedPhoto, setCachedPhoto] = useState([''])
  const [loadingStatus, setLoadingStatus] = useState("START");
  const dispatch = useDispatch();

  useEffect(() => {
    loadingStatus === "START" &&
      getImage(activeBlurPhoto).then(result => {
        result && setCachedPhoto([result.url]);
        setLoadingStatus("BLUR");
      })
    loadingStatus === "BLUR" &&
      getImage(activePhoto).then(result => {
        result && setCachedPhoto([result.url]);
        setLoadingStatus("FINISH");
      });
  },[activePhoto, activeBlurPhoto, loadingStatus])

  const closeImageViewer = () => {
    setCurrentImage(0);
    dispatch(setModal(false));
        dispatch(setActiveModal("POST"));

  };

  return (
    <div className="flex w-full min-w-full text-white">
        <ImageViewer
          src={cachedPhoto}
          currentIndex={currentImage}
          onClose={(closeImageViewer)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
    </div>
  );
}

export default Photo;
