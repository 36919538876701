import { Reducer } from "redux";
import {
  FileReducerType,
  FILE_ACTIVE_REPS,
  FILE_ADD_ACTIVE_FILE,
  FILE_CLEAR_ACTIVE_FILES,
  FILE_ADD_IMAGE_PASS_SUCCESS,
  FILE_SELECT_FILE,
  FILE_UPDATE_PROGRESS,
  FILE_REQUEST_UPLOADED_FILES,
  FILE_UPDATE_PENDING,
} from "./constants";

const defaultState = {
  activeReps: [4],
  activeFiles: [],
  unLockTarget: {
    id: '',
    person_id: '',
    reputation: 'NEUTRAL',
    bid: 0,
    last_refresh_time: 1,
  },
  selectedFile: '',
  selectedField: '',
  selectedUrl: '',
  progress: 0,
  filesPendingUpload: [],
  filesLatestUpload: [],
};

const fileReducer: Reducer<FileReducerType> = (
  state = defaultState,
  action: { type: String; payload?: any; meta?: any }
) => {
  switch (action.type) {
    case FILE_UPDATE_PENDING:
      return {
        ...state,
        filesPendingUpload: [...state.filesPendingUpload, action.payload]
      }
    case FILE_REQUEST_UPLOADED_FILES:
      const url = action.payload
      return {
        ...state,
        selectedField: url.field,
        selectedUrl: url.url,
        selectedFile: url.id,
      }
    case FILE_UPDATE_PROGRESS:
      return {
          ...state,
          progress: action.payload,
      }
    case FILE_ACTIVE_REPS: {
      return {
        ...state,
        activeReps: action.payload.idxAry,
      };
    }
    case FILE_ADD_ACTIVE_FILE: {
      return {
        ...state,
        activeFiles: [action.payload],
      };
    }
    case FILE_CLEAR_ACTIVE_FILES: {
      return {
        ...state,
        activeFiles: [],
        uploadedFileUrls: [],
        files: [],
        filesPendingUpload: [],
        filesLatestUpload: [],
        progress: 0,
        selectedField: '',
        selectedFile: '',
        selectedUrl: '',
      };
    }
    case FILE_ADD_IMAGE_PASS_SUCCESS: {
      return {
        ...state,
        unLockTarget: action.payload,
      };
    }
    case FILE_SELECT_FILE: {
      return {
        ...state,
        selectedFile: action.payload.id,
      }
    }
    default:
      return state;
  }
};

export default fileReducer;