import {
  MONEY_ACTIVATE,
  TRANSACTION_BEGIN,
  TRANSACTION_ERROR,
  TRANSACTION_SUCCESS,
  BUY_BEGIN,
  BUY_ERROR,
  BUY_SUCCESS,
  TRANSACTION_ADD_CONFIRM,
  TRANSACTION_LOG_ERROR,
  TRANSACTION_LOG_BEGIN,
  TRANSACTION_LOG_SUCCESS,
  // BALANCES_BEGIN,
  // BALANCES_ERROR,
  // BALANCES_SUCCESS,
  TRANSACTION_ACTIVATE,
  CLEAR_BUY
} from "./constants";
import { TransactionType, TipTransactionType, BuyType } from "../../types";
import { 
  TRANSACTION_ENDPOINT, 
  TIP_ENDPOINT, 
  BUY_ENDPOINT,
  TRANSACTION_LOG_ENDPOINT,
  // BALANCES_ENDPOINT,
} from "../../constants/endpoints";

export const transaction = (transaction: TransactionType) => (dispatch: any) => {
  dispatch({
    type: TRANSACTION_BEGIN,
    payload: { transaction },
    meta: {
      offline: {
        effect: {
          url: TRANSACTION_ENDPOINT,
          method: "POST",
          body: JSON.stringify(transaction), 
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${transaction.token}`,
          },
        },
        commit: { type: TRANSACTION_SUCCESS, meta: { transaction } },
        rollback: { type: TRANSACTION_ERROR, meta: { transaction } },
      },
    },
  });
  return { success: true };
};

export const loggedOutBuy = (transaction: BuyType) => (dispatch: any) => {
  dispatch({
    type: BUY_BEGIN,
    payload: { transaction },
  });
  return { success: true };
};

export const clearBuy = () => (dispatch: any) => {
  dispatch({type: CLEAR_BUY})
}

export const buy = (transaction: BuyType, token: string) => (dispatch: any) => {
  dispatch({
    type: BUY_BEGIN,
    payload: { transaction },
    meta: {
      offline: {
        effect: {
          url: BUY_ENDPOINT,
          method: "POST",
          body: JSON.stringify(transaction), 
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        commit: { type: BUY_SUCCESS, meta: { transaction } },
        rollback: { type: BUY_ERROR, meta: { transaction } },
      },
    },
  });
  return { success: true };
};

export const tip = (transaction: TipTransactionType, token: string) => (dispatch: any) => {
  dispatch({
    type: TRANSACTION_BEGIN,
    payload: { transaction },
    meta: {
      offline: {
        effect: {
          url: TIP_ENDPOINT,
          method: "POST",
          body: JSON.stringify(transaction), 
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        commit: { type: TRANSACTION_SUCCESS, meta: { transaction } },
        rollback: { type: TRANSACTION_ERROR, meta: { transaction } },
      },
    },
  });
  return { success: true };
};

export const exchangeActivate = (transactionAmount: string) => (dispatch: any) => {
  dispatch({ type: MONEY_ACTIVATE, payload: { transactionAmount } });
};

export const loadConfirmedTransactions = (token: string) => (dispatch: any) => {
  // dispatch({ type: TRANSACTION_LOAD_CONFIRM, payload: { transactions } });
  dispatch({
    type: TRANSACTION_LOG_BEGIN,
    payload: { transaction },
    meta: {
      offline: {
        effect: {
          url: TRANSACTION_LOG_ENDPOINT,
          method: "GET",
          body: JSON.stringify(transaction), 
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        commit: { type: TRANSACTION_LOG_SUCCESS, meta: { transaction } },
        rollback: { type: TRANSACTION_LOG_ERROR, meta: { transaction } },
      },
    },
  });
  return { success: true };
};

// export const loadBalances = (token: string) => (dispatch: any) => {
//   dispatch({
//     type: BALANCES_BEGIN,
//     payload: { transaction },
//     meta: {
//       offline: {
//         effect: {
//           url: BALANCES_ENDPOINT,
//           method: "GET",
//           body: JSON.stringify(transaction), 
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         },
//         commit: { type: BALANCES_SUCCESS, meta: { transaction } },
//         rollback: { type: BALANCES_ERROR, meta: { transaction } },
//       },
//     },
//   });
//   return { success: true };
// };

export const addConfirmedTransaction = (transaction: TipTransactionType) => (dispatch: any) => {
  dispatch({ type: TRANSACTION_ADD_CONFIRM, payload: { transaction } });
};

export const transactionActivate = (transaction: TipTransactionType) => (dispatch: any) => {
  dispatch({ type: TRANSACTION_ACTIVATE, payload: { transaction } });
};