import React, {  } from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import store from "../../store";
import { PersonType, FileType } from "../../types";
import { AssetGrid } from "../../components/AssetGrid";
import { PersonPanel } from "../../components/PersonPanel";
import { Image } from "../../components/common/Image";

import PurchaseButtons from "./PurchaseButtons";
// import { Spinner } from "../../components/Spinner";

export type RootState = ReturnType<typeof store>;

const Profile = () => {
  const activePersonId: string = useSelector(
    (state: RootState) => state.people.activePerson
  );
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: RootStateOrAny) => state.orm.Person.itemsById
  );
  const allFiles: { [id: string]: FileType } = useSelector(
    (state: RootStateOrAny) => state.orm.File.itemsById
  );
  const activePerson: PersonType = allPeople[activePersonId];
  // const isLoading: boolean = useSelector(
  //   (state: RootState) => state.layout.isLoading
  // );
  const images = allPeople[activePersonId]?.images
    ? allPeople[activePersonId]?.images
    : [0];
  const imageNumber = images ? images.length : 0;

  const mainImage =  images && allFiles[images[0]]
  

  // images?.map((image: any) => {
  //   if(image.is_blurred === 0){
  //     setMainImage(image);
  //   }
  //   return true;
  // })

  return (
    <>
      <div className="overflow-x-hidden mx-auto bg-cover dark:bg-gray-900" style={{ 
      // backgroundImage: `url("${mainImage?.lg_bl_url}")` 
    }}>
        <div className="">
          <PersonPanel
            personId={activePerson?.id}
            coverUrl={activePerson?.photo_url}
          />
        </div>
        <div className="flex grid mx-auto justify-center ">
          <PurchaseButtons />
        </div>
        {imageNumber > 0 && (
          <div className="sm:px-16 px-2">
            <div className="px-4 font-bold text-xl text-gray-700 dark:text-gray-300">Live</div>
            <div className="flex justify-center rounded-xl p-1 max-w-full max-h-full bg-red-400 dark:border-gray-800 m-2 border-2">
              {!mainImage?.is_blurred ? (
                <Image
                  url={mainImage?.ls_lq_url ? mainImage.ls_lq_url : ""}
                  className={"rounded-xl w-screen "}
                  blUrl={mainImage?.ls_bl_url ? mainImage.ls_bl_url : ""}
                />
              ) : (
                <Image
                url={mainImage?.ls_bl_url ? mainImage.ls_bl_url : ""}
                className={"rounded-xl w-screen opacity-50"}
                blUrl={mainImage?.ls_bl_url ? mainImage.ls_bl_url : ""}
              /> 
              )}
            </div>
          </div>
        )}
        <div className="flex grid mx-auto justify-center ">
          {imageNumber > 0 && (
            <>
              <div className="px-6 sm:px-10 font-bold text-xl text-gray-700 dark:text-gray-300 ">
                Current Season
              </div>
              <div className="max-w-xl px-2 ">
                <AssetGrid variant="LARGE" isSelf={false} />
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className=""></div> */}
    </>
  );
};

export default Profile;
