import React, {} from "react";
import store from "../../store";
import { numFormatter } from "../../services/utils";

export type RootState = ReturnType<typeof store>;

type CurrencyChipPropsType = {
  personName: string;
  assetName: string;
  balance: number;
};

const CurrencyChip = (props: CurrencyChipPropsType) => {
  const { personName, assetName, balance } = props;

  return (
    <>
        <div
          className={
            "shadow-xl pt-2 sm:pt-4 items-center px-2 sm:px-6 w-full flex rounded-xl" +
            " bg-primary-700 " 
          }
        >
          <div className="w-full pb-3 pr-1 pl-1">
            <div className="grid grid-rows flex justify-start text-white text-md sm:text-xl font-bold">
              {personName}
              {assetName}
            </div>
            <div className="text-2xl font-bold -mt-1 text-white text-left">
              {numFormatter(balance)}
            </div>
          </div>
        </div>
    </>
  );
};

export default CurrencyChip;
