import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import Doubloon2 from "../../components/common/Icons/Coins/Sprites/Doubloon2.svg";
import AssetConfirmation from "./AssetConfirmation";
import LoginConfirmation from "./LoginConfirmation";
import { loadSelf } from '../../store/people/actions';
// import { setLoading } from '../../store/layout/actions';


export type RootState = ReturnType<typeof store>;

const Confirmation = () => {
  const selectedTransaction = useSelector(
    (state: RootState) => state.transactions.selectedTransaction
  );
  const activeConfirmaton = useSelector(
    (state: RootState) => state.layout.activeConfirmation
  );
  const token = useSelector(
    (state: RootState) => state.auth.token
  );
  const dispatch = useDispatch();
  
  useEffect(() => {
    token && dispatch(loadSelf(token));
  },[token, dispatch])

  // useEffect(() => {
  //   activeConfirmaton && dispatch(setLoading(false))
  // },[activeConfirmaton, dispatch])

  const utcSeconds = selectedTransaction?.chain_confirmed_at;
  let transactionTime = new Date(0);
  utcSeconds && transactionTime.setUTCSeconds(utcSeconds);

  return (
    <div className="flex justify-center h-screen">
    
<div className="grid grid-rows-10 flex items-center">
        <div className="row-span-1"></div>
        <div className="flex row-span-1 justify-center mx-auto h-32 w-32">
          <object type="image/svg+xml" data={Doubloon2}> </object>
        </div>
        <div className="row-span-4">
          {activeConfirmaton === "ASSET" && (
            <AssetConfirmation
              qty={
                selectedTransaction?.asset_qty &&
                Math.abs(selectedTransaction?.asset_qty)
              }
              txid={selectedTransaction?.chain_txid}
              date={transactionTime.toString()}
              assetName="Doubloon"
            />
          )}
          {activeConfirmaton === "LOGIN" && (
            <LoginConfirmation />
          )}
        </div>
        <div className="row-span-3"></div>
      </div>
      
      
    
    </div>
  );
};

export default Confirmation;
