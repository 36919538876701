import React, { useEffect,  } from "react";
import { useParams } from "react-router-dom";
import store from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../components/Post";
import { selectFile } from "../../store/files/actions";
import { refreshFile } from "../../store/orm/actions";
import { Modal } from "../../components/common/Modal";
import { setModal, } from "../../store/layout/actions";
import { FileType } from "../../types";
import { apiLogin } from "../../store/auth/actions";

export type RootState = ReturnType<typeof store>;

type IdType = {
  target_id?: string;
};

const PostLanding = () => {
  const dispatch = useDispatch();
  const { target_id } = useParams<IdType>();
  const token = useSelector((state: RootState) => state.auth.token);

  const loadingState = useSelector(
    (state: RootState) => state.layout.loadingState
  );

  const allFiles: { [id: string]: FileType } = useSelector(
    (state: any) => state.orm.File.itemsById
  );
  // const [consoleFile, setConsoleFile] = useState("");

  useEffect(() => {
    token && dispatch(apiLogin(token));
  }, [token, loadingState, dispatch]);

  useEffect(() => {
    if (target_id) {
      const refreshObject = {
        id: target_id,
        last_refresh_time: allFiles[target_id]?.last_refresh_time
          ? allFiles[target_id]?.last_refresh_time
          : 1,
      };
      dispatch(selectFile(target_id));
      dispatch(refreshFile([refreshObject]));
      // setConsoleFile(allFiles[target_id]?.person_id);
    }
  }, [target_id, dispatch, allFiles]);

  useEffect(() => {
    target_id &&
      target_id === allFiles[target_id]?.id &&
      dispatch(setModal(true));
  }, [allFiles, dispatch, target_id]);

  return (
    <div className="h-screen font-sans">
      {target_id && (
        <Modal>
          <Post />
        </Modal>
      )}
    </div>
  );
};

export default PostLanding;
