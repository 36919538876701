import React from "react";
import { useDispatch } from "react-redux";
import {
  setActiveBlurPhoto,
  setActiveModal,
  setActivePhoto,
  setModal,
} from "../../store/layout/actions";
import { setActiveFiles, selectFile } from "../../store/files/actions";
import { Image } from "../../components/common/Image";
import { getReputationColor } from "../../services/xpService";
import {
  NeutralSm,
  FriendlySm,
  HonoredSm,
  ReveredSm,
  ExaltedSm,
} from "../common/Icons";

type ThumbnailPropsType = {
  id: string;
  sq_lq_url?: string;
  lg_lq_url?: string;
  sq_bl_url?: string;
  lg_bl_url?: string;
  rep?: string;
  isBlurred?: boolean;
  variant: string;
  isLoggedIn: boolean;
  price?: number;
  hidden?: boolean;
  postdate?: string;
};

const Thumbnail = (props: ThumbnailPropsType) => {
  const {
    price,
    sq_lq_url,
    lg_lq_url,
    lg_bl_url,
    sq_bl_url,
    rep,
    variant,
    isBlurred,
    id,
    isLoggedIn,
    hidden,
    postdate,
  } = props;

  const dispatch = useDispatch();

  const handlePhotoClick = () => {
    dispatch(selectFile(id));
    if (variant === "SELF") {
      dispatch(setActiveFiles(id));
      lg_bl_url && dispatch(setActiveBlurPhoto(lg_bl_url));
      lg_lq_url && dispatch(setActivePhoto(lg_lq_url));
      isLoggedIn
        ? dispatch(setActiveModal("POST"))
        : dispatch(setActiveModal("LOGIN"));
      dispatch(setModal(true));
    } else {
      lg_bl_url && dispatch(setActiveBlurPhoto(lg_bl_url));
      lg_lq_url && dispatch(setActivePhoto(lg_lq_url));
      dispatch(setActiveModal("UNLOCK"));
      dispatch(setModal(true));
    }
  };

  return (
    <div
      className={`bg-${rep ? getReputationColor(rep) : "gray"}-500 
      dark:border-${rep ? getReputationColor(rep) : "gray"}-800 
    border-2 p-1 rounded-xl relative h-full w-full 
    `}
    >
      <button
        onClick={() => {
          handlePhotoClick();
        }}
        // className={`bg-${
        //   rep ? getReputationColor(rep) : "gray"
        // }-500 focus:outline-none rounded-xl min-w-full flex`}
      >
        {sq_lq_url ? (
          <>
            {!isBlurred && sq_lq_url && (
              <>
                <div className="flex rounded-xl">
                  <Image
                    url={sq_lq_url}
                    blUrl={sq_bl_url ? sq_bl_url : sq_lq_url}
                    className={
                      "flex rounded-xl " 
                    }
                    hidden={hidden}
                    onLoad={() => {
                      return true;
                    }}
                  />
                  <div
                    hidden={!postdate}
                    className="absolute justify-start w-full flex h-full top-0 pt-1 items-end pl-2 pb-2 text-white font-bold"
                  >
                    {postdate}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div>
            {isBlurred && sq_bl_url ? (
              <>
                <div className="flex rounded-xl">
                  {
                    <Image
                      url={sq_bl_url}
                      blUrl={sq_bl_url}
                      className="flex w-screen rounded-xl opacity-50  "
                      hidden={hidden}
                      onLoad={() => {
                        return true;
                      }}
                    />
                  }
                </div>

                <div className="absolute justify-center w-full flex h-full top-0 pt-1 items-center">
                  <div className={``}>
                    {rep === "NEUTRAL" && (
                      <img
                        src={NeutralSm}
                        alt=""
                        className="h-7 w-7 sm:h-12 sm:w-12"
                      />
                    )}
                    {rep === "FRIENDLY" && (
                      <img
                        src={FriendlySm}
                        alt=""
                        className="h-7 w-7 sm:h-12 sm:w-12"
                      />
                    )}
                    {rep === "HONORED" && (
                      <img
                        src={HonoredSm}
                        alt=""
                        className="h-7 w-7 sm:h-12 sm:w-12"
                      />
                    )}
                    {rep === "REVERED" && (
                      <img
                        src={ReveredSm}
                        alt=""
                        className="h-7 w-7 sm:h-12 sm:w-12"
                      />
                    )}
                    {rep === "EXALTED" && (
                      <img
                        src={ExaltedSm}
                        alt=""
                        className="h-7 w-7 sm:h-12 sm:w-12"
                      />
                    )}
                  </div>
                  <div className="absolute bottom-0 pr-4 w-full flex justify-end pb-2">
                    <div className="text-white text-xs sm:text-lg font-bold flex items-end justify-end">
                      {price}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="bg-gray-300 w-full h-full rounded-xl"></div>
            )}
          </div>
        )}
      </button>
    </div>
  );
};

export default Thumbnail;
