// import { loadStripe } from "@stripe/stripe-js";
// import { STORE_INSUFFICIENT_FUNDS } from "../../constants/copy";
import { BASE_WEB_URL } from "../../constants/endpoints";
import DoubloonPanel from "./DoubloonPanel";
// import { setModal } from '../../store/layout/actions'
import { buy, clearBuy } from '../../store/transactions/actions';
import { useSelector, useDispatch } from 'react-redux'
import store from "../../store";
import { BuyType } from '../../types';
import {
  STRIPE_DB100_KEY,
  STRIPE_DB500_KEY,
  STRIPE_DB1625_KEY,
  STRIPE_DB4650_KEY,
  // STRIPE_API_KEY
} from '../../constants/config';
import { useEffect } from "react";

export type RootState = ReturnType<typeof store>;


const Payment = () => {

  // const person = useSelector((state: RootState) => state.people.self);
  const target = useSelector((state: RootState) => state.layout.parameter);
  const token = useSelector((state: RootState) => state.auth.token);
  // const email = useSelector((state: RootState) => state.people.self.email);
  const redirect = useSelector((state: RootState) => state.transactions.redirect);

  const dispatch = useDispatch()

  const stripeDb100 = STRIPE_DB100_KEY ? STRIPE_DB100_KEY : ""
  const stripeDb500 = STRIPE_DB500_KEY ? STRIPE_DB500_KEY : ""
  const stripeDb1625 = STRIPE_DB1625_KEY ? STRIPE_DB1625_KEY : ""
  const stripeDb4650 = STRIPE_DB4650_KEY ? STRIPE_DB4650_KEY : ""
  // const stripeApiKey = STRIPE_API_KEY ? STRIPE_API_KEY : ""
  // const stripePromise = loadStripe(stripeApiKey);

  let successUrl = BASE_WEB_URL + "/stripe_success/";
  let cancelUrl = BASE_WEB_URL;

  if (target) {
    successUrl = successUrl + target;
  }
  if (target) {
    cancelUrl = cancelUrl + "/" + target;
  }

  useEffect(() => {
    const url = redirect;
    dispatch(clearBuy());
    if(redirect !== ''){
      window.location.href = url;
    }
  },[redirect, dispatch])

  const handlePayment = async (paymentId: string) => {

    const idem = 'p' + require("crypto").randomBytes(8).toString('hex');

    const buyObject: BuyType = {
      id: idem,
      provisioned_at: (Date.now() / 1000),
      status: 'PROVISIONED',
      stripePaymentKey: paymentId,
      successUrl:  successUrl,
      cancelUrl: cancelUrl,
    };

    token && dispatch(buy(buyObject, token));
  };

  return (
    <>
      <div className="py-5 px-2 sm:px-6">
        <>
          {/* {balance && balance <= 0 && <p>{STORE_INSUFFICIENT_FUNDS}</p>} */}
          <DoubloonPanel
            amt="100"
            price="$1.60"
            handlePayment={handlePayment}
            paymentId={stripeDb100}
            testid='doubloon-panel-test'
          />

          <DoubloonPanel
            amt="500"
            price="$8"
            handlePayment={handlePayment}
            paymentId={stripeDb500}
          />

          <DoubloonPanel
            amt="1625"
            price="$25"
            handlePayment={handlePayment}
            savings="5% Discount"
            paymentId={stripeDb1625}
          />

          <DoubloonPanel
            amt="4650"
            price="$65"
            handlePayment={handlePayment}
            savings="8% Discount"
            paymentId={stripeDb4650}
          />
        </>
      </div>
    </>
  );
};

export default Payment;
