import React, { useState } from "react";
import { setActiveLanding, setModal } from "../../store/layout/actions";
import { getInitials } from "../../services/utils";
import PostReputationIndicator from "./PostReputationIndicator";
import { capitalizeFirstLetter } from "../../services/utils";
import { loadPerson } from "../../store/people/actions";
import { useDispatch } from "react-redux";
import { FileType } from "../../types";
import store from "../../store";
import {
  setActiveBlurPhoto,
  setActiveModal,
  setActivePhoto,
} from "../../store/layout/actions";
import { PhotographIcon, Share } from "../common/Icons";
import { XpMeter } from "../XpMeter";
import { BASE_WEB_URL } from "../../constants/endpoints";

export type RootState = ReturnType<typeof store>;

type PostProfilePropsType = {
  target_id: string;
  targetPersonBgColor: string;
  targetPersonTextColor: string;
  targetPersonRingColor: string;
  name: string;
  person_photo_url: string;
  subtitle?: string;
  target_post_reputation?: string;
  targetPersonAssetBalance: number;
  targetPersonAssetName: string;
  lastRefreshTime: number;
  targetPersonXp: number;
  isBlurred: boolean;
  file: FileType;
};

const PostProfile = (props: PostProfilePropsType) => {
  const {
    target_id,
    targetPersonBgColor,
    targetPersonRingColor,
    targetPersonTextColor,
    name,
    person_photo_url,
    target_post_reputation,
    targetPersonAssetBalance,
    targetPersonAssetName,
    targetPersonXp,
    isBlurred,
    file,
  } = props;

  const dispatch = useDispatch();
  const [avatarLoaded, setAvatarLoaded] = useState(false);

  const handleQrButton = () => {
    navigator?.clipboard?.writeText(BASE_WEB_URL + "/asset/" + file.id);
    if (navigator.share) {
      navigator.share({
        title: name,
        text: 'Libertalia Asset Share',
        url: BASE_WEB_URL + "/asset/" + file.id,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  };

  const handleProfileClick = (target_id: string) => {
    const refreshObject = {
      id: target_id,
      last_refresh_time: 1,
    };
    dispatch(loadPerson([refreshObject]));
    dispatch(setActiveLanding("TARGET_PROFILE"));
    dispatch(setModal(false));
  };

  const handleFullScreen = () => {
    !isBlurred && dispatch(setActiveModal("PHOTO"));
    file.lg_bl_url && dispatch(setActiveBlurPhoto(file.lg_bl_url));
    !isBlurred && file.lg_hq_url && dispatch(setActivePhoto(file.lg_hq_url));
  };

  return (
    <div className="grid grid-rows-12 items-start max-h-28">
      <div className=" grid grid-cols-12 items-start space-x-2 row-start-1">
        <button
          onClick={() => handleProfileClick(target_id)}
          className="focus:outline-none col-span-2 p-1"
          data-testid="button-test"
        >
          <div className="">
            {!avatarLoaded && name && (
              <div
                className={
                  "ring rounded-full text-xl md:text-3xl text-white " +
                  targetPersonBgColor +
                  " " +
                  targetPersonRingColor
                }
              >
                {getInitials(name)}
              </div>
            )}
            <img
              className={
                "ring-2 rounded-full " +
                targetPersonBgColor +
                " " +
                targetPersonRingColor
              }
              style={{ display: avatarLoaded ? "block" : "none" }}
              src={person_photo_url}
              alt=""
              onLoad={() => setAvatarLoaded(true)}
            />
          </div>
        </button>

        <div className="col-span-8 -mt-1">
          <div className="grid grid-rows-3 col-span-2 sm:col-span-4 md:col-span-6">
            <div
              className={
                "flex justify-start text-md sm:text-lg font-bold grid grid-rows" +
                targetPersonTextColor
              }
            >
              <div className="text-left">{name}</div>
              <div className="text-left font-thin text-xs">
                {targetPersonAssetBalance}{" "}
                {targetPersonAssetName &&
                  capitalizeFirstLetter(targetPersonAssetName)}
              </div>
              <div className="text-left flex pt-1">
                {!isBlurred && (
                  <button
                    className="cursor-pointer text-gray-700 pr-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleFullScreen()}
                  >
                    <PhotographIcon />
                  </button>
                )}
                <button
                  className="cursor-pointer text-gray-700 pl-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleQrButton()}
                >
                  <Share />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex col-span-2 justify-end pr-5">
          <PostReputationIndicator
            target_post_reputation={target_post_reputation ? target_post_reputation : 'EXALTED'}
          />
        </div>
      </div>

      <div className="row-start-2 pt-1">
        <XpMeter amount={targetPersonXp} />
      </div>
    </div>
  );
};

export default PostProfile;
