//Background.js
import React from 'react';

const Background = ( { children }: any ) =>
{
    return (
       
        <body className="bg-white dark:bg-black transition-all">
            {children}
        </body>
    )
}

export default Background;