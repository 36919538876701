import { Model, attr, fk } from 'redux-orm';
// import { FileType, PersonType } from '../../types';

export class File extends Model {}
export class Person extends Model {}
export class Asset extends Model {}

File.modelName = 'File';
File.fields = {
    id: attr(),
    lg_hq_url: attr(),
    lg_lq_url: attr(),
    lg_bl_url: attr(),
    md_hq_url: attr(),
    md_lq_url: attr(),
    md_bl_url: attr(),
    sq_lq_url: attr(),
    sq_bl_url: attr(),
    person_id: fk({
        to: 'Person',
        as: 'person',
        relatedName: 'files',
    }),
    reputation: attr(),
    bid: attr(),
    status: attr(),
    is_blurred: attr(),
    last_refresh_time: attr(),
}

Person.modelName = 'Person';
Person.fields = {
    id: attr(),
    name: attr(),
    username: attr(),
    photo_url: attr(),
    asset_balance: attr(),
    xp_asset_balance: attr(),
    xp_asset_id: attr(),
    asset_id: attr(),
    asset_name: attr(),
    facebook_link: attr(),
    instagram_link: attr(),
    soundcloud_link: attr(),
    tiktok_link: attr(),
    last_refresh_time: attr(),
}

Asset.modelName = 'Asset';
Asset.fields = {
    id: attr(),
    name: attr(),
    qty: attr(),
    person_id: fk({
        to: 'Person',
        as: 'person',
        relatedName: 'assets',
    }),
}