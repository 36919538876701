/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HamburgerSvg } from "../../components/common/Icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setModal,
  setActiveModal,
  setSlideout,
  // setLoading,
  // setToast,
  // setActiveToast,
  setActiveLanding,
} from "../../store/layout/actions";
import { logout } from "../../store/auth/actions";
import store from "../../store";
import { BASE_WEB_URL } from "../../constants/endpoints";
import firebase from "firebase/app";
import "firebase/auth";

export type RootState = ReturnType<typeof store>;
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const ProfileMenu = () => {
  const handleLogout = () => {
    dispatch(setSlideout(false));
    // dispatch(setLoading(true));
    firebase
      .auth()
      .signOut()
      .then((e) => {
        dispatch(setActiveLanding(""))
        dispatch(logout());
        // dispatch(setActiveToast("LOGOUT"));
        // dispatch(setModal(false));
        // dispatch(setToast());
        // dispatch(setLoading(false));
        setTimeout(() => {
          BASE_WEB_URL && window.location.assign(BASE_WEB_URL + "/destroy");
        },100)
      });
  };
  const handleBuyDoubloons = () => {
    dispatch(setSlideout(false));
    dispatch(setActiveModal("BUY_DOUBLOONS"));
    dispatch(setModal(true));
  };

  const handleSellDoubloons = () => {
    dispatch(setSlideout(false));
    dispatch(setActiveModal("PAYOUT"));
    dispatch(setModal(true));
  };

  const handleQR = () => {
    dispatch(setSlideout(false));
    dispatch(setActiveModal("QR"));
    dispatch(setModal(true));
  };

  const dispatch = useDispatch();

  const [payoutButtonClick, setPayoutButtonClick] = useState(false);
  const stripe_account_link = useSelector(
    (state: RootState) => state.people.self.stripe_account_link
  );
  const isPayoutEnabled = useSelector(
    (state: RootState) => state.people.self.payouts_enabled
  );
  const { online } = useSelector((state: RootState) => state.offline);

  useEffect(() => {
    stripe_account_link &&
      payoutButtonClick &&
      window.location.assign(stripe_account_link);
  }, [payoutButtonClick, stripe_account_link]);

  const [isOpen, setIsOpen] = useState(false);
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white focus:outline-none"
            >
              <span className="sr-only">Open options</span>
              <HamburgerSvg />
            </Menu.Button>
          </div>

          {open && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              show={open}
            >
              <Menu.Items
                static
                className="origin-top-right absolute -right-6 top-6 mt-4 w-56 rounded-bl-md shadow-md bg-white ring-1 ring-primary-700 ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  {online && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => handleBuyDoubloons()}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-sm text-left"
                          )}
                        >
                          Buy Doubloons
                        </button>
                      )}
                    </Menu.Item>
                  )}

                  {online && (
                    <>
                      {!isPayoutEnabled ? (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => setPayoutButtonClick(true)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                            >
                              Setup Payouts
                            </button>
                          )}
                        </Menu.Item>
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => handleSellDoubloons()}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                            >
                              Sell Doubloons
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </>
                  )}

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleQR()}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full text-left px-4 py-2 text-sm"
                        )}
                      >
                        QR Code
                      </button>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleLogout()}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full text-left px-4 py-2 text-sm"
                        )}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  );
};

export default ProfileMenu;
