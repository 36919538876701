/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from "react";
import {
  NeutralSm,
  FriendlySm,
  HonoredSm,
  ReveredSm,
  ExaltedSm,
} from "../common/Icons";
import { setActiveRep } from "../../store/files/actions";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
export type RootState = ReturnType<typeof store>;

const plans = [
  {
    name: "Neutral",
    color: "text-gray-700",
    bgColor: "bg-gray-700",
    index: 0,
    icon: NeutralSm,
  },
  {
    name: "Friendly",
    color: "text-green-700",
    bgColor: "bg-green-700",
    index: 1,
    icon: FriendlySm,
  },
  {
    name: "Honored",
    color: "text-blue-700",
    bgColor: "bg-blue-700",
    index: 2,
    icon: HonoredSm,
  },
  {
    name: "Revered",
    color: "text-purple-700",
    bgColor: "bg-purple-700",
    index: 3,
    icon: ReveredSm,
  },
  {
    name: "Exalted",
    color: "text-red-700",
    bgColor: "bg-red-700",
    index: 4,
    icon: ExaltedSm,
  },
];

type RadioGroupPropsType = {
  reputation?: string;
  id: string;
  setReputation: Function;
};

export default function Example(props: RadioGroupPropsType) {
  const { setReputation } = props;
  const [selected, setSelected] = useState(4);
  const selectedArray = useSelector(
    (state: RootState) => state.files.activeReps
  );

  const dispatch = useDispatch();

  useEffect(() => {
    selected === 0 && dispatch(setActiveRep([0, 1, 2, 3, 4]));
    selected === 1 && dispatch(setActiveRep([1, 2, 3, 4]));
    selected === 2 && dispatch(setActiveRep([2, 3, 4]));
    selected === 3 && dispatch(setActiveRep([3, 4]));
    selected === 4 && dispatch(setActiveRep([4]));
    setReputation()

  }, [selected, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRepChange = (plan: any) => {
    setSelected(plan.index)
  }

  return (
    <div className="w-72 grid grid-cols-2 pb-1 -mt-6 flex items-center justify-end">
      <div className=""></div>
      <div className="flex grid grid-cols-5">
      {plans.map(plan => {
        let bgColor = 'bg-white'
        if(selectedArray.includes(plan.index)){
          bgColor = plan.bgColor
        }
        return (
        <div className={""} key={plan.name}>
          <button
            key={plan.name}
            onClick={() => handleRepChange(plan)}
            className={"rounded-full p-1 shadow-md border-2 " + bgColor}
          >
            <img src={plan.icon} alt="" className="" />
          </button>
        </div>
      )}
      )}
      </div>
    </div>
  );
}
