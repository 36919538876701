import React, {  } from "react";
import {
  CoinsSm,
  CoinsMd,
  CoinsLg,
  CoinsXl
}from '../../components/common/Icons';
import { Button } from "../../components/common/Button";

type DoubloonPanelPropsType = {
  amt: string;
  price: string;
  handlePayment: Function;
  savings?: string;
  paymentId: string;
  testid?: string;
};

const DoubloonPanel = (props: DoubloonPanelPropsType) => {
  const { amt, price, handlePayment, savings, paymentId, testid } = props;

  return (
    <div className="grid grid-cols-1">
      <div className="items-center my-1 justify-between relative border rounded border-gray-200 px-2 py-2 shadow-md flex items-center">
        <div className="grid grid-cols-2">

          {/* <React.Suspense fallback="Loading"> */}
            <div className="w-12 flex">
              {amt === "100" && <img src={CoinsSm} loading="lazy" alt=""/>}
              {amt === "500" && <img src={CoinsMd} loading="lazy" alt=""/>}
              {amt === "1625" && <img src={CoinsLg} loading="lazy" alt=""/>}
              {amt === "4650" && <img src={CoinsXl} loading="lazy" alt=""/>}
            </div>
          {/* </React.Suspense> */}

          <div className="grid">
            <div className="flex text-xs text-primary-700 -mb-2">DOUBLOONS</div>
            <div className="flex font-bold text-2xl text-primary-700">
              {amt}
            </div>
          </div>
        </div>
        <div className="grid pl-4">
          <div className="flex-shrink w-32">
            <Button
              variant="primary"
              onClick={() => handlePayment(paymentId)}
              label={price}
              fullWidth
              testid={testid ? testid : 'none'}
            />
          </div>
          <div className="flex text-sm text-primary-700 justify-center">
            {savings ? savings : <div>&nbsp;</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubloonPanel;
