import React, { useEffect } from "react";
import { PurchaseButtons } from "../Inventory";
import Currencies from "../Inventory/Currencies";
import { TxLog } from "../../components/TxLog";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import {
    loadConfirmedTransactions,
  } from "../../store/transactions/actions";

export type RootState = ReturnType<typeof store>;

const Wallet = () => {
    const dispatch = useDispatch();

    const token = useSelector((state: RootState) => state.auth.token);

    // Load Transactions from Server
  useEffect(() => {
    if (token) {
      dispatch(loadConfirmedTransactions(token));
    }
  }, [token, dispatch]);

  return (
    <div className="grid grid-cols-12">
      <div className="sm:col-span-1 col-span-0"></div>
      <div className="sm:col-span-10 col-span-12 w-full">
        <div className="font-bold pl-4 text-gray-600 dark:text-gray-400 flex">Buy Doubloons</div>
        <PurchaseButtons />
        <div className="font-bold pl-4 text-gray-600 dark:text-gray-400 flex">Currencies</div>
        <Currencies />
        <div className="font-bold pl-4 pt-2 text-gray-600 dark:text-gray-400 flex">
          Transaction Log
        </div>
        <TxLog />
      </div>
      <div className="sm:col-span-1 col-span-0"></div>
    </div>
  );
};

export default Wallet;
