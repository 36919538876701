import { Image } from '../common/Image';

type AvatarPropsType = {
  image_url: string;
  blUrl: string;
};

const Avatar = (props: AvatarPropsType) => {
  const { image_url, blUrl } = props;

  return (
    <div className="mx-auto">
  
        <div className="">
          <div className="flex justify-center mx-auto w-full h-full rounded-full ring ring-white rounded-full mx-auto">
            <Image 
              url={image_url}
              className="ring ring-white w-full h-full rounded-full mx-auto"
              onLoad={() => {return null}}
              blUrl={blUrl}
            />
          </div>

        </div>
    </div>
  );
};

export default Avatar;
