import React from "react";
import DoubloonSpinner from "../common/Icons/Coins/Sprites/doubloon-spinning.svg";

type SpinnerPropsType = {
  label?: string,
  classNames?: string,
}

const Spinner = (props: SpinnerPropsType) => {
  const { label, classNames } = props;
  return (
    <>
      <div className="grid grid-rows-2 flex place-content-center items-center justify-center mx-auto">
        <div className={classNames ? classNames : "flex h-32 w-32 mx-auto items-center -ml-12"}>
          <object type="image/svg+xml" data={DoubloonSpinner} className="flex mx-auto items-center">
            svg-animation
          </object>
        </div>
        <div className="flex animate-pulse mx-auto pt-10">
          <div className="text-2xl font-bold">{label ? label : 'Libertalia'}</div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
