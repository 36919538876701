import React from 'react';
import { Image } from "../../components/common/Image";

type ConfirmPropTypes = {
    handleUnlock: Function;
    setIsConfirmationVisible: Function;
    color: string,
    url: string,
    amount: number,
    currency: string,
    id: string,
}
const Confirm = (props: ConfirmPropTypes) => {
    const { handleUnlock, setIsConfirmationVisible, color,  url, amount, currency, id } = props;
    return (
        <div className="focus:outline-none relative pt-1">
        <div className="absolute w-full h-full flex justify-center">
          <div className="z-10 text-white font-bold flex items-center">
            <div className="flex grid grid-rows">
            <div className="text-xl">Confirm Unlock</div>
            <div className="text-xl">{amount} {currency}</div>
            </div>

            <button 
            onClick={() => handleUnlock(id)}
            className="absolute bottom-5 right-10 border-2 rounded-md py-2 px-8">
              Yes
            </button>
            <button 
            onClick={() => setIsConfirmationVisible(false)}
            className=" absolute bottom-5 left-10 border-2 rounded-md py-2 px-8">
              No
            </button>
          </div>
        </div>

        <div className={`flex bg-${color}-700 rounded-md w-full `}>
          <div className={"flex justify-center rounded-md w-full "}>
            <Image
              url={url}
              blUrl={url}
              className={"rounded-md w-screen opacity-50"}
            />
          </div>
        </div>
      </div>
    )
}

export default Confirm;