import orm from "./orm";
import {
  REFRESH_PERSON,
  REFRESH_FILE,
  // REFRESH_FILE_ERROR,
  REFRESH_FILE_SUCCESS,
  OPTIMISTIC_FILE_SUCCESS,
  REFRESH_PERSON_SUCCESS,
  REFRESH_PERSON_ERROR,
  POST_FILE_SUCCESS,
  // REFRESH_ASSETS,
  REFRESH_ASSETS_SUCCESS,
  DESTROY_FILE,
} from "./constants";
import Model, { ORMReducer } from "redux-orm";
import { mergeObjects } from '../../services/utils';
import { FileType, PersonType, AssetType } from "../../types";

const emptyDbState = orm.getEmptyState();

const ormReducer: ORMReducer<Model> = (
  dbState: typeof emptyDbState = orm.getEmptyState(),
  action: { type: String; payload?: any; meta?: any }
) => {
  const sess = orm.session(dbState);

  const { File, Person, Asset } = sess;

  switch (action.type) {
    case DESTROY_FILE:
      const fileDestructionCandidate = File.withId(action.payload.id);
      fileDestructionCandidate && fileDestructionCandidate?.delete();
      break;
    case REFRESH_ASSETS_SUCCESS:
      action?.payload?.map((asset: AssetType) => {
        const refreshAssetCandidate = Asset.withId(asset.id);
        if (refreshAssetCandidate) {
          asset.last_refresh_time = Date.now() / 1000;
          Asset?.withId(asset.id)?.update(asset);
        } else {
          if (asset.id) {
            asset.last_refresh_time = Date.now() / 1000;
            Asset?.create(asset);
          }
        }
        return true;
      });
      break;
    case POST_FILE_SUCCESS:
      // console.log("refreshing files");
      action?.payload?.file?.map((file: FileType) => {
        // console.log("remote file blur: " + file.lg_bl_url);
        // console.log("remote file hq: " + file.lg_hq_url);
        const refreshFileCandidate = File.withId(file.id);
        // console.log("local file blur: " + refreshFileCandidate.lg_bl_url);
        // console.log("local file hq: " + refreshFileCandidate.lg_hq_url);

        if(refreshFileCandidate){
          file.last_refresh_time = Date.now() / 1000;
          if(refreshFileCandidate.is_blurred === false && file.is_blurred === 1){
            refreshFileCandidate.delete();
            File?.create(file);
          } else if(refreshFileCandidate.is_blurred === true && file.is_blurred === 0){
            // refreshFileCandidate.delete();
            // File?.create(file);
            const result = mergeObjects(File.withId(file.id), file);
            File?.withId(file.id)?.update(result);
          } else {
            const result = mergeObjects(File.withId(file.id), file);
            File?.withId(file.id)?.update(result);
          }
        } else {
          if(file.id){
            file.last_refresh_time = Date.now() / 1000;
            File?.create(file);
          }
        }
        return true;
      });
      break;
    case OPTIMISTIC_FILE_SUCCESS:
      console.log(action.payload);
      action.payload.files.map((file: FileType) => {
        const optimisticFileCandidate = File.withId(file.id);
        if (optimisticFileCandidate) {
          const result = mergeObjects(File.withId(file.id), file);
          File?.withId(file.id)?.update(result);
        } else {
          if (file.id) {
            console.log("creating entry");
            File?.create(file);
          }
        }
        return true;
      })
      break;
    case REFRESH_FILE:
      if (action.payload.filteredRefreshObject) {
        action?.payload?.filteredRefreshObject.map((file: FileType) => {
          const refreshFileCandidate = File.withId(file.id);
          if (refreshFileCandidate) {
            file.last_refresh_time = Date.now() / 1000;
            const result = mergeObjects(File.withId(file.id), file)
            File?.withId(file.id)?.update(result);
          } else {
            if (file.id) {
              file.last_refresh_time = Date.now() / 1000;
              File?.create(file);
            }
          }
          return true;
        });
      }

      break;
    case REFRESH_FILE_SUCCESS:
      // console.log("refreshing files");
      action?.payload?.map((file: FileType) => {
        // console.log("remote file blur: " + file.lg_bl_url);
        // console.log("remote file hq: " + file.lg_hq_url);
        const refreshFileCandidate = File.withId(file.id);
        // console.log("local file blur: " + refreshFileCandidate.lg_bl_url);
        // console.log("local file hq: " + refreshFileCandidate.lg_hq_url);

        if(refreshFileCandidate){
          file.last_refresh_time = Date.now() / 1000;
          if(refreshFileCandidate.is_blurred === 0 && file.is_blurred === 1){
            refreshFileCandidate.delete();
            File?.create(file);
          } else if(refreshFileCandidate.is_blurred === 1 && file.is_blurred === 0){
            // refreshFileCandidate.delete();
            // File?.create(file);
            const result = mergeObjects(File.withId(file.id), file)
            File?.withId(file.id)?.update(result);
          } else {
            const result = mergeObjects(File.withId(file.id), file)
            File?.withId(file.id)?.update(result);
          }
        } else {
          if(file.id){
            file.last_refresh_time = Date.now() / 1000;
            File?.create(file);
          }
        }
        return true;
      });
      break;
    // case REFRESH_FILE_ERROR:
    //   const deleteFileCandidate = File.withId(action.payload.id);
    //   deleteFileCandidate && deleteFileCandidate?.delete();

    //   break;
    case REFRESH_PERSON:
      action?.payload?.filteredRefreshObject.map((file: PersonType) => {
        const refreshPersonCandidate = Person.withId(file.id);
        if (refreshPersonCandidate) {
          file.last_refresh_time = Date.now() / 1000;
          Person?.withId(file.id)?.update(file);
        } else {
          if (file.id) {
            file.last_refresh_time = Date.now() / 1000;
            Person?.create(file);
          }
        }
        return true;
      });
      break;
    case REFRESH_PERSON_SUCCESS:
      action.payload.map((file: PersonType) => {
        const refreshPersonCandidate = Person.withId(file.id);
        if (refreshPersonCandidate) {
          file.last_refresh_time = Date.now() / 1000;
          Person?.withId(file.id)?.update(file);
        } else {
          if (file.id) {
            file.last_refresh_time = Date.now() / 1000;
            Person?.create(file);
          }
        }
        return true;
      });
      break;
    case REFRESH_PERSON_ERROR:
      const deletePersonCandidate = Person.withId(action.payload.id);
      deletePersonCandidate && deletePersonCandidate?.delete();
      break;
  }

  return sess.state;
};

export default ormReducer;
