import React, { useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import store from "../../store";
import { firebaseConfig, webClient } from "../../constants/config";
import { setLoading, setModal } from "../../store/layout/actions";
import { firebaseLogin } from "../../store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
// import Doubloon3 from "../../components/common/Icons/Coins/Sprites/Doubloon3.svg";
// import { TUTORIAL_FOUR } from "../../constants/copy";


export type RootState = ReturnType<typeof store>;

// Configure Firebase.
firebase.initializeApp(firebaseConfig);

type FirebaseLoginPropsType = {
  redirect?: string,
}

const FirebaseLogin = (props: FirebaseLoginPropsType) => {
  const dispatch = useDispatch();

  const redirect = useSelector((state: RootState) => state.transactions.redirect);


  const callRedirectWhenLoggedIn = () => {
    const intervalId = setInterval(() => {
      if(redirect !== '' && redirect){
        window.location.href = redirect
      }
    },500)
    return () => clearInterval(intervalId);
  }

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",

    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        clientId: webClient,
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {
        firebase
          ?.auth()
          ?.currentUser?.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            dispatch(firebaseLogin(idToken));
            dispatch(setModal(false));
            dispatch(setLoading(true));
            callRedirectWhenLoggedIn();
            
          })
          .catch(function (error) {
            // Handle error
          });
        return false;
      },
    },
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        user !== null && dispatch(() => setModal(false));
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [dispatch, redirect]);

  return (
    <>
      {/* <div className="flex justify-center mx-auto h-32 w-32">
        <object type="image/svg+xml" data={Doubloon3}>
          
        </object>
      </div>
      <div className="py-5 text-md text-gray-500">{TUTORIAL_FOUR}</div> */}

      <div data-testid="firebase-button">

        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
          uiCallback={ui => ui.disableAutoSignIn()}
        />
      </div>
    </>
  );
};

export default FirebaseLogin;
