import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
// import { getInitials } from "../../services/utils";
// import RecentPerson from "./RecentPerson";
// import { getReputation } from "../../services/xpService";
import { PersonType } from "../../types";
import { PersonChip } from "../../components/PersonPanel";
import { loadPerson } from "../../store/people/actions";
import {
  // setActiveSlideout,
  setActiveLanding,
} from "../../store/layout/actions";
import SwipeableViews from "react-swipeable-views";

export type RootState = ReturnType<typeof store>;

const RecentPeople = () => {
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );

  const recent = useSelector((state: RootState) => state.people.recent);

  const [recentPeople, setRecentPeople] = useState<Array<PersonType>>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let recentPeople: Array<PersonType> = [];
    recent.map((personId: string) => {
      const person: PersonType = allPeople[personId];
      recentPeople.push(person);
      return true;
    });
    setRecentPeople(recentPeople);
  }, [recent, allPeople]);

  const handlePanelClick = (target_id: string) => {
    const refreshObject = {
      id: target_id,
      last_refresh_time: 1,
    };
    dispatch(loadPerson([refreshObject]));
    dispatch(setActiveLanding("TARGET_PROFILE"));
  };

  const springConfig = {
    duration: "0.3s",
    delay: "0s",
    easeFunction: "ease-in",
    tension: 100,
    friction: 10,
  };

  const [adjustedIndex, setadjustedIndex] = useState(0);

  const swipe = (idx: number) => {
    adjustedIndex < idx ? setadjustedIndex(idx) : setadjustedIndex(idx);
  };

  return (
    <div className="flex mx-auto px-2 justify-center w-full ">
      <SwipeableViews
        index={adjustedIndex}
        onChangeIndex={swipe}
        springConfig={springConfig}
        resistance={true}
        enableMouseEvents
        style={{ maxWidth: "100%" }}
        slideStyle={{ maxWidth: "70%", minWidth: "50%" }}
        className="px-1 sm:pr-40 min-w-full"
        disabled={recentPeople && recentPeople?.length <= 1}
      >
        {recentPeople.map((person: any, idx: any) => {
          return (
            <div
              key={idx}
              hidden={
                idx - adjustedIndex > 1 || adjustedIndex - idx  > 1
              }
              style={Object.assign({})}
              className=" w-full flex px-1"
              >
              {" "}
              <button
                onClick={() => handlePanelClick(person.id)}
                className="flex w-full "
              >
                <PersonChip personId={person?.id} />
              </button>
            </div>
          );
        })}
      </SwipeableViews>
    </div>
  );
};

export default RecentPeople;
