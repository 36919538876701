export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_WEB_URL = process.env.REACT_APP_BASE_WEB_URL;

export const USER_STATUS_ENDPOINT = BASE_URL + '/user_status';
export const PEOPLE_ENDPOINT = BASE_URL + '/person';
export const FIREBASE_LOGIN_ENDPOINT = BASE_URL + '/firebase_login';
export const VALIDATE_USERNAME_ENDPOINT = BASE_URL + '/validate_username';
export const TRANSACTION_ENDPOINT = BASE_URL + '/payout';
export const VALIDATE_ASSETNAME_ENDPOINT = BASE_URL + '/validate_asset_name';
export const TIP_ENDPOINT = BASE_URL + '/tip';
export const BUY_ENDPOINT = BASE_URL + '/buy';
export const TRANSACTION_LOG_ENDPOINT = BASE_URL + '/transactions';
export const BALANCES_ENDPOINT = BASE_URL + '/balances';
export const PING_ENDPOINT = BASE_URL + '/ping';
export const SELF_ENDPOINT = BASE_URL + '/self';
export const PUBLIC_IMAGE_ENDPOINT = BASE_URL + '/publicimage';
export const AUTH_FEED_ENDPOINT = BASE_URL + '/authfeed';
export const PUBLIC_FEED_ENDPOINT = BASE_URL + '/publicfeed';
export const IMAGE_PASS_ENDPOINT = BASE_URL + '/imagepass';
export const AUTH_IMAGE_ENDPOINT = BASE_URL + '/authimage';
export const POST_IMAGE_ENDPOINT = BASE_URL + '/postimage';
export const DESTROY_IMAGE_ENDPOINT = BASE_URL + '/destroyimage';

export const FEATURED_ENDPOINT = BASE_URL + '/featured';
export const SEARCH_ENDPOINT = BASE_URL + '/search';