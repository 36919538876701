import React, { useState, useEffect } from "react";
import { getImage } from "../../../services/utils";

type ImagePropsType = {
  url: string;
  className?: string;
  onLoad?: Function;
  blUrl: string;
  hidden?: boolean;
};

const Image = (props: ImagePropsType) => {
  const { url, className, onLoad, blUrl, hidden } = props;
  const [loadingStatus, setLoadingStatus] = useState("START");
  const [cachedImage, setCachedImage] = useState<any>("");

  useEffect(() => {
    let unmounted = false;

    const autoBlur = async () => {
      if (blUrl.substring(0, 5) === "blob:") {
        if (!unmounted) {
          setCachedImage(blUrl);
          setLoadingStatus("FINISH");
        }
      } else {
        loadingStatus === "START" &&
          !unmounted &&
          getImage(blUrl).then((result) => {
            if(!unmounted){
              setCachedImage(result?.url);
              setLoadingStatus("BLUR");
            }
          });
        if (url.substring(0, 5) === "blob:") {
          loadingStatus === "BLUR" &&
            !hidden &&
            !unmounted &&
            setCachedImage(url);
        } else {
          loadingStatus === "BLUR" &&
            !hidden &&
            !unmounted &&
            getImage(url).then((result) => {
              if (!unmounted) {
                setCachedImage(result?.url);
                setLoadingStatus("FINISH");
              }
            });
        }
      }
    };

    autoBlur();

    return () => {
      unmounted = true;
    };
  }, [url, blUrl, hidden, loadingStatus]);

  return (
    <div className="flex block relative rounded-xl">
      {loadingStatus === "START" ? (
        <div className="animate-pulse bg-gray-200 block relative mx-auto border-2 border-gray-300 border-dashed rounded-xl hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"></div>
      ) : (
        <>
          <img
            src={cachedImage}
            alt=""
            className={className + " "}
            onLoad={() => {
              if (onLoad) {
                onLoad(true);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export default Image;
