import { put, call } from 'redux-saga/effects';
// import { addSelfImage } from '../people/actions'
import { PEOPLE_SEARCH_SUCCESS } from '../people/constants'
import { searchPeople } from '../../services/authService';

export function* peopleSearchSaga(action) {
    let results = yield call(searchPeople, action.payload)
    if(!action.payload){
        results = []
    }
    yield put({
        type: PEOPLE_SEARCH_SUCCESS,
        payload: results
    })
}

