import {
  CashIcon,
  FacebookSvg,
  InstagramSvg,
  Cog,
  ParrotSvg,
  User
} from "../common/Icons";
import { Button } from "../common/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  setSlideout,
  setActiveSlideout,
  setModal,
  setActiveModal,
  setActiveLanding,
} from "../../store/layout/actions";
import store from "../../store";
import { XpMeter } from "../XpMeter";
import { getReputation } from "../../services/xpService";
import { AssetType } from '../../types';
import { numFormatter } from "../../services/utils";
export type RootState = ReturnType<typeof store>;

const TopNav = () => {
  const spinning = useSelector((state: RootState) => state.layout.isLoading);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const allAssets: { [id: string]: AssetType } = useSelector((state: any) => state.orm.Asset.itemsById);
  const xpAsset = allAssets['XP'];
  const xpBalance = xpAsset?.qty;
  const dbAsset = allAssets['DBLN'];
  const dbBalance = dbAsset?.qty;

  const reputation = getReputation(xpBalance);

  const dispatch = useDispatch();

  const handleProfileButton = () => {
    if (isLoggedIn) {
      dispatch(setSlideout(true));
      dispatch(setActiveSlideout("SELF_PROFILE"));
    } else {
      dispatch(setModal(true));
      dispatch(setActiveModal("LOGIN"));
    }
  };

  const handleWalletButton = () => {
    if (isLoggedIn) {
      dispatch(setActiveLanding("WALLET"));
    } else {
      dispatch(setModal(true));
      dispatch(setActiveModal("LOGIN"));
    }
  };

  const handleUserButton = () => {
    if (isLoggedIn) {
      dispatch(setActiveLanding("SELF_PROFILE"))
    }
  }

  const handleFeedButton = () => {
    dispatch(setActiveLanding("FEED"));
  };

  return (
    <div className="">
      <div className="flex justify-center items-center">
          <div className="text-primary-700 pr-2 justify-self-center">
            <button
              onClick={() => handleFeedButton()}
              className="bg-white rounded-full ring-2 ring-white"
            ><ParrotSvg /> </button>
          </div>
          <div className="w-full ">
            <div className="text-gray-700 dark:text-gray-400 font-bold text-xl">Libertalia</div>
            {isLoggedIn && (
              <>
               <div className="text-gray-700 dark:text-gray-400 text-xs">
               {numFormatter(dbBalance)} Doubloons
             </div>

             <div className="text-gray-700 dark:text-gray-400 text-xs">
                {xpBalance ? numFormatter(xpBalance) : 0} / {numFormatter(reputation.levelCeiling)} XP
              </div>
             </>
            )}
          </div>

        <div className="md:flex-grow flex-grow-0"></div>
        <div className="flex-grow md:flex-grow-0"></div>

        {isLoggedIn ? (
          <>
            <div className="text-primary-700 dark:text-primary-300 pr-2 ">
              <Button
                onClick={() => handleUserButton()}
                variant="round"
                Icon={User}
                spinning={spinning}
              />
            </div>
            <div className="text-primary-700 pr-2 ">
              <Button
                onClick={() => handleWalletButton()}
                variant="round"
                Icon={CashIcon}
                spinning={spinning}
              />
            </div>
            <div className="text-primary-700">
              <Button
                onClick={() => handleProfileButton()}
                variant="round"
                Icon={Cog}
                spinning={spinning}
              />
            </div>
          </>
        ) : (
          <>
            <div className="text-gray-900 pr-2 ">
              <Button
                onClick={() => window.location.assign('https://facebook.com/libertaliaapp')}
                variant="gray-round"
                Icon={FacebookSvg}
                spinning={spinning}
              />
            </div>
            <div className="text-gray-900 pr-2">
              <Button
                onClick={() => window.location.assign('https://instagram.com/libertaliaapp')}
                variant="gray-round"
                Icon={InstagramSvg}
                spinning={spinning}
              />
            </div>
          </>
        )}
      </div>
      <div className="">
        <XpMeter amount={xpBalance} />
      </div>
    </div>
  );
};

export default TopNav;
