import axios from "axios";
import { USER_EXISTS } from "../constants/errors";
import { deleteLocalDatabase } from "./storageService";
import {
  VALIDATE_USERNAME_ENDPOINT,
  VALIDATE_ASSETNAME_ENDPOINT,
  SEARCH_ENDPOINT,
} from "../constants/endpoints";
import { unregister } from "../serviceWorkerRegistration";

export const checkout = async (payload: any) => {
  return axios("https://dev-api.libertalia.app/api/checkout", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: payload,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const validateUsername = async (querystring: string) => {
  const queryObject = {
    searchquery: querystring,
  };
  return axios(VALIDATE_USERNAME_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: queryObject,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(USER_EXISTS);
    });
};

export const searchPeople = async (querystring: string) => {
  const queryObject = {
    searchquery: querystring,
  };
  return axios(SEARCH_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: queryObject,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(USER_EXISTS);
    });
};

export const validateAssetName = async (querystring: string) => {
  const queryObject = {
    searchquery: querystring,
  };
  return axios(VALIDATE_ASSETNAME_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: queryObject,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(USER_EXISTS);
    });
};

export const logoutUser = async () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  unregister();
  sessionStorage.clear();
  localStorage.clear();
  deleteLocalDatabase("firebaseLocalStorageDb").then(result => {
    return true;
  })
};
