import { FileType } from "../../types";

export interface FileReducerType {
    activeReps: Array<number>;
    activeFiles: Array<string>;
    unLockTarget: FileType;
    selectedFile: string;
    progress: number;
    selectedUrl: string,
    selectedField: string,
    filesPendingUpload: Array<FileType>,
    filesLatestUpload: Array<FileType>,
}

export const FILE_ACTIVE_REPS = 'FILE_ACTIVE_REPS';
export const FILE_ADD_ACTIVE_FILE = 'FILE_ADD_ACTIVE_FILE';
export const FILE_CLEAR_ACTIVE_FILES = 'FILE_CLEAR_ACTIVE_FILES';
export const FILE_ADD_IMAGE_PASS = 'FILE_ADD_IMAGE_PASS';
export const FILE_ADD_IMAGE_PASS_SUCCESS = 'FILE_ADD_IMAGE_PASS_SUCCESS';
export const FILE_ADD_IMAGE_PASS_ERROR = 'FILE_ADD_IMAGE_PASS_ERROR';
export const FILE_CLEAR_UNLOCK_CANDIDATE = 'FILE_CLEAR_UNLOCK_CANDIDATE';
export const FILE_SELECT_FILE = 'FILE_SELECT_FILE';

export const FILE_UPDATE_PROGRESS = "FILE_UPDATE_PROGRESS";
export const FILE_REQUEST_UPLOADED_FILES = "FILE_REQUEST_UPLOADED_FILES";
export const FILE_UPLOAD_FILE_REQUEST = 'FILE_UPLOAD_FILE_REQUEST';

export const FILE_UPDATE_PENDING = 'FILE_UPDATE_PENDING'