import {
    LAYOUT_SET_ACTIVE_SLIDEOUT,
    LAYOUT_SET_SLIDEOUT,
    LAYOUT_SET_ACTIVE_TOAST,
    LAYOUT_SET_TOAST,
    LAYOUT_SET_MODAL,
    LAYOUT_SET_ACTIVE_MODAL,
    LAYOUT_SET_LOADING,
    LAYOUT_SET_LOADING_STATE,
    LAYOUT_SET_PARAMETER,
    LAYOUT_HAS_PARAMETER,
    LAYOUT_SET_ACTIVE_LANDING,
    LAYOUT_SET_ACTIVE_PHOTO,
    LAYOUT_SET_ACTIVE_CONFIRMATION,
    LAYOUT_SET_ACTIVE_BLUR_PHOTO,
  } from './constants';

  export const setSlideout = (isOpen: boolean) => (dispatch: any) =>{
    dispatch({type: LAYOUT_SET_SLIDEOUT, payload: isOpen});
    return true;
  }

  export const setActiveSlideout = (activeSlideout: string) => (dispatch: any) =>{
    dispatch({type: LAYOUT_SET_ACTIVE_SLIDEOUT, payload: activeSlideout});
    return true;
  }

  export const setToast = () => (dispatch: any) =>{
    dispatch({type: LAYOUT_SET_TOAST, payload: true})
    const timer = setTimeout(() => {
      dispatch({type: LAYOUT_SET_TOAST, payload: false})
    }, 2000); // delay

    return () => {
      clearTimeout(timer);
    };
  }

  export const setActiveToast = (activeToast: string) => (dispatch: any) =>{
    dispatch({type: LAYOUT_SET_ACTIVE_TOAST, payload: activeToast});
    return true;
  }
  
  export const setModal = (isOpen: boolean) => (dispatch: any) =>{
    dispatch({type: LAYOUT_SET_MODAL, payload: isOpen});
    return true;
  }

  export const setActiveModal = (activeModal: string) => (dispatch: any) =>{
    dispatch({type: LAYOUT_SET_ACTIVE_MODAL, payload: activeModal});
    return true;
  }

  export const setLoading = (isLoading: boolean) => (dispatch: any) => {
    dispatch({type: LAYOUT_SET_LOADING, payload: isLoading});
    return true;
  }

  export const setLoadingState = (loadingState: string) => (dispatch: any) => {
    dispatch({type: LAYOUT_SET_LOADING_STATE, payload: loadingState});
    return true;
  }

  export const setHasParameter = (hasParameter: boolean) => (dispatch: any) => {
    dispatch({type: LAYOUT_HAS_PARAMETER, payload: hasParameter});
    return true;
  }

  export const setParameter = (parameter: string) => (dispatch: any) => {
    dispatch({type: LAYOUT_SET_PARAMETER, payload: parameter});
    return true;
  }

  export const setActiveLanding = (landing: string) => (dispatch: any) => {
    dispatch({type: LAYOUT_SET_ACTIVE_LANDING, payload: landing});
    return true;
  }

  export const setActivePhoto = (photo: string) => (dispatch: any) => {
    dispatch({type: LAYOUT_SET_ACTIVE_PHOTO, payload: photo});
    return true;
  }

  export const setActiveBlurPhoto = (photo: string) => (dispatch: any) => {
    dispatch({type: LAYOUT_SET_ACTIVE_BLUR_PHOTO, payload: photo});
    return true;
  }

  export const setActiveConfirmation = (confirmation: string) => (dispatch: any) => {
    dispatch({type: LAYOUT_SET_ACTIVE_CONFIRMATION, payload: confirmation});
    return true;
  }