import { BASE_WEB_URL } from "../../constants/endpoints";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import Doubloon3 from "../../components/common/Icons/Coins/Sprites/Doubloon3.svg";
import { Button } from "../common/Button";
import confetti from 'canvas-confetti'
import { useEffect } from "react";
import { useParams } from "react-router-dom";
// import Level from "./LevelUp";
import AssetPurchase from './AssetPurchase';
import {loadSelf} from '../../store/people/actions'
import { PersonType } from '../../types';
import {
  setAssetBalance,
  setSelfDoubloonBalance,
  setXpAssetBalance,
} from "../../store/people/actions";
import {
  addConfirmedTransaction,
  exchangeActivate,
  tip,
  loadConfirmedTransactions,
} from "../../store/transactions/actions";
import {
  setSlideout,
  setModal,
  setActiveModal,
  // setLoading,
  setToast,
  setActiveToast,
} from "../../store/layout/actions";

export type RootState = ReturnType<typeof store>;

const DoubloonPurchaseConfirmation = () => {
  const dispatch = useDispatch();
  const selectedTransaction = useSelector(
    (state: RootState) => state.transactions.buyTransaction
  );
  const token = useSelector(
    (state: RootState) => state.auth.token
  );
  const activePersonId = useSelector(
    (state: RootState) => state.people.activePerson
  )
  const allPeople: { [id: string]: PersonType } = useSelector((state: any) => state.orm.Person.itemsById);
  const activePerson = allPeople[activePersonId];
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const self = useSelector(
    (state: RootState) => state.people.self
  )

  const utcSeconds = selectedTransaction?.provisioned_at;
  let transactionTime = new Date(0);
  utcSeconds && transactionTime.setUTCSeconds(utcSeconds);

  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  useEffect(() => {
    setInterval(() => {
      confetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 }
      });
    },3000)
  },[])

  useEffect(() => {
    token && dispatch(loadSelf(token));
  },[token, dispatch])

  useEffect(() => {
    (self?.id !== '0') && handleTip(selectedTransaction?.tipTransaction?.asset_qty ? selectedTransaction?.tipTransaction?.asset_qty : 0);
  },[self.id]) // eslint-disable-line react-hooks/exhaustive-deps


  type IdType = {
    target_id?: string;
  };
  const { target_id } = useParams<IdType>();

  const handleButtonClick = () => {
    
    BASE_WEB_URL && window.location.assign(BASE_WEB_URL + '/' + target_id);
  }

  const handleTip = (amount: number) => {
    const tipObject = selectedTransaction.tipTransaction;

    if (isLoggedIn && tipObject) {
      const userCurrencyBalance = activePerson?.asset_balance;
      const xpBalance = activePerson?.xp_asset_balance;
      const selfDoubloonBalance = self.doubloonBalance;
      let userAssetBalance = 0;
      userCurrencyBalance && (userAssetBalance = userCurrencyBalance);
      dispatch(setXpAssetBalance(xpBalance + amount));
      dispatch(setSelfDoubloonBalance(selfDoubloonBalance - amount));
      token && dispatch(tip(tipObject, token));
      dispatch(addConfirmedTransaction(tipObject));
      dispatch(exchangeActivate((selfDoubloonBalance - amount).toString()));
      dispatch(setAssetBalance(userAssetBalance + amount));
      token && dispatch(loadConfirmedTransactions(token));
      // dispatch(setLoading(false));
      dispatch(setActiveToast("TIP"));
      dispatch(setToast());
    } else {
      dispatch(setSlideout(false));
      dispatch(setActiveModal("LOGIN"));
      dispatch(setModal(true));
    }
  };

  return (
    <div className="flex justify-center h-screen">
      <div className="grid grid-rows-10 flex items-center">
        <div className="row-span-1"></div>
        <div className="flex row-span-1 justify-center mx-auto h-32 w-32">
          <object type="image/svg+xml" data={Doubloon3}>

          </object>
        </div>
        <div className="row-span-4">
        {/* <Level 
          name="Libertalia"
          reputation="friendly"
        /> */}
        <AssetPurchase 
          qty={
            selectedTransaction?.dblnQty &&
            Math.abs(selectedTransaction?.dblnQty)
          }
          txid={selectedTransaction?.id}
          date={transactionTime.toString()}
          assetName="Doubloons"
        />
        </div>
       
        <div className="flex row-span-1 items-start text-gray-700 text-xs mx-auto flex justify-center" >
          <Button label="Huzzah!" onClick={() => handleButtonClick()}/>
        </div>
        <div className="row-span-3"></div>
      </div>
    </div>
  );
};

export default DoubloonPurchaseConfirmation;
