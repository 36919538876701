import QRCode from "qrcode.react";
import { BASE_WEB_URL } from "../../constants/endpoints";
import { useSelector } from "react-redux";
import store from "../../store";
// import { setActiveToast, setModal, setToast } from "../../store/layout/actions";
import { QRIconOverlay } from "../common/Icons";
// import { Image } from "../common/Image";
// import moment from "moment";

export type RootState = ReturnType<typeof store>;

const QR = () => {
  const selfPhoto = useSelector(
    (state: RootState) => state.people.self.photo_url
  );
  // const selfName = useSelector((state: RootState) => state.people.self.name);
  const selectedTransaction = useSelector(
    (state: RootState) => state.transactions.selectedTransaction
  );
  // const dispatch = useDispatch();

  const utcSeconds = selectedTransaction?.chain_confirmed_at;
  let transactionTime = new Date(0);
  utcSeconds && transactionTime.setUTCSeconds(utcSeconds);

  let transactionColor = "text-green-700";
  // let accentColor = "bg-green-100";
  let strf = "Received From";
  if (selectedTransaction) {
    if (selectedTransaction.asset_qty < 0) {
      transactionColor = "text-red-700";
      // accentColor = "bg-red-100";
      strf = "Sent To";
    }
  }

  // copies link to clipboard
  // const handleQrButton = () => {
  //   navigator?.clipboard?.writeText(BASE_WEB_URL + "/");
  //   dispatch(setModal(false));
  //   dispatch(setActiveToast("LINK"));
  //   dispatch(setToast());
  // };

  return (
    <div className="grid grid-rows-3">
      <div className="flex justify-center max-h-20">
        <div className="absolute ml-12">
          {selfPhoto && (
            <img
              src={selfPhoto}
              className="flex h-20 w-20 rounded-full ring-1 ring-gray-700"
              alt=""
            />
          )}
        </div>
        <div className="absolute">
          {selectedTransaction?.target_photo_url && (
            <img
              src={selectedTransaction.target_photo_url}
              className="flex h-20 w-20 rounded-full ring-1 ring-gray-700"
              alt=""
            />
          )}
        </div>

        <div className="absolute w-20 h-20 rounded-full bg-white ring-1 text-gray-700 ring-gray-700 -ml-12">
          {/* <button
            onClick={() => handleQrButton()}
            className=""
          > */}
          <QRIconOverlay>
            <QRCode
              value={BASE_WEB_URL + "/"}
              data-testid="qr-image"
              renderAs="svg"
              fgColor="#1A202C"
              level="H"
            />
          </QRIconOverlay>
            {/* </button> */}
        </div>
      </div>
      <div className="h-36">
        <div
          className={
            "text-8xl font-bold mx-auto flex justify-center " + transactionColor
          }
        >
          {selectedTransaction?.asset_qty &&
            Math.abs(selectedTransaction?.asset_qty)}
        </div>
        <div className="text-gray-700 text-4xl font-thin mx-auto flex justify-center uppercase -mt-3">
          {selectedTransaction?.asset_name}
        </div>
        <div className="text-gray-700 text-lg font-bold mx-auto flex justify-center">
          {selectedTransaction?.from_name}
        </div>
        <div className="text-gray-700 text-lg font-thin mx-auto flex justify-center pt-4" >
          {strf}
        </div>
        <div className="text-gray-700 text-4xl font-bold mx-auto flex justify-center">
          {selectedTransaction?.target_name}
        </div>
        <div
          className="text-gray-700 mx-auto flex justify-center pt-4 text-lg font-thin"
        >
          Blockchain Confirmation
        </div>
        <div
          className="text-gray-700 mx-auto flex justify-center"
          style={{ fontSize: "0.5rem" }}
        >
          {selectedTransaction?.chain_txid}
        </div>
        <div
          className="text-gray-700 text-xs mx-auto flex justify-center"
          style={{ fontSize: "0.5rem" }}
        >
          {transactionTime.toString()}
        </div>
      </div>
    </div>
  );
};

export default QR;
