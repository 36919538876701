import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import store from "../src/store";
import logger from "redux-logger";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./store/sagas";

const sagaMiddleware = createSagaMiddleware();
const newOfflineConfig = {
    ...offlineConfig,
    persistOptions: {
      // blacklist: ["layout"],
      whitelist: ["orm","auth"]
    },
}
// const offlineStore = offline(newOfflineConfig);


const configureStore = () => {
  // const middleware = [sagaMiddleware, thunk, logger];

    const appStore =
      process.env.REACT_APP_BUILD === "PROD"
        ? createStore(
            store,
            {},
            compose(
              applyMiddleware(thunk),
              offline(newOfflineConfig),
              applyMiddleware(sagaMiddleware),
              applyMiddleware(logger)
            )
          )
        : createStore(
            store,
            {},
            compose(
              applyMiddleware(thunk),
              offline(newOfflineConfig),
              applyMiddleware(sagaMiddleware),
              applyMiddleware(logger)
            )
          );
  
    sagaMiddleware.run(rootSaga);
    return appStore;
  };

const newstore: any = configureStore();

export default newstore;