import { Reducer } from "redux";
// import merge from "lodash/merge";
// import { normalizeArrayByKey } from "../../services/utils";
import {
  PEOPLE_LOAD,
  // PEOPLE_LOAD_SUCCESS,
  // PEOPLE_LOAD_ERROR,
  PEOPLE_SELF_LOAD,
  PEOPLE_SELF_LOAD_ERROR,
  PEOPLE_SELF_LOAD_SUCCESS,
  PEOPLE_UPDATE_SUCCESS,
  PEOPLE_UPDATE,
  PEOPLE_UPDATE_ERROR,
  PEOPLE_SELF_ACTIVATE,
  PEOPLE_SELF_UPDATE_BALANCE,
  PEOPLE_SELF_XP_UPDATE_BALANCE,
  // PEOPLE_UPDATE_ASSET_BALANCE,
  PeopleReducerTypes,
  PEOPLE_UPDATE_VALIDATION_ERROR,
  PEOPLE_UPDATE_INVALID_FIELDS,
  PEOPLE_ACTIVATE,
  // PEOPLE_UPDATE_XP_ASSET_BALANCE,
  PEOPLE_GET_FEATURED_SUCCESS,
  PEOPLE_ADD_IMAGE,
  PEOPLE_SEARCH_SUCCESS,
} from "./constants";

const defaultState = {
  recent: [],
  featured: [],
  isPeopleLoading: false,
  activePerson: "",
  self: {
    id: "0",
    name: "",
    username: "",
    balances: [{ name: "", id: "DBLN", qty: 0, last_refresh_time: 1 }],
    blockchain_address: "",
    doubloonBalance: 0,
    xp: 0,
    asset_name: "",
    initials: "",
    email: "",
    title: "",
    photo_url: "",
    stripe_account_link: "",
    payouts_enabled: false,
    facebook_link: "",
    instagram_link: "",
    soundcloud_link: "",
    tiktok_link: "",
    validationError: "",
    invalidFields: [""],
    upcomingImages: [""],
    publishedImages: [""]
  },
  searchResults: [],
};

const authReducer: Reducer<PeopleReducerTypes> = (
  state = defaultState,
  action: { type: String; payload?: any }
) => {
  switch (action.type) {
    case PEOPLE_SEARCH_SUCCESS: {
      return {
        ...state,
        searchResults: action.payload
      }
    }
    case PEOPLE_ADD_IMAGE: {
      let idAlreadyExists = state.self.upcomingImages && state.self.upcomingImages.includes(action.payload);
      // make a copy of the existing array
      let chosenIds = state.self.upcomingImages ? state.self.upcomingImages.slice() : []
      console.log(chosenIds);

      if(idAlreadyExists) {
          // chosenIds = [...chosenIds]   
          console.log('skipped: ' + chosenIds)           
      }     
      else {
          // modify the COPY, not the original
          chosenIds.push(action.payload);            
      }   
      return {
      ...state,
      self: {
        ...state.self,
        images: chosenIds,
        upcomingImages: chosenIds,
      }
    }
    }
    case PEOPLE_SELF_LOAD: {
      return {
        ...state,
        isPeopleLoading: false,
      };
    }
    case PEOPLE_SELF_LOAD_SUCCESS: {
      return {
        ...state,
        isPeopleLoading: false,
        self: {
          ...state.self, 
          ...action.payload.success.user.profile,
          subscriptions: action.payload.success.user.subscriptions,
          images: action.payload.success.user.images,
          publishedImages: action.payload.success.user.publishedImages,
          upcomingImages: action.payload.success.user.upcomingImages,
        },
      };
    }
    case PEOPLE_SELF_LOAD_ERROR: {
      return {
        ...state,
        isPeopleLoading: false,
        self: {
          id: "0",
          name: "",
          username: "",
          balances: [{ name: "", id: "DBLN", qty: 0 }],
          blockchain_address: "",
          doubloonBalance: 0,
          initials: "",
          email: "",
          title: "",
          photo_url: "",
          stripe_account_link: null,
          payouts_enabled: false,
          facebook_link: "",
          instagram_link: "",
          soundcloud_link: "",
          tiktok_link: "",
          validationErrror: "",
          invalidFields: ["NONE"],
        },
      };
    }
    case PEOPLE_ACTIVATE: {
      return {
        ...state,
        activePerson: action.payload,
      };
    }
    case PEOPLE_LOAD: {
      let payload = action.payload.refreshObject[0];
      let recentArray = [...state.recent];
      let payloadId = state.activePerson;

      if (payload) {
        payloadId = payload.id;
        if (!state.recent.includes(payload.id)) {
          // recentArray.push(payload.id)
          recentArray = [payload.id, ...state.recent];
          if (recentArray.length > 5) {
            recentArray.pop();
          }
        }
      }
      
      return {
        ...state,
        isPeopleLoading: false,
        activePerson: payloadId,
        recent: recentArray,
      };
    }
    case PEOPLE_UPDATE: {
      return {
        ...state,
        isPeopleLoading: false,
      };
    }
    case PEOPLE_UPDATE_SUCCESS: {
      return {
        ...state,
        self: {
          ...state.self, ...action.payload
        },
      }
    }
    case PEOPLE_UPDATE_ERROR: {
      return {
        ...state,
        isPeopleLoading: false,
      };
    }
    case PEOPLE_GET_FEATURED_SUCCESS: {
      return {
        ...state,
        featured: action.payload
      }
    }
    case PEOPLE_SELF_ACTIVATE: {
      return {
        ...state,
        self: action.payload,
      };
    }
    case PEOPLE_SELF_UPDATE_BALANCE: {
      return {
        ...state,
        self: {
          ...state.self,
          doubloonBalance: action.payload,
        },
      };
    }
    case PEOPLE_SELF_XP_UPDATE_BALANCE: {
      return {
        ...state,
        self: {
          ...state.self,
          xp: action.payload,
        },
      };
    }
    case PEOPLE_UPDATE_VALIDATION_ERROR: {
      return {
        ...state,
        self: {
          ...state.self,
          validationError: action.payload,
        },
      };
    }
    case PEOPLE_UPDATE_INVALID_FIELDS: {
      return {
        ...state,
        self: {
          ...state.self,
          invalidFields: [...action.payload],
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
