import { put } from 'redux-saga/effects';
// import { addSelfImage } from '../people/actions'
import { PEOPLE_ADD_IMAGE } from '../people/constants'

export function* optimisticUploadSaga(action) {
    console.log(action);
    const file = action.payload.files[0];
        if(file && file.id){
            yield put({
                type: PEOPLE_ADD_IMAGE,
                payload: file.id
              })
        }
}
