import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import store from "../../store";

export type RootState = ReturnType<typeof store>;

const Redirect = (props: PropsFromRedux) => {
  const { isLoggedIn, stripe_account_link } = props;

  useEffect(() => {
        !isLoggedIn && stripe_account_link && window.location.assign(stripe_account_link);
  }, [isLoggedIn, stripe_account_link]);


  return <></>;
};

const mapStateToProps = (state: RootState) => ({
    isLoggedIn: state.auth.isLoggedIn,
    stripe_account_link: state.people.self.stripe_account_link,
});

const mapDispatchToProps = (dispatch: Function) => ({
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Redirect);
