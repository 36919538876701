import {
  CashIcon,
  Cog,
  ParrotSvg,
  User,
} from "../common/Icons";
import { Button } from "../common/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  setSlideout,
  setActiveSlideout,
  setModal,
  setActiveModal,
  setActiveLanding,
} from "../../store/layout/actions";
import store from "../../store";

export type RootState = ReturnType<typeof store>;

const BottomNav = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const isSlideoutVisible = useSelector(
    (state: RootState) => state.layout.isSlideoutVisible
  );

  const dispatch = useDispatch();

  const handleProfileButton = () => {
    // isSlideoutVisible && dispatch(setSlideout(false));
    // if (!isSlideoutVisible) {
      if (isLoggedIn) {
        dispatch(setSlideout(!isSlideoutVisible));
        dispatch(setActiveSlideout("SELF_PROFILE"));
      } else {
        dispatch(setModal(true));
        dispatch(setActiveModal("LOGIN"));
      }
    // }
  };

  const handleWalletButton = () => {
    if (isLoggedIn) {
      dispatch(setActiveLanding("WALLET"));
    } else {
      dispatch(setModal(true));
      dispatch(setActiveModal("LOGIN"));
    }
  };

  const handleUserButton = () => {
    if (isLoggedIn) {
      dispatch(setActiveLanding("SELF_PROFILE"));
    }
  };

  const handleFeedButton = () => {
    dispatch(setActiveLanding("FEED"));
  };

  return (
    <div className="w-full mt-20 flex dark:bg-gray-900">
      <section
        id="bottom-navigation"
        className="sm:hidden block fixed inset-x-0 bottom-0 pb-4 z-10 bg-primary-700 shadow"
      >
        <div
          id="tabs"
          className="flex justify-center items-center grid-cols-4 grid"
        >
          <div className="text-white pr-2 w-full justify-center inline-block text-center pt-2 pb-1 -mt-7">
            <Button
              onClick={() => handleFeedButton()}
              variant="bottom-round-lg"
              Icon={ParrotSvg}
              spinning={false}
            />
            <span className="pt-1 tab tab-explore block text-xs">Home</span>
          </div>{" "}
          <div className="text-white pr-2 w-full justify-center inline-block text-center pt-2 pb-1">
            <Button
              onClick={() => handleUserButton()}
              variant="bottom-round"
              Icon={User}
              spinning={false}
            />
            <span className="pt-1 tab tab-explore block text-xs">Profile</span>
          </div>
          <div className="text-white pr-2 w-full justify-center inline-block text-center pt-2 pb-1">
            <Button
              onClick={() => handleWalletButton()}
              variant="bottom-round"
              Icon={CashIcon}
              spinning={false}
            />
            <span className="pt-1 tab tab-explore block text-xs">Wallet</span>
          </div>
          <div className="text-white pr-2 w-full justify-center inline-block text-center pt-2 pb-1">
            <Button
              onClick={() => handleProfileButton()}
              variant="bottom-round"
              Icon={Cog}
              spinning={false}
            />
            <span className="pt-1 tab tab-explore block text-xs">Settings</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BottomNav;
