import Fuse from "fuse.js";
import localforage from "localforage";
import { addDays, getISODay } from "date-fns";

export const getClosestDayOfLastWeek = (fromDate = new Date()) => {
  const offsetDays = -7 - (getISODay(fromDate) - 5);
  const lastFriday = addDays(fromDate, offsetDays);
  return lastFriday;
};

export const mergeObjects = (obj1: any, obj2: any) => {
  let omitNull = (obj: any) => {
    Object.keys(obj).filter(k => (obj[k] === null) || (obj[k] === "")).forEach(k => delete(obj[k]))
    return obj
  }
  const result = { ...omitNull(obj1), ...omitNull(obj2) }
  return result
}

export const numFormatter = (num: number) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 999999 && num < 999999999) {
    return (num / 1000000).toFixed(2) + "M"; // convert to M for number from > 1 million
  } else if (num > 999999999) {
    return (num / 1000000000).toFixed(2) + "B"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

export const paginate = (
  totalItems: number,
  currentPage: number = 1,
  pageSize: number = 9,
  maxPages: number = 10
) => {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
      currentPage = 1;
  } else if (currentPage > totalPages) {
      currentPage = totalPages;
  }

  let startPage: number, endPage: number;
  if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
  } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
      } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
      }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

  // return object with all pager properties required by the view
  return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
  };
}

export const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0)?.toUpperCase() + string.slice(1).toLowerCase();
}

export const getInitials = (name: string) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials: Array<any> = [name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const classNames = (...classes: Array<string>) => {
  return classes.filter(Boolean).join(" ");
};

export const randomWithProbability = () => {
  var notRandomNumbers = [1, 1, 1, 1, 2, 2, 2, 3, 3, 4];
  var idx = Math.floor(Math.random() * notRandomNumbers.length);
  return notRandomNumbers[idx];
};

export const randomToken = (len: number) => {
  function dec2hex(dec: any) {
    return dec.toString(16).padStart(2, "0");
  }

  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const search = (
  query: string,
  unfiltered: Array<any>,
  keys: Array<string>
) => {
  const fuse = new Fuse(unfiltered, {
    keys: keys,
    threshold: 0.2,
  });
  let payload = [];
  if (!query) {
    payload = unfiltered;
  } else {
    const result = fuse.search(query);
    const matches: Array<any> = [];
    result.forEach(({ item }: any) => {
      matches.push(item);
    });
    payload = matches;
  }
  return payload;
};

// Compare two json objects and return an object with only the diffs
export const compareJSON = (obj1: any, obj2: any) => {
  let ret: any = {};
  for (var i in obj2) {
    if (!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
      ret[i] = obj2[i];
    }
  }
  return ret;
};

export const getImage = async (url: string) => {
    return localforage.getItem(url).then(async(result) => {
      if (result) {
        const url = URL.createObjectURL(result);
        const returnObject = {
          url: url,
        };
        return returnObject;
      } else {
        let blob = await fetch(url).then((r) => r.blob());
        return localforage.setItem(url, new Blob([blob])).then((result) => {
          if (result) {
            try {
              const newUrl = URL?.createObjectURL(result);
              const returnObject = {
                url: newUrl,
              };
              return returnObject;
            } catch {
              return {url: url}
            }
  
          }
        });
      }
    });
};

export const dataURItoBlob = (dataURI: string) => {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? atob(dataURI.split(",")[1])
      : unescape(dataURI.split(",")[1]);
  const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};
interface IResizeImageOptions {
  maxSize: number;
  file: File;
  compression: Number;
}

export const resizeImage = (settings: IResizeImageOptions) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const compression = settings.compression;
  const reader = new FileReader();
  const image = new Image();

  const newResize = (size: number) => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > size) {
        height *= size / width;
        width = size;
      }
    } else {
      if (height > size) {
        width *= size / height;
        height = size;
      }
    }
    return { width: width, height: height };
  };

  const resize = () => {
    const canvas = document.createElement("canvas");
    const lgHq = newResize(maxSize);
    canvas.width = lgHq.width;
    canvas.height = lgHq.height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, lgHq.width, lgHq.height);
    let lgHqUrl = canvas.toDataURL("image/webp", compression);
    return dataURItoBlob(lgHqUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const normalizeArrayByKey = (dataArray: any, key: string) => {
  const assignBy = (key: string) => {
    return (data: any, item: any) => {
      data[item[key]] = item;
      return data;
    };
  };
  return dataArray.reduce(assignBy(key), {});
};
