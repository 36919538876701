import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { AssetType, PersonType } from "../../types";
import { loadPerson } from "../../store/people/actions";
import {
  setActiveLanding,
} from "../../store/layout/actions";
import SwipeableViews from "react-swipeable-views";
import { CurrencyChip } from ".";

export type RootState = ReturnType<typeof store>;

const Currencies = () => {
  const allPeople: { [id: string]: PersonType } = useSelector(
    (state: any) => state.orm.Person.itemsById
  );
  const assets = useSelector((state: any) => state.orm.Asset.items);
  const allAssets: { [id: string]: AssetType } = useSelector(
    (state: any) => state.orm.Asset.itemsById
  );

  const recent = useSelector((state: RootState) => state.people.recent);

  const [currencies, setCurrencies] = useState<Array<AssetType>>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let currencies: Array<AssetType> = [];
    assets.map((assetId: string) => {
      const asset: AssetType = allAssets[assetId];
      if((asset.name) && asset.qty < 99999999){
        currencies.push(asset);
      }
      if(asset.id === 'DBLN') {
        currencies = [asset, ...currencies]
      }
      return true;
    });
    setCurrencies(currencies);
  }, [recent, assets, allAssets]);

  const handlePanelClick = (target_id: string) => {
    const refreshObject = {
      id: target_id,
      last_refresh_time: 1,
    };
    dispatch(loadPerson([refreshObject]));
    dispatch(setActiveLanding("TARGET_PROFILE"));
  };

  const springConfig = {
    duration: "0.3s",
    delay: "0s",
    easeFunction: "ease-in",
    tension: 100,
    friction: 10,
  };

  const [adjustedIndex, setadjustedIndex] = useState(0);

  const swipe = (idx: number) => {
    adjustedIndex < idx ? setadjustedIndex(idx) : setadjustedIndex(idx);
  };

  return (
    <div className="flex p-2 justify-start w-full ">
      <SwipeableViews
        index={adjustedIndex}
        onChangeIndex={swipe}
        springConfig={springConfig}
        resistance={true}
        enableMouseEvents
        style={{ width: "100%" }}
        slideStyle={{ maxWidth: "90wh" }}
        className="sm:pr-80 pr-52 "
        disabled={currencies && currencies?.length <= 1}
      >
        {currencies.map((asset: AssetType, idx: number) => {
          const personName: string = allPeople[asset.id]?.name;
          let assetName = asset.name;
          if(asset.id === 'DBLN'){
            assetName = "Doubloons"
          }
          return (
                <div
                  key={idx}
                  style={Object.assign({})}
                  className=" w-full flex px-1"
                >
                  {" "}
                  <button
                    onClick={() => asset.person_id && handlePanelClick(allPeople[asset.person_id].id)}
                    className="flex w-full"
                  >
                    <CurrencyChip 
                    personName={personName}
                    assetName={assetName}
                    balance={asset.qty}
                    />
                  </button>
                </div>
          );
        })}
      </SwipeableViews>
    </div>
  );
};

export default Currencies;
