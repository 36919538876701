import { Reducer } from 'redux';
import { 
    MoneyReducerType,
    MONEY_ACTIVATE,
    TRANSACTION_SUCCESS,
    TRANSACTION_ERROR,
    TRANSACTION_BEGIN,
    TRANSACTION_LOAD_CONFIRM,
    TRANSACTION_ADD_CONFIRM,
    TRANSACTION_LOG_BEGIN,
    TRANSACTION_LOG_SUCCESS,
    TRANSACTION_LOG_ERROR,
    // BALANCES_BEGIN,
    // BALANCES_SUCCESS,
    // BALANCES_ERROR,
    TRANSACTION_ACTIVATE,
    BUY_BEGIN,
    BUY_SUCCESS,
    CLEAR_BUY
} from './constants';


const defaultState = {
    transactionAmount: 0,
    isTransactionLoading: false,
    transactionType: 'NONE',
    activeTransactions: [],
    stagedTransactions: [],
    confirmedTransactions: [],
    selectedTransaction: {
      id: '',
      target_id:'',
      target_name: '',
      target_photo_url: '',
      target_username: '',
      asset_name: '',
      asset_id: '',
      asset_qty: 0,
      provisioned_at: 0,
      status: '',
    },
    buyTransaction: {
      id: '',
      provisioned_at: (Date.now() / 1000),
      status: 'PLACEHOLDER',
      stripePaymentKey: 'PLACEHOLDER'
    },
    redirect: ''
};



const moneyReducer: Reducer<MoneyReducerType> = (
    state = defaultState,
    action: { type: String; payload?: any, meta?: any }
  ) => {
    switch (action.type) {
          case MONEY_ACTIVATE: {
            return {
              ...state,
              transactionAmount: action.payload.transactionAmount,
            };
          }
          case TRANSACTION_BEGIN: {
            const newArray = [...state.activeTransactions, action.payload.transaction];
            return {
              ...state,
              isTransactionLoading: true,
              activeTransactions: newArray,
            }
          }
          case TRANSACTION_SUCCESS: {
            const newArray = state.activeTransactions.filter(trans => trans.id !== action.meta.transaction.id);
            return {
              ...state,
              isTransactionLoading: false,
              activeTransactions: newArray
            }
          }case TRANSACTION_ERROR: {
            const newArray = state.activeTransactions.filter(trans => trans.id !== action.meta.transaction.id);
            return {
              ...state,
              isTransactionLoading: false,
              activeTransactions: newArray,
              stagedTransactions: [...state.stagedTransactions, action.meta.transaction]
            }
          }
          case TRANSACTION_LOAD_CONFIRM: {
            return {
              ...state,
              confirmedTransactions: action.payload.transactions
            }
          }
          case TRANSACTION_ADD_CONFIRM: {
            return {
              ...state,
              confirmedTransactions: [action.payload.transaction, ...state.confirmedTransactions]
            }
          }
          case TRANSACTION_LOG_BEGIN: {
            return {
              ...state,
              isTransactionLoading: true,
            }
          }
          case TRANSACTION_LOG_SUCCESS: {
            return {
              ...state,
              isTransactionLoading: false,
              confirmedTransactions: [...action.payload.success]
            }
          }case TRANSACTION_LOG_ERROR: {
            return {
              ...state,
              isTransactionLoading: false,
              confirmedTransactions: []
            }
          }
          // case BALANCES_BEGIN: {
          //   return {
          //     ...state,
          //     isTransactionLoading: true,
          //   }
          // }
          // case BALANCES_SUCCESS: {
          //   return {
          //     ...state,
          //     isTransactionLoading: false,
          //     balances: [...action.payload.success.balances]
          //   }
          // }case BALANCES_ERROR: {
          //   return {
          //     ...state,
          //     isTransactionLoading: false,
          //     balances: []
          //   }
          // }
          case TRANSACTION_ACTIVATE: {
            return {
              ...state,
              selectedTransaction: action.payload.transaction,
            }
          }case BUY_BEGIN: {
            return {
              ...state,
              buyTransaction: action.payload.transaction,
            }
          }
          case BUY_SUCCESS: {
            return {
              ...state,
              redirect: action.payload
            }
          }
          case CLEAR_BUY: {
            return {
              ...state,
              redirect: ''
            }
          }
        default:
            return state;
    }
};

export default moneyReducer;