import { TipTransactionType, BuyType } from "../../types";

export interface MoneyReducerType {
    transactionAmount: number,
    isTransactionLoading: boolean,
    transactionType: string,
    activeTransactions: Array<TipTransactionType>,
    stagedTransactions: Array<TipTransactionType>,
    confirmedTransactions: Array<TipTransactionType>,
    selectedTransaction?: TipTransactionType,
    buyTransaction: BuyType,
    redirect: string,
}

export const MONEY_ACTIVATE = 'MONEY_ACTIVATE';
export const TRANSACTION_BEGIN = 'TRANSACTION_BEGIN';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS';
export const BUY_BEGIN = 'BUY_BEGIN';
export const BUY_ERROR = 'BUY_ERROR';
export const BUY_SUCCESS = 'BUY_SUCCESS';
export const TRANSACTION_LOAD_CONFIRM = 'TRANSACTION_LOAD_CONFIRM';
export const TRANSACTION_ADD_CONFIRM = 'TRANSACTION_ADD_CONFIRM';
export const TRANSACTION_LOG_BEGIN = 'TRANSACTION_LOG_BEGIN';
export const TRANSACTION_LOG_SUCCESS = 'TRANSACTION_LOG_SUCCESS';
export const TRANSACTION_LOG_ERROR = 'TRANSACTION_LOG_ERROR';
// export const BALANCES_BEGIN = 'BALANCES_BEGIN';
// export const BALANCES_ERROR = 'BALANCES_ERROR';
// export const BALANCES_SUCCESS = 'BALANCES_SUCCESS';
export const TRANSACTION_ACTIVATE = 'TRANSACTION_ACTIVATE';
export const CLEAR_BUY = 'CLEAR_BUY';