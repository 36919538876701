export const saveLocalStorage = async (k: string, v: string) => {
  window.localStorage.setItem(k, v)
  return true;
};

// deletes local token
export const deleteLocalStorage = async (k: string) => {
  window.localStorage.removeItem(k);
}

// deletes database
export const deleteLocalDatabase = async (k: string) => {
  window.localStorage.clear();
  indexedDB.deleteDatabase(k);
  saveLocalStorage('token','first')
}
