import React from 'react';
import { capitalizeFirstLetter } from '../../services/utils';

type AssetPurchasePropsType = {
    qty?: number,
    txid?: string,
    date: string,
    assetName: string,
}

const AssetPurchase = (props: AssetPurchasePropsType) => {
    const { qty, txid, date, assetName } = props;
    return (
        <div>

        <div
          className={
            "text-8xl font-bold mx-auto flex justify-center text-green-700"
          }
        >
          {qty}
        </div>
        <div className="text-gray-700 text-4xl font-bold mx-auto flex justify-center uppercase">
          <div>
            <div className="mx-auto text-center">{capitalizeFirstLetter(assetName)}</div>
            <div className="mx-auto text-center">Purchased</div>
          </div>
        </div>
        <div className="text-gray-700 mx-auto flex justify-center pt-8 text-lg " >
          <div className="flex justify-center grid-rows grid mx-auto flex items-start">
            <div className="mx-auto"> Blockchain Confirmation</div>
            <div className="mx-auto "style={{fontSize: '0.5rem'}}> {txid}</div>
            <div className="mx-auto -mt-4" style={{fontSize: '0.5rem'}}> {date}</div>
          </div>
        </div>
        </div>
    )
}

export default AssetPurchase