import React, { } from "react";
import { SelfThumbnail } from ".";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { FileType, RefreshType } from "../../types";
import { format, addDays, getUnixTime } from "date-fns";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getClosestDayOfLastWeek } from '../../services/utils';
import { postFile, refreshFile, optimisticPostFile } from "../../store/orm/actions";
import { loadSelf } from "../../store/people/actions";

export type RootState = ReturnType<typeof store>;
type AssetGridPropsType = {
  variant: string;
  isSelf: boolean;
};

const AssetGrid = (props: AssetGridPropsType) => {
  const { variant } = props;
  const dispatch = useDispatch();

  
  const allFiles: { [id: string]: FileType } = useSelector(
    (state: any) => state.orm.File.itemsById
  );
  const token: string = useSelector((state: any) => state.auth.token);
  
  const selfId: string = useSelector(
    (state: RootState) => state.people.self.id
  );
  const isLoggedIn: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const upcomingImages: Array<string> | undefined = useSelector(
    (state: RootState) => state.people.self.upcomingImages
  );

  const lastFriday = getClosestDayOfLastWeek();


  const moveListItem = (dragIndex: number, hoverIndex: number) => {
    const fooImages = upcomingImages;
    if (fooImages && fooImages[dragIndex] && fooImages[hoverIndex]) {
      fooImages.splice(hoverIndex, 0, fooImages.splice(dragIndex, 1)[0]);

      let imageObjectPayload: Array<FileType> = [];
      fooImages?.forEach((image: string, idx:number) => {
        const targetFriday = getUnixTime(addDays(lastFriday, 7 * (idx + 1)))
        const imageObject = {
          id: image,
          scheduled_at: targetFriday,
          person_id: selfId,
          last_refresh_time: 1,
        }
        imageObjectPayload.push(imageObject);
        dispatch(optimisticPostFile([imageObject]));
      })
      token && dispatch(postFile(imageObjectPayload, token)); 

      let refreshArray: Array<RefreshType> = [];
      fooImages?.forEach((image) => {
        const refreshObject = {
          id: image,
          last_refresh_time: allFiles[image]?.last_refresh_time
            ? allFiles[image]?.last_refresh_time
            : 1,
        };
        !refreshArray.includes(refreshObject) && refreshArray.push(refreshObject);
      });
      token
        ? dispatch(refreshFile(refreshArray, token))
        : dispatch(refreshFile(refreshArray));
      token && dispatch(loadSelf(token))
    }  
  };

  const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.maxTouchPoints > 0));
  }
  
  const touchFlag = isTouchDevice();
  const backend = touchFlag ? TouchBackend : HTML5Backend

  return (
    <DndProvider backend={backend}>
      <div className="flex bg-white dark:bg-gray-900 pt-2 relative justify-start items-start ">
        <div className="grid grid-cols-3 flex p-2 ">
          {upcomingImages?.map((image: string, idx: number) => {
            const nextFriday = format(
              addDays(lastFriday, 7 * (idx + 1)),
              "MMM d"
            );

            return (
              <div key={image} className="flex ">
                <>
                  <SelfThumbnail
                    id={allFiles[image]?.id}
                    variant={variant}
                    sq_lq_url={allFiles[image]?.sq_lq_url}
                    lg_lq_url={allFiles[image]?.lg_lq_url}
                    sq_bl_url={allFiles[image]?.sq_bl_url}
                    lg_bl_url={allFiles[image]?.lg_bl_url}
                    rep={allFiles[image]?.reputation}
                    isBlurred={false}
                    isLoggedIn={isLoggedIn}
                    price={allFiles[image]?.bid}
                    postdate={nextFriday}
                    idx={idx}
                    moveListItem={moveListItem}
                  />
                </>
              </div>
            );
          })}
        </div>
      </div>
    </DndProvider>
  );
};

export default AssetGrid;
