import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Landing, PostLanding, Redirect } from "../../screens/Landing";
import { useDispatch, useSelector } from "react-redux";
import store from "../../store";
import { logout } from "../../store/auth/actions";
import { BASE_WEB_URL } from "../../constants/endpoints";
// import { useParams } from "react-router-dom";
// import { setActiveModal, setModal } from "../../store/layout/actions";
import { Confirmation, Success } from "../Notifications";
import { logoutUser } from "../../services/authService";
import { clearBuy, buy } from "../../store/transactions/actions";
import { BuyType } from "../../types";
import { FirebaseLogin } from "../FirebaseLogin";
import { Spinner } from "../Spinner";

export type RootState = ReturnType<typeof store>;

const RoutingService = () => {
  const dispatch = useDispatch();
  const redirect = useSelector(
    (state: RootState) => state.transactions.redirect
  );
  const buyObject = useSelector(
    (state: RootState) => state.transactions.buyTransaction
  );
  const token = useSelector((state: RootState) => state.auth.token);
  const rehydrated = useSelector((state: RootState) => state.layout.rehydrated);

  return (
    <div data-testid="router-test" className="dark:bg-gray-900 ">
      {rehydrated && (
        <Router>
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/loader">
              <Spinner />
            </Route>
            <Route exact path="/expired">
              <Redirect />
            </Route>
            <Route exact path="/destroy">
              <Destroy dispatch={dispatch} logout={logout} />
            </Route>
            <Route exact path="/stripe_success/:target_id">
              <Success />
            </Route>
            <Route exact path="/stripe_success">
              <Success />
            </Route>
            <Route exact path="/confirmation">
              <Confirmation />
            </Route>
            <Route exact path="/login">
              <FirebaseLogin />
            </Route>
            <Route exact path="/txRedirect">
              {token && (
                <RedirectToStripe
                  token={token}
                  buy={buy}
                  redirect={redirect}
                  dispatch={dispatch}
                  buyObject={buyObject}
                />
              )}
            </Route>
            <Route exact path="/asset/:target_id">
              <PostLanding />
            </Route>
            <Route exact path="/:target_id">
              <Landing />
            </Route>
          </Switch>
        </Router>
      )}
    </div>
  );
};

type DestroyProps = {
  logout: Function;
  dispatch: Function;
};

export const Destroy = (props: DestroyProps) => {
  // const { dispatch } = props;
  useEffect(() => {
    logoutUser().then((result) => {
      BASE_WEB_URL && window.location.assign(BASE_WEB_URL);
    });
  }, []);
  return <></>;
};

type RedirectProps = {
  redirect: string;
  dispatch: Function;
  buyObject: BuyType;
  buy: Function;
  token?: string;
};

export const RedirectToStripe = (props: RedirectProps) => {
  const { redirect, dispatch, buyObject, token, buy } = props;
  console.log(redirect);
  useEffect(() => {
    token && dispatch(buy(buyObject, token));
  }, [buyObject, dispatch, buy, token]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (redirect !== "") {
        dispatch(clearBuy());
        window.location.assign(redirect);
      }
    }, 500);
    return () => clearInterval(intervalId);
  }, [redirect, dispatch]);
  return <></>;
};

export default RoutingService;
