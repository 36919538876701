import { Avatar } from "../../components/Avatar";
import { SelfProfileMenu } from "./index";
import { useSelector } from "react-redux";
import store from "../../store";
import { StatusOfflineSvg } from "../../components/common/Icons";
import { ThemeToggle } from "../../components/Background";

export type RootState = ReturnType<typeof store>;

type HeaderPropsType = {
  image_url?: string;
  name?: string;
};

// User Profile Header (Settings).

const ProfileHeader = (props: HeaderPropsType) => {
  const { image_url, name } = props;

  const { online } = useSelector((state: RootState) => state.offline);

  return (
    <>
      <div className="items-end grid flex bg-primary-900">
        <div className="py-4 flex w-20 mx-auto">
          {image_url && <Avatar blUrl={image_url} image_url={image_url} />}
        </div>
        <div className="grid grid-cols-2">
          <div className="pb-4 flex-shrink text-white flex justify-start pl-6">
            <p className="">{name}</p>
          </div>
          <div className="pb-4 flex-shrink text-white flex justify-end pr-6">
          <ThemeToggle />
          {!online && <StatusOfflineSvg />}
            <SelfProfileMenu />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;
