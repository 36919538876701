import {
  REFRESH_FILE,
  OPTIMISTIC_FILE_SUCCESS,
  REFRESH_FILE_SUCCESS,
  REFRESH_FILE_ERROR,
  REFRESH_PERSON,
  REFRESH_PERSON_ERROR,
  REFRESH_PERSON_SUCCESS,
  POST_FILE_SUCCESS,
  POST_FILE_ERROR,
  REFRESH_ASSETS,
  REFRESH_ASSETS_ERROR,
  REFRESH_ASSETS_SUCCESS,
  DESTROY_FILE,
  DESTROY_FILE_ERROR,
  DESTROY_FILE_SUCCESS,
} from "./constants";
import {
  PUBLIC_IMAGE_ENDPOINT,
  PEOPLE_ENDPOINT,
  AUTH_IMAGE_ENDPOINT,
  POST_IMAGE_ENDPOINT,
  BALANCES_ENDPOINT,
  DESTROY_IMAGE_ENDPOINT
} from "../../constants/endpoints";
import { FileType, RefreshType } from "../../types";

export const destroyFile = (id: string, token: string) => (dispatch: any) => {
  dispatch({
    type: DESTROY_FILE,
    payload: { id },
    meta: {
      offline: {
        effect: {
          url: DESTROY_IMAGE_ENDPOINT + '/' + id,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        commit: { type: DESTROY_FILE_SUCCESS, meta: { id } },
        rollback: { type: DESTROY_FILE_ERROR, meta: { id } },
      },
    },
  })
}

export const refreshAssets = (token: string) => (dispatch: any) => {
  dispatch({
    type: REFRESH_ASSETS,
    payload: {  },
    meta: {
      offline: {
        effect: {
          url: BALANCES_ENDPOINT,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        commit: { type: REFRESH_ASSETS_SUCCESS, meta: {  } },
        rollback: { type: REFRESH_ASSETS_ERROR, meta: {  } },
      },
    },
  });
  return { success: true };
};

export const optimisticPostFile = (files: Array<FileType>) => (dispatch: any) => {
  dispatch({ type: OPTIMISTIC_FILE_SUCCESS, payload: { files } });
};

export const postFile = (files: Array<FileType>, token: string) => (dispatch: any) => {
  if (files[0].id) {
    console.log(files);
    dispatch({
      type: OPTIMISTIC_FILE_SUCCESS,
      payload: { files },
      meta: {
        offline: {
          effect: {
            url: POST_IMAGE_ENDPOINT,
            method: "POST",
            body: JSON.stringify(files),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          commit: { type: POST_FILE_SUCCESS, meta: { files } },
          rollback: { type: POST_FILE_ERROR, meta: { files } },
        },
      },
    });
  }
};

export const refreshFile =
  (refreshObject: Array<RefreshType>, token: string = "0") =>
  (dispatch: any) => {
    const currentTime = Date.now() / 1000;
    let filteredRefreshObject: Array<RefreshType> = [];
    refreshObject.forEach((object) => {
      const timeDifference = currentTime - object.last_refresh_time;
      if (timeDifference >= 0.5) {
        filteredRefreshObject.push(object);
      }
    });
    if (filteredRefreshObject.length > 0) {
      if (token !== "0") {
        dispatch({
          type: REFRESH_FILE,
          payload: { filteredRefreshObject },
          meta: {
            offline: {
              effect: {
                url: AUTH_IMAGE_ENDPOINT,
                method: "POST",
                body: JSON.stringify(filteredRefreshObject),
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              },
              commit: { type: REFRESH_FILE_SUCCESS, meta: { filteredRefreshObject } },
              rollback: { type: REFRESH_FILE_ERROR, meta: { filteredRefreshObject } },
            },
          },
        });
      } else {
        dispatch({
          type: REFRESH_FILE,
          payload: { filteredRefreshObject },
          meta: {
            offline: {
              effect: {
                url: PUBLIC_IMAGE_ENDPOINT,
                method: "POST",
                body: JSON.stringify(filteredRefreshObject),
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              },
              commit: { type: REFRESH_FILE_SUCCESS, meta: { filteredRefreshObject } },
              rollback: { type: REFRESH_FILE_ERROR, meta: { filteredRefreshObject } },
            },
          },
        });
      }
    }
    return { success: true };
  };

export const refreshPerson =
  (refreshObject: Array<RefreshType>) => (dispatch: any) => {
    const currentTime = Date.now() / 1000;
    let filteredRefreshObject: Array<RefreshType> = [];
    refreshObject.forEach((object) => {
      const timeDifference = currentTime - object.last_refresh_time;
      // console.log(timeDifference);
      if (timeDifference >= 0.5 && object.id) {
        filteredRefreshObject.push(object);
      }
    });
    if (filteredRefreshObject.length > 0) {
      dispatch({
        type: REFRESH_PERSON,
        payload: { filteredRefreshObject },
        meta: {
          offline: {
            effect: {
              url: PEOPLE_ENDPOINT,
              method: "POST",
              body: JSON.stringify(filteredRefreshObject),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            },
            commit: { type: REFRESH_PERSON_SUCCESS, meta: { filteredRefreshObject } },
            rollback: { type: REFRESH_PERSON_ERROR, meta: { filteredRefreshObject } },
          },
        },
      });
    }

    return { success: true };
  };
