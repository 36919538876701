export const hasPermission = (personRep: string, fileRep: string) => {
    let permission = false;
    if(personRep === "NEUTRAL"){
      if(fileRep === "NEUTRAL"){
        permission = true;
      }
    }
    if(personRep === "FRIENDLY"){
      if(fileRep === "FRIENDLY" || fileRep === "NEUTRAL"){
        permission = true;
      }
    }
    if(personRep === "HONORED"){
      if(fileRep === "HONORED" || fileRep === "FRIENDLY" || fileRep === "NEUTRAL"){
        permission = true;
      }
    }
    if(personRep === "REVERED"){
      if(fileRep === "REVERED" || fileRep === "HONORED" || fileRep === "FRIENDLY" || fileRep === "NEUTRAL"){
        permission = true;
      }
    }
    if(personRep === "EXALTED"){
      permission = true;
    }

    return permission;
  }

export const getReputationColor = (rep: string) => {
    let color = "gray";
    rep === "NEUTRAL" && (color = "gray");
    rep === "FRIENDLY" && (color = "green");
    rep === "HONORED" && (color = "blue");
    rep === "REVERED" && (color = "purple");
    rep === "EXALTED" && (color = "red");

    return color;
}

export const getReputation = (xpBalance: number) => {
    const neutralCeiling = 4650;
    const friendlyCeiling = 9300;
    const honoredCeiling = 18600;
    const reveredCeiling = 37200;

    let reputation = 'NEUTRAL';
    let levelCeiling = neutralCeiling;
    let pctFull = xpBalance / neutralCeiling;
    let classWidth = 'w-0';
    let color = 'gray';
    let nextColor = 'green';

    if(xpBalance >= neutralCeiling && xpBalance < friendlyCeiling){
        reputation = 'FRIENDLY';
        levelCeiling = friendlyCeiling;
        pctFull = ((xpBalance - neutralCeiling) / neutralCeiling);
        color = 'green';
        nextColor = 'blue';
    } 

    if(xpBalance >= friendlyCeiling && xpBalance < 18600){
        reputation = 'HONORED';
        levelCeiling = honoredCeiling;
        pctFull = ((xpBalance - friendlyCeiling) / honoredCeiling);
        color = 'blue';
        nextColor = 'purple';
    } 

    if(xpBalance >= honoredCeiling && xpBalance < reveredCeiling){
        reputation = 'REVERED';
        levelCeiling = reveredCeiling;
        pctFull = ((xpBalance - honoredCeiling) / reveredCeiling);
        nextColor = 'red'
    } 

    if(xpBalance > reveredCeiling){
        reputation = 'EXALTED';
        pctFull = 1;
        color = 'red';
    } 

    if(pctFull > 0.08333333 && pctFull <= 0.16666667){
        classWidth = 'w-1/12';
    }

    if(pctFull > 0.16666667 && pctFull <= 0.25){
        classWidth = 'w-2/12';
    }

    if(pctFull > 0.25 && pctFull <= 0.333333){
        classWidth = 'w-3/12';
    }

    if(pctFull > 0.333333 && pctFull <= 0.41666667){
        classWidth = 'w-4/12';
    }

    if(pctFull > 0.41666667 && pctFull <= 0.50){
        classWidth = 'w-5/12';
    }

    if(pctFull > 0.50 && pctFull <= 0.583333){
        classWidth = 'w-6/12';
    }

    if(pctFull > 0.583333 && pctFull <= 0.66666667){
        classWidth = 'w-7/12';
    }

    if(pctFull > 0.66666667 && pctFull <= 0.75){
        classWidth = 'w-8/12';
    }

    if(pctFull > 0.75 && pctFull <= 0.833333){
        classWidth = 'w-9/12';
    }

    if(pctFull > 0.833333 && pctFull <= 0.91666667){
        classWidth = 'w-10/12';
    }

    if(pctFull > 0.91666667 && pctFull < 1){
        classWidth = 'w-11/12';
    }

    if(pctFull >= 1){
        classWidth = 'w-full';
    }

    const returnObject = {
        reputation: reputation,
        pctFull: pctFull,
        levelCeiling: levelCeiling,
        classWidth: classWidth,
        color: color,
        nextColor: nextColor,
    }

    return returnObject;
}

