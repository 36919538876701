import React, { useEffect, useRef, useState } from "react";
import { CloudUploadIcon } from "../common/Icons";
import {
  setActiveFiles,
  addPendingFile,
  uploadFile,
} from "../../store/files/actions";
import { randomToken, resizeImage, dataURItoBlob } from "../../services/utils";
import smartcrop from "smartcrop";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { FileType } from "../../types";
import { postFile, optimisticPostFile } from "../../store/orm/actions";
import { addDays, getUnixTime } from "date-fns";
import { getClosestDayOfLastWeek } from '../../services/utils';

export type RootState = ReturnType<typeof store>;

type FileUploadPropsType = {
  setActiveLoadingMessage: Function;
  setActiveLoadingPct: Function;
};

const FileUpload = (props: FileUploadPropsType) => {
  const { setActiveLoadingMessage, setActiveLoadingPct } = props;

  const dispatch = useDispatch();

  const self_id = useSelector((state: RootState) => state.people.self.id);
  const token = useSelector((state: RootState) => state.auth.token);
  const progress = useSelector((state: RootState) => state.files.progress);
  const upcomingImages = useSelector((state: RootState) => state.people.self.upcomingImages);

  const selectedField: string = useSelector(
    (state: RootState) => state.files.selectedField
  );
  const selectedUrl: string = useSelector(
    (state: RootState) => state.files.selectedUrl
  );
  const imgLoaded = async (img: HTMLImageElement) => {
    return new Promise((resolve, reject) => {
      img.addEventListener("load", () => resolve(img));
      img.addEventListener("error", (err) => reject(err));
    });
  };
  const [buttonOpen, setButtonOpen] = useState(true);
  const [parentId, setParentId] = useState("");
  const [lgHqUrl, setLgHqUrl] = useState("");
  const [lgHqObject, setLgHqObject] = useState<objType>();

  const fileUploadRef = useRef(null);

  const lastFriday = getClosestDayOfLastWeek();
  const skipAhead = upcomingImages ? upcomingImages.length : 0;
  const targetFriday = getUnixTime(addDays(lastFriday, 7 * (skipAhead + 1)))

  type objType = {
    parent_id: string;
    child_id: string;
    field: string;
    blob: Blob;
  };


  useEffect(() => {
    lgHqObject && dispatch(uploadFile(lgHqObject));
  }, [lgHqObject, dispatch]);

  useEffect(() => {
    (progress > 0) && (progress < 100) && setActiveLoadingMessage( progress.toFixed() + "%");
    setActiveLoadingPct( progress );

    progress >= 100 && setActiveLoadingMessage();
    progress >= 100 && setButtonOpen(true);

  }, [progress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("selectedField");
    if (selectedField === "lg_hq_url") {
      setLgHqUrl(selectedUrl);
    }
  }, [selectedField]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setButtonOpen(true);
    dispatch(setActiveFiles(parentId));
    const currentTime = Date.now() / 1000;
    const fileObject = {
      id: parentId,
      lg_hq_url: lgHqUrl,
      last_refresh_time: currentTime,
      person_id: self_id,
      status: "UPLOADED",
      reputation: "EXALTED",
      scheduled_at: targetFriday,
    };
    token && dispatch(postFile([fileObject], token));
  },[lgHqUrl]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileSelection = async (event: any) => {
    setButtonOpen(false);
    event.preventDefault();
    event.stopPropagation();
    setActiveLoadingMessage("Reading Files");
    setActiveLoadingPct(1)
    // const file = event.target.files[0];
    const filesArray = [...event.target.files];

    filesArray.map(async (file: any) => {
      const parent_id = randomToken(48);
      setParentId(parent_id);
      const child_id = randomToken(48);
  
      const lgHqSettings = {
        id: parent_id,
        file: file,
        maxSize: 1920,
        compression: 0.95,
      };
      const lgLqSettings = {
        id: parent_id,
        file: file,
        maxSize: 1920,
        compression: 0.5,
      };
      const mdHqSettings = {
        id: parent_id,
        file: file,
        maxSize: 1080,
        compression: 0.95,
      };
      const mdLqSettings = {
        id: parent_id,
        file: file,
        maxSize: 1080,
        compression: 0.5,
      };
  
      const lgHqImage: any = await resizeImage(lgHqSettings);
      const lgLqImage: any = await resizeImage(lgLqSettings);
      const mdHqImage: any = await resizeImage(mdHqSettings);
      const mdLqImage: any = await resizeImage(mdLqSettings);
  
      const currentTime = Date.now() / 1000;
  
      const lgHqObject = {
        parent_id: parent_id,
        child_id: child_id,
        field: "original",
        blob: lgHqImage,
      };
      setLgHqObject(lgHqObject);
      const fileObjectApi = {
        id: parent_id,
        person_id: self_id,
        status: "UPLOADING",
        last_refresh_time: currentTime,
        scheduled_at: targetFriday,
      };
  
      const mdPromise: Promise<Blob> = new Promise((resolve) => {
        const image = new Image();
        const objectURL = URL.createObjectURL(mdLqImage);
        image.src = objectURL;
        imgLoaded(image).then(() => {
          smartcrop
            .crop(image, {
              width: 1080,
              height: 608,
              minScale: 1,
            })
            .then((result) => {
              const { topCrop } = result;
              const canvas = document.createElement("canvas");
              canvas.width = image.width;
              canvas.height = image.width / 1.777;
              const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
              ctx?.drawImage(
                image,
                topCrop.x,
                topCrop.y,
                topCrop.width,
                topCrop.height,
                0,
                0,
                1080,
                608
              );
              let mdCrop = canvas.toDataURL("image/webp");
              let blob = dataURItoBlob(mdCrop);
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              resolve(blob);
            });
        });
      });
  
      const sqPromise: Promise<Blob> = new Promise((resolve) => {
        const image = new Image();
        const objectURL = URL.createObjectURL(mdLqImage);
  
        image.src = objectURL;
  
        imgLoaded(image).then(() => {
          let height = image.height
          let width = image.width
          if (width < height){
            height = width;
          } else {
            width = height;
          }
          smartcrop
            .crop(image, {
              width: width,
              height: height,
            })
            .then((result) => {
              const { topCrop } = result;
              const canvas = document.createElement("canvas");
              canvas.width = image.width;
              canvas.height = image.width;
              const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
              ctx?.drawImage(
                image,
                topCrop.x,
                topCrop.y,
                topCrop.width,
                topCrop.height,
                0,
                0,
                1080,
                1080
              );
              let sqCrop = canvas.toDataURL("image/webp");
              let blob = dataURItoBlob(sqCrop);
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              resolve(blob);
            });
        });
      });
  
      setActiveLoadingMessage("Processing Files");
      setActiveLoadingPct(2)
      const coverObject: Promise<{
        parent_id: string;
        child_id: string;
        field: string;
        blob: Blob;
      }> = new Promise((resolve) => {
        return mdPromise.then((result) => {
          const coverObject = {
            parent_id: parent_id,
            child_id: child_id,
            field: "ls_lq_url",
            blob: result,
          };
          resolve(coverObject);
        });
      });
      await coverObject;
  
      const sqObject: Promise<{
        parent_id: string;
        child_id: string;
        field: string;
        blob: Blob;
      }> = new Promise((resolve) => {
        return sqPromise.then((result) => {
          const sqObject = {
            parent_id: parent_id,
            child_id: child_id,
            field: "sq_lq_url",
            blob: result,
          };
          resolve(sqObject);
        });
      });
  
      const sqImage = (await sqObject).blob;
      const coverImage = (await coverObject).blob;
  
      const filePendingObject: FileType = {
        id: parent_id,
        person_id: self_id,
        last_refresh_time: currentTime,
        lg_hq_url: URL.createObjectURL(lgHqImage),
        lg_lq_url: URL.createObjectURL(lgLqImage),
        md_hq_url: URL.createObjectURL(mdHqImage),
        md_lq_url: URL.createObjectURL(mdLqImage),
        sq_lq_url: URL.createObjectURL(sqImage),
        ls_lq_url: URL.createObjectURL(coverImage),
        scheduled_at: targetFriday,
        is_blurred: 0,
        reputation: "EXALTED"
      };
  
      dispatch(addPendingFile(filePendingObject));
      dispatch(optimisticPostFile([filePendingObject]));
      token && dispatch(postFile([fileObjectApi], token));
    })
    
  };

  return (
    <>
      {buttonOpen && (
        <div
          className={
            "rounded-xl py-4 sm:flex sm:items-start sm:justify-between "
          }
        >
          <label className="w-full flex flex-cols-2 items-center py-4 bg-primary-700 rounded-xl tracking-wide uppercase border border-primary dark:border-gray-800 cursor-pointer hover:bg-primary-700 hover:text-white text-white ease-linear transition-all duration-150">
            <div className="px-4">
              <CloudUploadIcon />
            </div>
            <div className="flex text-left justify-start">
              <span className="text-lg font-bold px-4 ">Upload Files</span>
            </div>
            <input
              type="file"
              onChange={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleFileSelection(e);
              }}
              className="hidden"
              ref={fileUploadRef}
              multiple
            />
          </label>
        </div>
      )}
    </>
  );
};

export default FileUpload;
