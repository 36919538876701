import React, { useState, useEffect } from "react";
import { Image } from "../../components/common/Image";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveBlurPhoto,
  setActiveModal,
  setActivePhoto,
  setModal,
} from "../../store/layout/actions";
import { addImagePass } from "../../store/files/actions";
import store from "../../store";

export type RootState = ReturnType<typeof store>;

type PostPhotoPropsType = {
  fullScreenUrl?: string;
  thumbnailUrl: string;
  isBlurred?: boolean;
  repColor?: string;
  xpBalance?: number;
  blUrl: string;
  amount: number;
  isLoggedIn: boolean;
  target_post_reputation: string;
  target_person_asset_balance: number;
  doubloon_balance: number;
  target_person_reputation: string;
  target_currency: string;
};

const PostPhoto = (props: PostPhotoPropsType) => {
  const {
    fullScreenUrl,
    thumbnailUrl,
    isBlurred,
    repColor,
    blUrl,
    amount,
    // target_post_reputation,
    target_person_reputation,
    target_person_asset_balance,
    doubloon_balance,
    target_currency,
  } = props;

  // const [targetAssetBalanceDiff, setTargetAssetBalanceDiff] = useState(0);
  const [isAssetShort, setIsAssetShort] = useState(false);
  const [isDoubloonShort, setIsDoubloonShort] = useState(false);

  useEffect(() => {
    const assetDiff = amount - target_person_asset_balance;
    if (assetDiff > 0) {
      setIsAssetShort(true);
      // setTargetAssetBalanceDiff(amount);
    } else {
      setIsAssetShort(false);
      // setTargetAssetBalanceDiff(target_person_asset_balance);
    }
    const doubloonDiff =
      doubloon_balance - (amount - target_person_asset_balance);
    if (doubloonDiff <= 0) {
      setIsDoubloonShort(true);
    } else {
      setIsDoubloonShort(false);
    }
  }, [target_person_asset_balance, doubloon_balance, amount]);

  const dispatch = useDispatch();

  const id = useSelector((state: RootState) => state.files.selectedFile);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const token = useSelector((state: RootState) => state.auth.token);

  const handlePhotoClick = (photo: string) => {
    !isBlurred && dispatch(setActiveModal("PHOTO"));
    dispatch(setActiveBlurPhoto(blUrl));
    !isBlurred && dispatch(setActivePhoto(photo));
  };

  const handleLoginClick = () => {
    dispatch(setActiveModal("LOGIN"));
    dispatch(setModal(true));
  };

  const handleDoubloonClick = () => {
    dispatch(setActiveModal("BUY_DOUBLOONS"));
    dispatch(setModal(true));
  };

  const handleUnlock = () => {
    token && dispatch(addImagePass(id, token));
  };

  const opacity = () => {
    if (isBlurred) {
      return "opacity-50";
    }
  };

  return (
    <>
      <button
        onClick={() => {
          fullScreenUrl && handlePhotoClick(fullScreenUrl);
          !isLoggedIn && isBlurred && handleLoginClick();
        }}
        className="focus:outline-none relative pt-1 w-full h-full"
        data-testid="button-test"
      >
        {isBlurred && (
          <div className="z-10 absolute w-full h-full flex justify-center grid grid-rows">
            <div className="text-white flex items-center grid grid-rows-7">
              {isLoggedIn && (
                <div>You are {target_person_reputation} with Remy Bork</div>
              )}
              <div></div>
              <div className="text-md font-bold">
                {isAssetShort ? (
                  <>
                    <div>
                      {target_person_asset_balance - amount > 0 &&
                        target_person_asset_balance !== 0 && (
                          <>
                            {" "}
                            Using {target_person_asset_balance}{" "}
                            {target_currency} balance
                          </>
                        )}
                    </div>
                    <div className="">
                      {isDoubloonShort ? (
                        <>
                          {isLoggedIn ? (
                            <>You don't have enough money to unlock this post</>
                          ) : (
                            <>Login to unlock</>
                          )}
                        </>
                      ) : (
                        <>
                          Converting {amount - target_person_asset_balance}{" "}
                          Doubloons into {target_currency}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="text-2xl font-bold">
                    Unlocking for {amount} {target_currency}
                  </div>
                )}
                {/* ${(amount / 100).toFixed(2)} USD */}
              </div>

              <div className="text-xs -mt-2">
                {/* {isAssetShort && (
                )} */}
              </div>
              <div></div>
              <div className="">
                {isDoubloonShort ? (
                  <button
                    onClick={() => handleDoubloonClick()}
                    className="border-2 py-1 rounded-xl shadow-md px-3"
                  >
                    {isLoggedIn ? <div>Buy Doubloons</div> : <div>Login</div>}
                  </button>
                ) : (
                  <button
                    onClick={() => handleUnlock()}
                    className="border-2 py-1 rounded-xl shadow-md px-3"
                  >
                    Unlock
                  </button>
                )}
              </div>
              <div></div>
            </div>
          </div>
        )}

        <div className={`flex bg-${repColor}-700 rounded-xl w-full h-full`}>
          <div className="flex justify-center rounded-xl w-full h-full">
            <Image
              url={thumbnailUrl}
              className={"rounded-xl w-screen " + opacity()}
              blUrl={blUrl}
            />
          </div>
        </div>
      </button>
    </>
  );
};

export default PostPhoto;
